import { Grid, Typography } from "@mui/material";
import "../../../styles/consultarCafeteria.css";
import SwipeToSlidePromociones from "./SliderPromociones";

interface IPromociones {
  promociones: any[];
}

export default function Promociones (props: IPromociones) {
    return (
      <div className="promociones">
        {/* Aquí va el contenido de las promociones */}
        <div style={{}}>
          <Grid container alignItems={"center"}>
            <Grid item xs={1}>
              <img
                src="/assets/consultarCafeteria/promocionesIcon.svg"
                alt="promocionesIcon"
              />
            </Grid>
            <Grid item xs={11}>
              <Typography
                sx={{
                  fontFamily: "Higuen",
                  color: "#003147",
                  fontSize: "1.25rem",
                  fontWeight: "400",
                  marginBottom: "0.625rem",
                }}
              >
                Promociones
              </Typography>
            </Grid>
          </Grid>
        </div>
        <SwipeToSlidePromociones promociones={props?.promociones} />
      </div>
    );
}
