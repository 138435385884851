import React from "react";
import "../styles/Comencemos.css";
import { Link } from "react-router-dom";

const Comencemos: React.FC = () => {

    return (
        <div className="background-azul">
            
            <h1 className="listo">¡Listo!</h1>

            <div className="div-boton">
                <Link to="/registrar-preferencias">
                    <button className="boton-comencemos">Comencemos</button>
                </Link>
            </div>
        </div>
    )
}

export default Comencemos