import React from "react";
import { Typography } from "@mui/material";
import "../../styles/CabeceraCafeteria.css";

export default function CabeceraCafeteria(props: any) {
  return (
    <footer className="cabecera-cafeteria">
      <div className="logo-cab-cafeteria"></div>
      <div className="cabecera-texto">
        {props.nombre_cafeteria && (
          <>
            <Typography
              className="bienvenido-texto"
              sx={{
                fontFamily: "Higuen",
                mr: "2rem",
                fontSize: "1.5rem",
                "@media (max-width: 400px)": {
                  fontSize: "1rem", // Cambia el tamaño en pantallas pequeñas
                  paddingRight: "2rem",
                },
              }}
            >
              Bienvenido,
            </Typography>
            <Typography
              className="nombre-cafeteria"
              sx={{
                fontFamily: "Higuen",
                fontSize: "1.5rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "13rem", // Ajusta según el diseño
                "@media (max-width: 400px)": {
                  fontSize: "1rem", // Cambia el tamaño en pantallas pequeñas
                  paddingRight: "2rem",
                },
              }}
            >
              {props.nombre_cafeteria}
            </Typography>
          </>
        )}
      </div>
    </footer>
  );
}
