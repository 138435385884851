import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../../styles/Inicio.css"; // Importar los estilos CSS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import CoffeeIcon from "@mui/icons-material/Coffee";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import NavCafeteria from "../../components/home/NavCafeteria";
import { useAuth } from "../../context/AuthContext";
import ReusableModal, { ModalButton } from "../../components/Modales/Modal";
import {
  eliminarUsuario,
  tieneCafeteriaAsociada,
} from "../../App/Services/UsuarioService/UsuarioService";
import { getCafeteria } from "../../App/Services/cafeteriaService";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import SwipeToSlideImg from "../../components/home/SliderImagenes";
import InfoCafeteria from "../../components/cafeteria/InfoCafeteria";
import SwipeToSlide from "../../components/home/SliderTags";
import Promociones from "../../components/cafeteria/promociones/PromocionesCafeteria";
import Contacto from "../../components/cafeteria/Contacto";
import Carta from "../../components/cafeteria/Carta";
import {
  getAllPromocionesActivas,
} from "../../App/Services/Cafeteria/promocionesServices";

const VerPerfilCafeteria: React.FC = () => {
  const usuario = useAuth();
  const [datosCafeteria, setDatosCafeteria] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [modalConfirmacion, setModalConfirmacion] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<ModalButton[]>();
  const [promos, setPromos] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPerfil = async () => {
      if (usuario?.user?.uid) {
        setLoading(true);
        try {
          const tieneCafeteria = await tieneCafeteriaAsociada(usuario.user.uid);
          console.log(tieneCafeteria.tiene_cafeteria_asociada);
          const res = await getCafeteria(tieneCafeteria.id_cafeteria);
          setDatosCafeteria(res);
          const promos = await getAllPromocionesActivas(
            tieneCafeteria.id_cafeteria
          );
          setPromos(promos);

        } catch (error) {
          console.error("Error al obtener datos del usuario", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("Usuario no definido o autenticación en proceso");
      }
    };

    fetchPerfil();
  }, [usuario?.user?.uid]);
  
  const handleBajaUsuario = () => {
    setTituloModal("¿Seguro que quieres dar de baja tu usuario?");
    setContenidoModal("Todos tus datos se perderán");
    setBotonesModal([
      {
        label: "No",
        onClick: () => setModalConfirmacion(false),
        sx: {
          background: "#44291E",
          color: "#F0ECE3",
          borderRardius: "0.9375rem",
        },
      },
      {
        label: "Sí",
        onClick: siBajaUsuario,
        sx: {
          background: "#F0ECE3",
          color: "#44291E",
          borderRardius: "0.9375rem",
        },
      },
    ]);
    setModalConfirmacion(true);
  };

  const handleCerrarSesion = () => {
    usuario
      .deslogueo()
      .then((res) => navigate(`/`))
      .catch((error) => console.log("error al desloguar"));
  };

  const siBajaUsuario = async () => {
    try {
      if (usuario && usuario.user && usuario.user.uid) {
        const res = await eliminarUsuario(usuario.user.uid);

        // Cerrar la sesión del usuario
        const res2 = await usuario.bajaUsuario(usuario.user);

        navigate(`/`);
      }
    } catch (error) {
      console.error("Error al dar de baja el usuario:", error);
      // Manejar el error según sea necesario
    }
  };

  // Pasar el objeto tag completo al hacer clic en el slider tags
  const handleTagClick = (tag: any) => {
    if (tag) {
      navigate(`/resultados`, { state: { tag } });
    }
  };

  const handleEditarCafeteria = async () => {
    navigate("/modificar-cafeteria");
  };

  return (
    <>
      {datosCafeteria?.imagenes && (
        <Dropdown>
          <MenuButton className="boton-perfil"></MenuButton>
          <Menu className="custom-menu">
            <Card
              sx={{
                width: "18.375rem",
                bordeRadius: "1.25rem",
                left: "50%",
                display: "flex", // Añadir flexbox para centrar contenido
                justifyContent: "center", // Centrar horizontalmente
                alignItems: "center", // Centrar verticalmente
                flexDirection: "column",
                pt: "1rem",
                height:"auto",
              }}
            >
              <CardMedia
                component="img" // Definir explícitamente que se renderizará un <img>
                sx={{
                  width: "6.33681rem",
                  height: "6.33681rem",
                  borderRadius: "50%", // Mantener la imagen circular
                  border: "0.25rem solid white", // Añadir borde blanco
                  objectFit: "cover",
                }}
                image={`data:image/bmp;base64,${datosCafeteria.imagenes[0].datos}`} // Usar la propiedad "image" para la fuente de la imagen
                alt={datosCafeteria.nombre}
              />
              <CardContent
                sx={{
                  width: "18.375rem",
                  height: "29.9375rem",
                }}
              >
                <Grid container justifyItems={"center"} spacing={0.5}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        fontFamily: "Sora",
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {datosCafeteria.nombre}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    sx={{ mx: "1rem" }}
                  >
                    <Button
                      sx={{
                        borderRadius: "0.9375rem",
                        background: "#DAB587",
                        color: "#44291E",
                        textAlign: "center",
                        fontFamily: "Sora",
                        fontSize: "0.9375rem",
                        textTransform: "none",
                        width: "100%",
                      }}
                      startIcon={<CoffeeIcon />}
                      onClick={handleEditarCafeteria}
                    >
                      Editar mi cafetería
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ mx: "1rem" }}>
                    <Button
                      sx={{
                        border: "none",
                        textAlign: "center",
                        color: "#44291E",
                        fontFamily: "Sora",
                        fontSize: "0.9375rem",
                        textTransform: "none",
                      }}
                      startIcon={<LogoutIcon />}
                      onClick={() => handleCerrarSesion()}
                    >
                      Cerrar sesión
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ mx: "1rem" }}>
                    <Button
                      sx={{
                        color: "#C60F0F",
                        textAlign: "center",
                        fontFamily: "Sora",
                        fontSize: "0.9375rem",
                        textTransform: "none",
                      }}
                      startIcon={<DeleteIcon />}
                      onClick={() => handleBajaUsuario()}
                    >
                      Dar de baja usuario
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Menu>
        </Dropdown>
      )}
      <div className="inicio">
        <CabeceraCafeteria />

        {/* Carrusel de imágenes */}
        {datosCafeteria?.imagenes ? (
          <div className="contenedor-carrusel-consultar-caf">
            <SwipeToSlideImg imagenes={datosCafeteria.imagenes} />
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="22.875rem"
            height="11.813rem"
            sx={{ borderRadius: "8px", margin: "0 auto" }}
          />
        )}

        {/* Información de la cafetería */}
        {datosCafeteria.horarios ? (
          <InfoCafeteria cafeteria={datosCafeteria} habilitado_reseñas={false} />
        ) : (
          <Skeleton
            variant="text"
            width="22.875rem"
            height="5.813rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}

        {/* Carrusel de tags */}
        {datosCafeteria?.tags ? (
          <div className="contenedor-carrusel-tags">
            <SwipeToSlide tags={datosCafeteria.tags} onClick={handleTagClick} />
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="22.875rem"
            height="1.594rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}

        {/* Descripción */}
        {datosCafeteria?.descripcion ? (
          <Box
            sx={{
              typography: "body1",
              mx: "2rem",
              marginTop: "1rem",
              fontFamily: "Sora",
            }}
          >
            {datosCafeteria.descripcion}
          </Box>
        ) : (
          <Skeleton
            variant="text"
            width="18.25rem"
            height="6.25rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}

        {/* Promociones */}
        {promos.length > 0 ? (
          <Promociones promociones={promos} />
        ) : (
          <Skeleton
            variant="rectangular"
            width="90%"
            height="8rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}

        {/* Contacto */}
        {datosCafeteria?.datos_contacto ? (
          <Contacto contacto={datosCafeteria.datos_contacto} />
        ) : (
          <Skeleton
            variant="rectangular"
            width="12.5rem"
            height="7.25rem"
            sx={{ borderRadius: "5px", margin: "2rem 0", marginLeft: "2rem" }}
          />
        )}

        {/* Carta */}
        {datosCafeteria?.carta != null && <Carta link={datosCafeteria.carta} />}

        <NavCafeteria select={5} />
      </div>
      <ReusableModal
        open={modalConfirmacion}
        onClose={() => {}}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
    </>
  );
};

export default VerPerfilCafeteria;
