import React, { Dispatch, useState } from "react";
import { Modal, Box, Button, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { postPost } from "../../../../App/Services/foroService";
import { useAuth } from "../../../../context/AuthContext";

interface IModalNuevoPost {
  id_foro: number;
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalNuevoPost(props: IModalNuevoPost) {
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const handleClose = () => props.setOpen(false);
  const usuario = useAuth();

  const handlePublicar = () => {
    if (usuario?.user?.uid) {
        postPost(usuario?.user?.uid, props.id_foro, titulo, descripcion)
        .then((res) => {setTitulo(""); setDescripcion(""); props.setOpen(false)})
        .catch((error) => {console.log(error)})
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ m: "0.5rem" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, left: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <TextField
          label="Título"
          variant="standard"
          value={titulo || ""}
          onChange={(e) => setTitulo(e.target.value)}
          fullWidth
          sx={{ fontWeight: "bold", fontSize: "1.25rem", mt: "1rem" }}
        />

        <TextField
          multiline
          rows={4}
          placeholder="texto del cuerpo"
          variant="standard"
          value={descripcion || ""}
          onChange={(e) => setDescripcion(e.target.value)}
          fullWidth
        />

        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "#003147",
            "&:hover": { backgroundColor: "#002236" },
            textTransform: "none",
            borderRadius: "1.125rem",
            fontFamily: "Sora",
            fontSize: "0.68875",
          }}
          disabled={!(titulo && descripcion) ? true : false}
          onClick={handlePublicar}
        >
          Publicar
        </Button>
      </Box>
    </Modal>
  );
}
