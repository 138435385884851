import axios from "axios";
import { BASE_URL } from "./config";

export const getRutas = async (): Promise<any> => {
  const response = await axios.get(
    `${BASE_URL}/rutas/`
  )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response
};

export const getEventos = async (): Promise<any> => {
  const response = await axios.get(
    `${BASE_URL}/eventos/`
  )
    .then((res) => { return res.data })
    .catch((error) => {
      throw Error(error);
    });
  return response
};

export const getRutaById = async (id: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/rutas/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};
export const getEvento = async (id_evento: number): Promise<any> => {
  const response = await axios.get(
    `${BASE_URL}/eventos/${id_evento}`
  )
    .then((res) => { return res.data })
    .catch((error) => {
      throw Error(error);
    });
  return response
};

export const postRuta = async (uid: string, nombre: string, descripcion: string, paradas: any[]): Promise<any> => {
  const response = await axios.post(
    `${BASE_URL}/rutas/`,
    {
      nombre: nombre,
      descripcion: descripcion,
      uid_meriendista: uid,
      paradas: paradas
    }
  )
    .then((res) => { return res.data })
    .catch((error) => {
      throw Error(error);
    });
  return response
};

export const deleteRuta = async (uid: string, idRuta: number): Promise<any> => {
  try {
    const response = await axios.delete(`${BASE_URL}/rutas/${uid}/${idRuta}`);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      // Si error es de tipo Error, puedes acceder al mensaje
      throw new Error(error.message || 'Error desconocido al eliminar la ruta');
    } else {
      // En caso de que no sea un Error estándar
      throw new Error('Error desconocido al eliminar la ruta');
    }
  }
};


export const puntuarRuta = async (
  idRuta: number,
  id_meriendista: string,
  puntaje: number
): Promise<any> => {
  const response = await axios.post(
    `${BASE_URL}/rutas/puntaje/${idRuta}`,
    {
      id_meriendista: id_meriendista,
      puntaje: puntaje
    }
  )
    .then((res) => res.data)
    .catch((error) => {
      throw Error(error);
    });

  return response;
};
export const suscribirAEvento = async (uid: string, id_evento: number): Promise<any> => {
  const response = await axios.post(`${BASE_URL}/eventos/${id_evento}/suscripcion/${uid}`)
    .then((res) => { return res.data })
    .catch((error) => {
      throw Error(error);
    });
  return response
}

export const getParticipantes = async (id_evento: number): Promise<any> => {
  const response = await axios.get(`${BASE_URL}/eventos/${id_evento}/suscripcion/`)
    .then((res) => { return res.data })
    .catch((error) => {
      throw Error(error);
    });
  return response
}

export const getAllEventosCafeteria = async (id_cafeteria: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/eventos/cafeteria/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getAllForosCafeteria = async (id_cafeteria: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/cafeteria/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};