import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA9uATz-WXyEHqlfAOnExS26NTLn9CQm6U",
  authDomain: "cofflick-644e7.firebaseapp.com",
  projectId: "cofflick-644e7",
  storageBucket: "cofflick-644e7.appspot.com",
  messagingSenderId: "978077254399",
  appId: "1:978077254399:web:854d93944491a79dcf82ca",
  measurementId: "G-K3W9FHKPWH"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
// Prueba para ver si cambia
// Esta es otra Prueba