import { useNavigate } from "react-router-dom";

const useForoSearch = () => {
  const navigate = useNavigate();

  const handleSearch = (query: string) => {
    if (query) {
      navigate(`/resultados-foro?search=${query}`);
    }
  };

  return handleSearch;
};

export default useForoSearch;