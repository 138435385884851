import { ReactElement } from "react";
import KitchenIcon from '@mui/icons-material/Kitchen';


export const avatarPorId = new Map<number, string>();

avatarPorId.set(1, "/assets/user1.svg");
avatarPorId.set(2, "/assets/user2.svg");
avatarPorId.set(3, "/assets/user3.svg");
avatarPorId.set(4, "/assets/user4.svg");
avatarPorId.set(5, "/assets/user5.svg");
avatarPorId.set(6, "/assets/user6.svg");
avatarPorId.set(7, "/assets/user7.svg");
avatarPorId.set(8, "/assets/user8.svg");
avatarPorId.set(9, "/assets/user9.svg");
avatarPorId.set(10, "/assets/user10.svg");
avatarPorId.set(11, "/assets/user11.svg");