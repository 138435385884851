import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TematicaComp from "../pure/Tematica";

export interface Tematica {
  id_tematica: number;
  nombre: string;
  descripcion?: string;
}

interface SliderTematicasProps {
  tematicas: Tematica[];
  onClick: (tematica: Tematica) => void;
}

export default function SliderTematicas({
  tematicas,
  onClick,
}: SliderTematicasProps) {
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "50px",
    slidesToShow: 3,
    swipeToSlide: true,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {tematicas.map((tematica: Tematica) => (
          <div
            key={tematica.id_tematica}
            onClick={() => onClick && onClick(tematica)}
          >
            {/* Mostrar el nombre de la temática */}
            <TematicaComp
              id_tematica={tematica.id_tematica}
              nombre={tematica.nombre}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
