import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import {
    TextField,
    Typography,
    Grid,
    ListItemButton,
    Box,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@mui/material";
import { getTags } from "../../App/Services/cafeteriaService";
import CustomStepper from "./customStepper"; // Asegúrate de que coincida exactamente
import { GoogleMap } from "@react-google-maps/api";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useAuth } from "../../context/AuthContext";
import { useGoogleMaps } from "../../components/Mapa/GoogleMapsProvider";
import ReusableModal, { ModalButton } from "../../components/Modales/Modal";
import { useNavigate } from "react-router-dom";
import { getCafeteria, patchCafeteria } from "../../App/Services/cafeteriaService";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";


interface Tag {
    id_tag: number;
    nombre: string;
}

interface modificarCafeteriaProps {
    apiKey: string;
}

interface Horario {
    dia: string;
    hora_apertura: string;
    hora_cierre: string;
}

interface Imagen {
    id_foto: number | null;
    datos: string | null; // Puede ser `null` para indicar imágenes eliminadas
}



// Declarar el tipo manualmente para `libraries`
const libraries: ("places" | "geometry" | "drawing" | "marker")[] = ["places", "marker"];

const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/; // Cambia según tu formato
    return phoneRegex.test(phone);
};

const isValidTimeRange = (horaApertura: string, horaCierre: string) => {
    if (!horaApertura || !horaCierre) return true; // Permite que los campos vacíos no bloqueen
    return horaApertura < horaCierre;
};

const validateLink = (link: string) => {
    if (link.trim() === "") {
        return true; // Aceptar enlaces vacíos
    }
    try {
        // Asegurar que el link tenga http:// o https://
        const url = new URL(link.startsWith("http") ? link : `http://${link}`);
        // Validar que termine con un dominio válido
        const domainRegex = /\.(com|org|net|edu|gov|io|info|biz|co|ar|es|cl|ee)$/i;
        return domainRegex.test(url.hostname);
    } catch {
        return false;
    }
};

// Array de días de la semana con IDs
const diasSemana = [
    { id: 1, nombre: "Lunes" },
    { id: 2, nombre: "Martes" },
    { id: 3, nombre: "Miércoles" },
    { id: 4, nombre: "Jueves" },
    { id: 5, nombre: "Viernes" },
    { id: 6, nombre: "Sábado" },
    { id: 7, nombre: "Domingo" },
];

const TagItem = memo(
    ({
        tag,
        selected,
        onClick,
    }: {
        tag: Tag;
        selected: boolean;
        onClick: (id: number) => void;
    }) => (
        <ListItemButton
            component="button"
            value={tag.id_tag}
            sx={{
                height: "auto",
                borderRadius: "4.75rem",
                background: selected ? "#44291E" : "#dab587",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.16)",
                color: "#FFF",
                fontSize: "0.875rem",
                padding: "0.5rem 1rem",
                textAlign: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
            }}
            onClick={() => onClick(tag.id_tag)}
        >
            {tag.nombre}
        </ListItemButton>
    )
);

const TagsList = memo(
    ({
        tags,
        preferencias,
        onTagClick,
    }: {
        tags: Tag[];
        preferencias: number[];
        onTagClick: (id: number) => void;
    }) => {
        return (
            <Grid
                container
                sx={{
                    mt: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
                spacing={2}
            >
                {tags.map((tag) => (
                    <Grid item key={tag.id_tag}>
                        <TagItem
                            tag={tag}
                            selected={preferencias.includes(tag.id_tag)} // Marca los seleccionados
                            onClick={onTagClick}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
);

const StepContent: React.FC<{
    activeStep: number;
    preferencias: number[];
    setPreferencias: React.Dispatch<React.SetStateAction<number[]>>;
    formState: {
        nombre: string;
        descripcion: string;
        telefono: string;
        email: string;
        carta: string;
        links: string[];
        franjasHorarias: {
            id_dia: number;
            hora_apertura: string;
            hora_cierre: string;
        }[];
        direccion: string;
        imagenes: Imagen[];
    };
    updateFormState: (key: string, value: any) => void;
    handleNext: () => void;
    handleBack: () => void;
    apiKey: string;
    usuario: any;
    setFormState: React.Dispatch<React.SetStateAction<any>>; // Agrega esto
}> = memo(
    ({
        activeStep,
        preferencias,
        setPreferencias,
        formState,
        updateFormState,
        handleNext,
        handleBack,
        apiKey,
        usuario,
        setFormState,
    }) => {
        const [tags, setTags] = useState<Tag[]>([]);

        const [coordinates, setCoordinates] = useState<google.maps.LatLngLiteral>({
            lat: -31.4201, // Coordenadas iniciales por defecto (ej: Córdoba, Argentina)
            lng: -64.1888,
        });

        const [tituloModal, setTituloModal] = useState<string>("");
        const [contenidoModal, setContenidoModal] = useState<string>("");
        const [botonesModal, setBotonesModal] = useState<ModalButton[]>();
        const [modalConfirmacion, setModalConfirmacion] = useState<boolean>(false);
        const navigate = useNavigate();
        const [currentMarker, setCurrentMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);


        const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);


        useEffect(() => {
            let isMounted = true; // Para evitar actualizaciones no deseadas si el componente se desmonta
            getTags()
                .then((response) => {
                    if (isMounted) setTags(response);
                })
                .catch((error) => console.error("Error al obtener los tags:", error));
            return () => {
                isMounted = false; // Limpieza del efecto
            };
        }, []);

        useEffect(() => {
            const fetchTags = async () => {
                try {
                    const response = await getTags();
                    setTags(response);
                } catch (error) {
                    console.error("Error al obtener los tags:", error);
                }
            };
            fetchTags();
        }, []);

        const handleCartaChange = (value: string) => {
            updateFormState("carta", value);

            // Validación: solo verificar el formato si no está vacío
            let error = "";
            if (value && !validateLink(value)) {
                error = "El enlace debe ser válido (ej. https://ejemplo.com).";
            }

            // Actualizar errores
            setErrors((prev) => ({
                ...prev,
                carta: error, // Actualizar el error (vacío si no hay problema)
            }));
        };

        const handleAddLink = () => {
            updateFormState("links", [...formState.links, ""]);
        };

        const renderAdvancedMarker = useCallback(
            (map: google.maps.Map, position: google.maps.LatLngLiteral, iconUrl: string) => {
                if (!google?.maps?.marker?.AdvancedMarkerElement) {
                    console.error("AdvancedMarkerElement no está disponible.");
                    return null;
                }

                // Elimina el marcador actual
                if (currentMarker) {
                    currentMarker.map = null; // Remueve el marcador del mapa
                    setCurrentMarker(null); // Limpia el estado del marcador actual
                }

                // Crea un nuevo marcador
                const content = document.createElement("div");
                content.style.width = "40px";
                content.style.height = "40px";
                content.style.backgroundImage = `url('${iconUrl}')`;
                content.style.backgroundSize = "cover";
                content.style.borderRadius = "50%";

                const marker = new google.maps.marker.AdvancedMarkerElement({
                    position,
                    map,
                    title: "Ubicación seleccionada",
                    content,
                });

                // Actualiza el estado del marcador actual
                setCurrentMarker(marker);

                return marker;
            },
            [currentMarker]
        );


        const handleRemoveLink = (index: number) => {
            const updatedLinks = [...formState.links];
            updatedLinks.splice(index, 1); // Elimina el enlace en el índice especificado

            // También elimina el error correspondiente
            const updatedErrors = [...errors.links];
            updatedErrors.splice(index, 1);

            // Actualiza el estado del formulario y los errores
            updateFormState("links", updatedLinks);
            setErrors((prev) => ({
                ...prev,
                links: updatedErrors,
            }));
        };

        const { isLoaded } = useGoogleMaps();

        useEffect(() => {
            if (mapInstance && coordinates.lat && coordinates.lng) {
                mapInstance.setCenter({
                    lat: parseFloat(coordinates.lat.toString()),
                    lng: parseFloat(coordinates.lng.toString()),
                });
                renderAdvancedMarker(mapInstance, coordinates, "/assets/consultarCafeteria/Logo Cofflick (1).svg");
            }
        }, [mapInstance, coordinates]);




        useEffect(() => {
            return () => {
                if (currentMarker) {
                    currentMarker.map = null;
                }
            };
        }, [currentMarker]);


        const canAddFranja = diasSemana.some((dia) => {
            const countsByDay = formState.franjasHorarias.reduce((acc, franja) => {
                acc[franja.id_dia] = (acc[franja.id_dia] || 0) + 1;
                return acc;
            }, {} as { [id_dia: number]: number });

            return (countsByDay[dia.id] || 0) < 2; // Permitir si hay menos de 2 franjas
        });

        const hasOverlappingRanges = (
            franjas: { id_dia: number; hora_apertura: string; hora_cierre: string }[],
            current: { id_dia: number; hora_apertura: string; hora_cierre: string }
        ) => {
            return franjas.some(
                (franja) =>
                    franja.id_dia === current.id_dia && // Mismo día
                    ((current.hora_apertura >= franja.hora_apertura &&
                        current.hora_apertura < franja.hora_cierre) || // Apertura en rango
                        (current.hora_cierre > franja.hora_apertura &&
                            current.hora_cierre <= franja.hora_cierre)) // Cierre en rango
            );
        };


        const handleAddFranja = () => {
            const countsByDay = formState.franjasHorarias.reduce((acc, franja) => {
                acc[franja.id_dia] = (acc[franja.id_dia] || 0) + 1;
                return acc;
            }, {} as { [id_dia: number]: number });

            const currentLastDay = formState.franjasHorarias.at(-1)?.id_dia || 0;
            const nextAvailableDay = diasSemana.find(
                (dia) =>
                    dia.id > currentLastDay && (countsByDay[dia.id] || 0) < 2
            );

            const availableDay =
                nextAvailableDay ||
                diasSemana.find((dia) => (countsByDay[dia.id] || 0) < 2);

            if (!availableDay) {
                alert("No puedes agregar más franjas horarias. Todos los días tienen el máximo permitido.");
                return;
            }

            const newFranja = {
                id_dia: availableDay.id,
                hora_apertura: "09:00",
                hora_cierre: "18:00",
            };

            updateFormState("franjasHorarias", [
                ...formState.franjasHorarias,
                newFranja,
            ]);

            setErrors((prev) => ({
                ...prev,
                franjasHorarias: [...(prev.franjasHorarias || []), ""],
            }));
        };

        const handleRemoveFranja = (index: number) => {
            const updatedFranjas = [...formState.franjasHorarias];
            updatedFranjas.splice(index, 1); // Eliminar la franja seleccionada
            updateFormState("franjasHorarias", updatedFranjas);

            // Eliminar el error correspondiente
            const updatedErrors = [...errors.franjasHorarias];
            updatedErrors.splice(index, 1);
            setErrors((prev) => ({
                ...prev,
                franjasHorarias: updatedErrors,
            }));
        };

        const [errors, setErrors] = useState<{
            telefono: string;
            email: string;
            carta: string;
            links: string[];
            franjasHorarias: string[];
            direccion: string;
            imagenes: string[]; // Cambiar el tipo de never[] a string[]
        }>({
            telefono: "",
            email: "",
            carta: "",
            links: [],
            franjasHorarias: [],
            direccion: "",
            imagenes: [], // Ahora tiene un tipo válido
        });

        const handleFranjaChange = (
            index: number,
            field: "hora_apertura" | "hora_cierre",
            value: string
        ) => {
            const updatedFranjas = [...formState.franjasHorarias];
            updatedFranjas[index][field] = value;

            // Validación de tiempo
            const { hora_apertura, hora_cierre, id_dia } = updatedFranjas[index];
            let error = "";

            if (!hora_apertura || !hora_cierre) {
                error = "Debes completar ambos horarios.";
            } else if (!isValidTimeRange(hora_apertura, hora_cierre)) {
                error = "La hora de apertura debe ser antes de la hora de cierre.";
            } else {
                const isOverlapping = hasOverlappingRanges(
                    updatedFranjas.filter((_, i) => i !== index),
                    { id_dia, hora_apertura, hora_cierre }
                );

                if (isOverlapping) {
                    error = "Las franjas horarias no deben superponerse.";
                }
            }

            // Actualizar errores
            const updatedErrors = [...errors.franjasHorarias];
            updatedErrors[index] = error;

            setErrors((prev) => ({
                ...prev,
                franjasHorarias: updatedErrors,
            }));

            // Actualizar estado del formulario
            updateFormState("franjasHorarias", updatedFranjas);
        };

        const handleLinkChange = (index: number, value: string) => {
            const updatedLinks = [...formState.links];
            updatedLinks[index] = value;
            updateFormState("links", updatedLinks);

            // Validación: si está vacío, eliminar el error
            const updatedErrors = [...errors.links];
            if (value.trim() === "") {
                updatedErrors[index] = ""; // Sin error si está vacío
            } else if (!validateLink(value)) {
                updatedErrors[index] = "El enlace no es válido."; // Error si el formato es incorrecto
            } else {
                updatedErrors[index] = ""; // Sin error si es válido
            }

            // Actualizar los errores en el estado
            setErrors((prev) => ({
                ...prev,
                links: updatedErrors,
            }));
        };

        const [cafeteria, setCafeteria] = useState<any>(null);

        useEffect(() => {
            const fetchDatosCafeteria = async () => {
                if (usuario.user) {
                    try {
                        const cafeteriaAsociada = await tieneCafeteriaAsociada(usuario.user.uid);
                        const datosCafeteria = await getCafeteria(cafeteriaAsociada.id_cafeteria);

                        setCafeteria(datosCafeteria)

                        // Actualizar coordenadas solo si están presentes en los datos
                        if (datosCafeteria.latitud && datosCafeteria.longitud) {
                            setCoordinates({
                                lat: parseFloat(datosCafeteria.latitud),
                                lng: parseFloat(datosCafeteria.longitud),
                            });
                        }

                        const images = datosCafeteria.imagenes.map((img: { id_foto: number; datos: string }) => ({
                            id_foto: img.id_foto,
                            datos: `data:image/jpeg;base64,${img.datos}`, // Incluye el encabezado Base64
                        }));

                        setFormState((prev: typeof formState) => ({
                            ...prev,
                            imagenes: images, // Actualiza con el formato correcto
                        }));

                        // Definir el mapeo de días
                        const diaSemanaMap: { [key: string]: number } = {
                            Lunes: 1,
                            Martes: 2,
                            Miércoles: 3,
                            Jueves: 4,
                            Viernes: 5,
                            Sábado: 6,
                            Domingo: 7,
                        };

                        const horariosCafeteria = datosCafeteria.horarios
                            .map((horario: Horario) => ({
                                id_dia: diaSemanaMap[horario.dia] ?? null,
                                hora_apertura: horario.hora_apertura,
                                hora_cierre: horario.hora_cierre,
                            }))
                            .filter((horario: { id_dia: number | null }) => horario.id_dia !== null);


                        setFormState((prev: any) => ({
                            ...prev,
                            franjasHorarias: horariosCafeteria,
                        }));

                        // Otros datos de la cafetería
                        setFormState((prev: any) => ({
                            ...prev,
                            nombre: datosCafeteria.nombre || "",
                            descripcion: datosCafeteria.descripcion || "",
                            telefono: datosCafeteria.datos_contacto.telefono || "",
                            email: datosCafeteria.datos_contacto.mail || "",
                            carta: datosCafeteria.carta || "",
                            direccion: datosCafeteria.direccion || "",
                        }));

                        // Establecer preferencias iniciales
                        setPreferencias(datosCafeteria.tags.map((tag: { id_tag: number }) => tag.id_tag));
                    } catch (error) {
                        console.error("Error al obtener los datos de la cafetería:", error);
                    }
                }
            };

            fetchDatosCafeteria();
        }, [usuario]);


        useEffect(() => {
            // Validación inicial de franjas horarias
            const initialErrors = formState.franjasHorarias.map((franja, index) => {
                const { hora_apertura, hora_cierre, id_dia } = franja;

                if (!hora_apertura || !hora_cierre) {
                    return "Debes completar ambos horarios.";
                }

                if (!isValidTimeRange(hora_apertura, hora_cierre)) {
                    return "La hora de apertura debe ser antes de la hora de cierre.";
                }

                // Validar solapamientos con otras franjas horarias del mismo día
                const isOverlapping = hasOverlappingRanges(
                    formState.franjasHorarias.filter((_, i) => i !== index),
                    franja
                );

                if (isOverlapping) {
                    return "Las franjas horarias no deben solaparse.";
                }

                return ""; // Sin errores
            });

            setErrors((prev) => ({
                ...prev,
                franjasHorarias: initialErrors,
            }));
        }, []); // Ejecutar solo una vez al montar el componente


        const isFormValid = useCallback(() => {
            const noErrors =
                !errors.telefono &&
                !errors.email &&
                errors.links.every((error, index) => !error || formState.links[index] === "") &&
                (!formState.carta || validateLink(formState.carta)) &&
                errors.franjasHorarias.every((error) => !error);

            return noErrors;
        }, [errors]);

        const canFinalize = useCallback(() => {
            return formState.imagenes.length >= 1 && formState.direccion.trim() !== ""; // Asegúrate de que haya al menos una imagen y la dirección no esté vacía
        }, [formState.imagenes, formState.direccion]);

        const handleTagClick = useCallback(
            (id: number) => {
                setPreferencias((prev) =>
                    prev.includes(id) ? prev.filter((tagId) => tagId !== id) : [...prev, id]
                );
            },
            [setPreferencias]
        );

        const handleFinalizar = async () => {
            setTituloModal("¿Estás seguro que quieres modificar la cafetería?");
            setContenidoModal("Se modificarán los datos de la cafetería en Cofflick :)");
            setBotonesModal([
                {
                    label: "No",
                    onClick: () => setModalConfirmacion(false),
                    sx: {
                        background: "#44291E",
                        color: "#F0ECE3",
                        borderRardius: "0.9375rem",
                    },
                },
                {
                    label: "Sí",
                    onClick: handleConfirmar,
                    sx: {
                        background: "#F0ECE3",
                        color: "#44291E",
                        borderRardius: "0.9375rem",
                    },
                },
            ]);
            setModalConfirmacion(true);
        }

        const handleConfirmar = async () => {
            if (!usuario?.user || !cafeteria || !cafeteria.id_cafeteria) {
                console.error("Datos insuficientes para la modificación.");
                return;
            }

            // Filtrar imágenes que tienen id_foto o datos como null
            const imagenesFiltradas = formState.imagenes.filter((imagen) => !imagen.id_foto || !imagen.datos);

            const fotos = imagenesFiltradas.map((imagen) => ({
                id_foto: imagen.id_foto, // Si la imagen tiene ID, es una existente
                datos: imagen.datos && imagen.datos.startsWith("data:image")
                    ? imagen.datos.split(",")[1] // Si es nueva, eliminar encabezado Base64
                    : null, // Si no tiene datos, mantener null
            }));

            const datosCafeteria = {
                idCafeteria: cafeteria.id_cafeteria,
                nombre: formState.nombre,
                descripcion: formState.descripcion,
                latitud: coordinates.lat.toString(), // Asegúrate de que sea string
                longitud: coordinates.lng.toString(), // Asegúrate de que sea string
                direccion: formState.direccion,
                telefono: formState.telefono,
                email: formState.email,
                carta: formState.carta,
                id_barrio: 1, // Ajusta según sea necesario
                uid_administrador: usuario.user.uid,
                ids_tags: preferencias,
                links: formState.links,
                franjas_horarias: formState.franjasHorarias,
                fotos, // Asegúrate de incluir las imágenes correctamente procesadas
            };


            try {
                const resultado = await patchCafeteria(datosCafeteria);
                navigate("/ver-perfil-cafeteria");
            } catch (error) {
                console.error("Error al modificar la cafetería:", error);
                alert("Hubo un error al modificar la cafetería. Intenta nuevamente.");
            }
        };

        return (
            <div className="rectanguloAzulLogin">
                {activeStep === 0 && (
                    <>
                        <Typography
                            sx={{
                                fontFamily: "Higuen",
                                color: "white",
                                fontSize: "2rem",
                                fontWeight: "400",
                                marginBottom: "0.625rem",
                            }}
                        >
                            Información Básica
                        </Typography>
                        <div className="form">
                            <TextField
                                className="text"
                                label="Nombre de la Cafeteria *"
                                variant="standard"
                                placeholder="Nombre"
                                value={formState.nombre}
                                onChange={(e) => updateFormState("nombre", e.target.value.slice(0, 25))}
                                sx={{
                                    marginTop: "2rem",
                                    marginBottom: "1rem",
                                    "& .MuiInputLabel-root": { color: "#fff" },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                        borderBottomColor: "#fff",
                                    },
                                    input: { color: "#fff" },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#fff",
                                        opacity: 0.5,
                                    },
                                }}
                            />
                            <TextField
                                className="text"
                                label="Descripción *"
                                variant="standard"
                                placeholder="Descripción Breve"
                                value={formState.descripcion}
                                onChange={(e) => updateFormState("descripcion", e.target.value.slice(0, 500))}
                                multiline
                                rows={8}
                                sx={{
                                    marginBottom: "1rem",
                                    "& .MuiInputLabel-root": { color: "#fff" },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                        borderBottomColor: "#fff",
                                    },
                                    "& .MuiInputBase-input": { color: "#fff" },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#fff",
                                        opacity: 0.5,
                                    },
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "1.5rem",
                            }}
                        >
                            <button onClick={handleNext} className="custom-button">
                                Siguiente
                            </button>
                        </div>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <Box
                            component="form"
                            className="form"
                            noValidate
                            autoComplete="off"
                            sx={{ m: 0 }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Higuen",
                                    color: "white",
                                    fontSize: "2rem",
                                    fontWeight: "400",
                                    marginBottom: "0.625rem",
                                }}
                            >
                                Preferencias
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Sora",
                                    color: "white",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    marginBottom: "0.625rem",
                                }}
                            >
                                Selecciona al menos 4 preferencias
                            </Typography>
                            <TagsList
                                tags={tags}
                                preferencias={preferencias}
                                onTagClick={handleTagClick}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    marginTop: "1.5rem",
                                }}
                            >
                                <div className="botones">
                                    <button onClick={handleBack} className="custom-button-paso2">
                                        Atrás
                                    </button>
                                    <button onClick={handleNext} className="custom-button-paso2">
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </Box>
                    </>
                )}
                {activeStep === 2 && (
                    <Box
                        className="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between", // Asegura que el contenido y los botones estén separados
                            height: "100%", // Ocupa todo el espacio disponible
                        }}
                    >
                        {/* Contenido dinámico */}
                        <Box
                            sx={{
                                flexGrow: 1, // Permite que el contenido crezca y use el espacio disponible
                                overflowY: "auto", // Scroll solo si el contenido es muy largo
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Higuen",
                                    color: "white",
                                    fontSize: "1.25rem",
                                    fontWeight: "400",
                                    marginBottom: "0.625rem",
                                }}
                            >
                                Contacto y Horarios
                            </Typography>

                            {/* Teléfono */}
                            <TextField
                                className="text"
                                label="Teléfono *"
                                variant="standard"
                                placeholder="Escribe el número de teléfono"
                                value={formState.telefono}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    updateFormState("telefono", value);

                                    // Validación
                                    if (!validatePhone(value)) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            telefono: "El teléfono no es válido.",
                                        }));
                                    } else {
                                        setErrors((prev) => ({ ...prev, telefono: "" }));
                                    }
                                }}
                                error={!!errors.telefono} // Cambia el estilo del campo si hay error
                                helperText={errors.telefono} // Muestra el mensaje de error
                                sx={{
                                    marginTop: "2rem",
                                    marginBottom: "1rem",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "70%",
                                    alignItems: "stretch",
                                    "& .MuiInputLabel-root": { color: "#fff" },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                        borderBottomColor: errors.telefono ? "red" : "#fff",
                                    },
                                    input: { color: "#fff" },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#fff",
                                        opacity: 0.5,
                                    },
                                }}
                            />

                            {/* Email */}
                            <TextField
                                className="text"
                                label="Email *"
                                variant="standard"
                                placeholder="Escribe el correo electrónico"
                                value={formState.email}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    updateFormState("email", value);

                                    // Validación
                                    if (!validateEmail(value)) {
                                        setErrors((prev) => ({
                                            ...prev,
                                            email: "El email no es válido.",
                                        }));
                                    } else {
                                        setErrors((prev) => ({ ...prev, email: "" }));
                                    }
                                }}
                                error={!!errors.email}
                                helperText={errors.email}
                                sx={{
                                    marginTop: "2rem",
                                    marginBottom: "1rem",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "70%",
                                    alignItems: "stretch",
                                    "& .MuiInputLabel-root": { color: "#fff" },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                        borderBottomColor: errors.email ? "red" : "#fff",
                                    },
                                    input: { color: "#fff" },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#fff",
                                        opacity: 0.5,
                                    },
                                }}
                            />

                            {/* Carta */}
                            <TextField
                                className="text"
                                label="Link de la Carta"
                                variant="standard"
                                placeholder="https://ejemplo.com/carta"
                                value={formState.carta}
                                onChange={(e) => handleCartaChange(e.target.value)}
                                error={!!errors.carta} // Mostrar estilo de error si hay uno
                                helperText={errors.carta} // Mostrar mensaje de error
                                sx={{
                                    marginTop: "2rem",
                                    marginBottom: "1rem",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "70%",
                                    alignItems: "stretch",
                                    "& .MuiInputLabel-root": { color: "#fff" },
                                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                        borderBottomColor: errors.carta ? "red" : "#fff",
                                    },
                                    input: { color: "#fff" },
                                    "& .MuiInputBase-input::placeholder": {
                                        color: "#fff",
                                        opacity: 0.5,
                                    },
                                }}
                            />

                            {/* Links */}
                            <Typography
                                sx={{
                                    fontFamily: "Sora",
                                    color: "white",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    marginTop: "1rem",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                Enlaces
                            </Typography>
                            {formState.links.map((link, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "4rem",
                                        marginRight: "4rem"
                                    }}
                                >
                                    <TextField
                                        label={`Link ${index + 1}`}
                                        variant="standard"
                                        value={link}
                                        placeholder="https://www.instagram.com/cafeteriaEjemplo"
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            handleLinkChange(index, newValue);

                                            // Validación
                                            const updatedErrors = [...errors.links];
                                            if (!validateLink(newValue)) {
                                                updatedErrors[index] = "El enlace no es válido.";
                                            } else {
                                                updatedErrors[index] = "";
                                            }
                                            setErrors((prev) => ({ ...prev, links: updatedErrors }));
                                        }}
                                        error={!!errors.links[index]}
                                        helperText={errors.links[index]}
                                        sx={{
                                            margin: "1rem",
                                            width: "60%",
                                            flex: 1,
                                            left: 1,
                                            "& .MuiInputLabel-root": { color: "#fff" },
                                            "& .MuiInput-underline:before, & .MuiInput-underline:after":
                                            {
                                                borderBottomColor: errors.links[index]
                                                    ? "red"
                                                    : "#fff",
                                            },
                                            input: { color: "#fff" },
                                            "& .MuiInputBase-input::placeholder": {
                                                color: "#fff",
                                                opacity: 0.5,
                                            },
                                        }}
                                    />
                                    <button
                                        onClick={() => handleRemoveLink(index)}
                                        disabled={formState.links.length === 1}
                                        style={{
                                            marginLeft: "0.5rem",
                                            backgroundColor:
                                                formState.links.length === 1 ? "grey" : "red",
                                            color: "white",
                                            border: "none",
                                            padding: "0.25rem 0.5rem",
                                            marginRight: "1rem",
                                            fontSize: "0.75rem",
                                            cursor:
                                                formState.links.length === 1
                                                    ? "not-allowed"
                                                    : "pointer",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        X
                                    </button>
                                </Box>
                            ))}
                            <button
                                className="boton-chiquito"
                                onClick={handleAddLink}
                                disabled={formState.links.length >= 3}
                                style={{
                                    cursor: formState.links.length >= 3 ? "not-allowed" : "pointer",
                                    opacity: formState.links.length >= 3 ? 0.5 : 1,
                                }}
                            >
                                Añadir enlace
                            </button>

                            {/* Franjas Horarias */}
                            <Typography
                                sx={{
                                    fontFamily: "Sora",
                                    color: "white",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    marginTop: "1rem",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                Franjas Horarias
                            </Typography>
                            {formState.franjasHorarias.map((franja, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginRight: "2.5rem",
                                        marginLeft: "2.5rem",
                                        marginBottom: "0.5rem",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {/* Día de la semana (Selección Simple con opciones bloqueadas) */}
                                        <Select
                                            value={franja.id_dia}
                                            onChange={(e) => {
                                                const selectedDay = e.target.value as number;
                                                const updatedFranjas = [...formState.franjasHorarias];
                                                updatedFranjas[index].id_dia = selectedDay;
                                                updateFormState("franjasHorarias", updatedFranjas);
                                            }}
                                            renderValue={(selected) =>
                                                diasSemana.find((dia) => dia.id === selected)?.nombre || ""
                                            }
                                            sx={{
                                                display: "flex",
                                                margin: "0.35rem",
                                                width: "25%",
                                                color: "#fff",
                                                "& .MuiSelect-select": { color: "#fff" },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#fff",
                                                },
                                            }}
                                        >
                                            {diasSemana.map((dia) => {
                                                // Contar franjas del día actual
                                                const countsByDay = formState.franjasHorarias.reduce((acc, franja) => {
                                                    acc[franja.id_dia] = (acc[franja.id_dia] || 0) + 1;
                                                    return acc;
                                                }, {} as { [id_dia: number]: number });

                                                const isDisabled = (countsByDay[dia.id] || 0) >= 2;

                                                return (
                                                    <MenuItem key={dia.id} value={dia.id} disabled={isDisabled}>
                                                        {dia.nombre}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>

                                        {/* Hora de apertura */}
                                        <TextField
                                            type="time"
                                            value={franja.hora_apertura || "09:00"}
                                            onChange={(e) =>
                                                handleFranjaChange(index, "hora_apertura", e.target.value)
                                            }
                                            sx={{
                                                display: "flex",
                                                margin: "0.5rem",
                                                width: "20%",
                                                input: { color: "#fff" },
                                            }}
                                        />

                                        {/* Hora de cierre */}
                                        <TextField
                                            type="time"
                                            value={franja.hora_cierre || "18:00"}
                                            onChange={(e) =>
                                                handleFranjaChange(index, "hora_cierre", e.target.value)
                                            }
                                            sx={{
                                                display: "flex",
                                                margin: "0.5rem",
                                                width: "20%",
                                                input: { color: "#fff" },
                                            }}
                                        />

                                        {/* Botón para eliminar franja */}
                                        <button
                                            onClick={() => handleRemoveFranja(index)}
                                            disabled={formState.franjasHorarias.length === 1}
                                            style={{
                                                display: "flex",
                                                marginLeft: "0.5rem",
                                                backgroundColor:
                                                    formState.franjasHorarias.length === 1 ? "grey" : "red",
                                                color: "white",
                                                border: "none",
                                                padding: "0.25rem 0.5rem",
                                                fontSize: "0.75rem",
                                                cursor:
                                                    formState.franjasHorarias.length === 1 ? "not-allowed" : "pointer",
                                                borderRadius: "4px",
                                            }}
                                        >
                                            X
                                        </button>
                                    </Box>

                                    {/* Mensaje de error */}
                                    {errors.franjasHorarias[index] && (
                                        <Typography
                                            sx={{
                                                color: "red",
                                                fontSize: "0.8rem",
                                                marginTop: "0.5rem",
                                                textAlign: "center",
                                            }}
                                        >
                                            {errors.franjasHorarias[index]}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                            <button
                                className="boton-chiquito"
                                onClick={handleAddFranja}
                                disabled={!canAddFranja} // Habilitar solo si se puede agregar otra franja
                                style={{
                                    cursor: canAddFranja ? "pointer" : "not-allowed",
                                    opacity: canAddFranja ? 1 : 0.5,
                                }}
                            >
                                Añadir Franja Horaria
                            </button>
                        </Box>

                        {/* Botones */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                padding: "1rem",
                                height: "10%",
                            }}
                            className="botones"
                        >
                            <button onClick={handleBack} className="custom-button-paso2">
                                Atrás
                            </button>
                            <button
                                onClick={handleNext}
                                disabled={!isFormValid()}
                                style={{
                                    width: "10rem",
                                    height: "4.8125rem",
                                    border: "none",
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Sora",
                                    fontSize: "1.25rem",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    marginBottom: "0.938rem",
                                    borderRadius: "1.25rem",
                                    background: !isFormValid()
                                        ? "grey"
                                        : "linear-gradient(90deg, #f0ece3 0.01%, #dab587 149.98%)",
                                    boxShadow: !isFormValid()
                                        ? "none"
                                        : "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                                    cursor: !isFormValid() ? "not-allowed" : "pointer",
                                    pointerEvents: !isFormValid() ? "none" : "auto",
                                }}
                            >
                                Siguiente
                            </button>
                        </Box>
                    </Box>
                )}
                {activeStep === 3 && (
                    <Box
                        className="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100vh", // Altura completa de la ventana
                            overflowY: "auto", // Habilitar scroll si el contenido es demasiado alto
                        }}
                    >
                        <Box
                            width="100%" // Correcto: como string con porcentaje
                            sx={{
                                padding: "-1rem",
                            }}
                        >
                            {/* Autocompletar Dirección */}
                            <Typography
                                sx={{
                                    fontFamily: "Sora",
                                    color: "white",
                                    fontSize: "1.25rem",
                                    fontWeight: "400",
                                }}
                            >
                                Dirección
                            </Typography>
                            {isLoaded ? (
                                <>
                                    {/* Campo para buscar la dirección */}
                                    <PlacesAutocomplete
                                        value={formState.direccion}
                                        onChange={(value) => updateFormState("direccion", value)}
                                        onSelect={async (selectedAddress: string) => {
                                            try {
                                                updateFormState("direccion", selectedAddress);
                                                const results = await geocodeByAddress(selectedAddress);
                                                if (results.length > 0) {
                                                    const latLng = await getLatLng(results[0]);
                                                    setCoordinates(latLng); // Actualizamos las coordenadas aquí
                                                }
                                            } catch (error) {
                                                console.error("Error al seleccionar la dirección:", error);
                                            }
                                        }}

                                        searchOptions={{
                                            bounds: {
                                                north: -31.2, // Coordenadas del área que deseas cubrir
                                                south: -31.6,
                                                east: -64.0,
                                                west: -64.3,
                                            },
                                            componentRestrictions: { country: "ar" }, // Restringe a Argentina
                                        }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div style={{ padding: "1rem" }}>
                                                <TextField
                                                    {...getInputProps({
                                                        placeholder: "Escribe una dirección...",
                                                    })}
                                                    className="text"
                                                    label="Dirección *"
                                                    variant="standard"
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputLabel-root": { color: "#fff" },
                                                        "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                                                            borderBottomColor: errors.carta ? "red" : "#fff",
                                                        },
                                                        input: { color: "#fff" },
                                                        "& .MuiInputBase-input::placeholder": {
                                                            color: "#fff",
                                                            opacity: 0.5,
                                                        },
                                                    }}
                                                />
                                                <div>
                                                    {loading && <div>Cargando...</div>}
                                                    {suggestions.map((suggestion) => {
                                                        const style = {
                                                            backgroundColor: suggestion.active ? "#f0f0f0" : "#fff",
                                                            padding: "0.5rem",
                                                        };

                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, { style })}
                                                                key={suggestion.placeId}
                                                            >
                                                                {suggestion.description}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {isLoaded && coordinates ? (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <GoogleMap
                                                id="map"
                                                mapContainerStyle={{
                                                    width: "90%", // Ocupa el 100% del ancho disponible
                                                    height: "300px", // La altura será el 50% de la altura de la ventana
                                                    borderRadius: "10px", // Mantén el estilo redondeado si es necesario
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                                center={coordinates}
                                                zoom={15}
                                                options={{
                                                    disableDefaultUI: true,
                                                    mapId: "80f094560809c165",
                                                    minZoom: 10,
                                                    maxZoom: 18,
                                                }}
                                                onLoad={(map) => {
                                                    setMapInstance(map); // Guarda la instancia del mapa
                                                    renderAdvancedMarker(map, coordinates, "/assets/consultarCafeteria/Logo Cofflick (1).svg");
                                                }}
                                            ></GoogleMap>
                                        </div>
                                    ) : (
                                        <Typography>Cargando mapa...</Typography>
                                    )}

                                </>
                            ) : (
                                <Typography>Cargando mapa...</Typography>
                            )}

                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Sora",
                                    color: "white",
                                    fontSize: "1.25rem",
                                    fontWeight: "400",
                                    marginBottom: "0.625rem",
                                }}
                            >
                                Subir Imágenes (Minimo 1)
                            </Typography>
                            <label htmlFor="upload-images" className="boton-chiquito" style={{ padding: "5px" }}>
                                Subir
                            </label>
                            <input
                                id="upload-images"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={async (e) => {
                                    const files = e.target.files;
                                    if (!files) return;

                                    const MAX_SIZE_MB = 10;
                                    let totalSize = 0;
                                    const updatedImages: Imagen[] = [...formState.imagenes]; // Clonar imágenes actuales

                                    for (const file of Array.from(files)) {
                                        totalSize += file.size / 1024 / 1024;
                                        if (totalSize > MAX_SIZE_MB) {
                                            setErrors((prev) => ({
                                                ...prev,
                                                imagenes: ["El tamaño total de las imágenes no debe superar los 10 MB."],
                                            }));
                                            return;
                                        }

                                        const reader = new FileReader();
                                        reader.readAsDataURL(file);

                                        await new Promise<void>((resolve) => {
                                            reader.onload = () => {
                                                updatedImages.push({
                                                    id_foto: null, // Nueva imagen (sin ID)
                                                    datos: reader.result as string, // Base64 completa
                                                });
                                                resolve();
                                            };
                                        });
                                    }

                                    updateFormState("imagenes", updatedImages);
                                }}
                                style={{ display: "none" }}
                            />

                            {errors.imagenes && <p style={{ color: "red" }}>{errors.imagenes[0]}</p>}

                            {/* Mostrar vista previa de imágenes con botón de eliminación */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "1rem",
                                    marginTop: "1rem",
                                    justifyContent: "center",
                                }}
                            >
                                {formState.imagenes.map((image, index) => (
                                    image.datos != null ? (
                                        <Box
                                            key={index}
                                            sx={{
                                                position: "relative",
                                                width: "100px",
                                                height: "100px",
                                            }}
                                        >
                                            <img
                                                src={
                                                    image.datos?.startsWith("data:image")
                                                        ? image.datos
                                                        : `data:image/jpeg;base64,${image.datos}`
                                                }
                                                alt={`Imagen ${index + 1}`}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                    borderRadius: "8px",
                                                    border: "1px solid #ccc",
                                                }}
                                            />
                                            <button
                                                onClick={() => {
                                                    const updatedImages = [...formState.imagenes];
                                                    updatedImages[index] = { ...image, datos: null };
                                                    updateFormState("imagenes", updatedImages);
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    right: "5px",
                                                    backgroundColor: "red",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                ×
                                            </button>
                                        </Box>
                                    ) : null
                                ))}
                            </Box>

                        </Box>

                        {/* Botones */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                padding: "1rem",
                                height: "10%",
                            }}
                            className="botones"
                        >
                            <button onClick={handleBack} className="custom-button-paso2">
                                Atrás
                            </button>
                            <button
                                onClick={handleFinalizar}
                                disabled={!canFinalize()}
                                style={{
                                    width: "10rem",
                                    height: "4.8125rem",
                                    border: "none",
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Sora",
                                    fontSize: "1.25rem",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    marginBottom: "0.938rem",
                                    borderRadius: "1.25rem",
                                    background: !canFinalize()
                                        ? "grey"
                                        : "linear-gradient(90deg, #f0ece3 0.01%, #dab587 149.98%)",
                                    boxShadow: !canFinalize()
                                        ? "none"
                                        : "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset",
                                    cursor: !canFinalize() ? "not-allowed" : "pointer",
                                    pointerEvents: !canFinalize() ? "none" : "auto",
                                }}
                            >
                                Finalizar
                            </button>
                        </Box>
                    </Box>
                )}
                <ReusableModal
                    open={modalConfirmacion}
                    onClose={() => { }}
                    title={tituloModal}
                    content={contenidoModal}
                    buttons={botonesModal}
                />
            </div>
        );
    }
);

const ModificarCafeteria: React.FC<modificarCafeteriaProps> = ({ apiKey }) => {
    const usuario = useAuth();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [preferencias, setPreferencias] = useState<number[]>([]);
    const [formState, setFormState] = useState<{
        nombre: string;
        descripcion: string;
        telefono: string;
        email: string;
        carta: string;
        links: string[];
        franjasHorarias: { id_dia: number; hora_apertura: string; hora_cierre: string }[];
        direccion: string;
        imagenes: Imagen[]; // Cambia de string[] a Imagen[]
    }>({
        nombre: "",
        descripcion: "",
        telefono: "",
        email: "",
        carta: "",
        links: [""],
        franjasHorarias: [{ id_dia: 1, hora_apertura: "09:00", hora_cierre: "18:00" }],
        direccion: "",
        imagenes: [] as Imagen[], // Inicialmente vacío
    });

    // Función genérica para actualizar cualquier propiedad en formState
    const updateFormState = (key: string, value: any) => {
        setFormState((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleNext = useCallback(() => {
        if (activeStep === 0 && (!formState.nombre || !formState.descripcion)) {
            alert("Por favor completa el nombre y la descripción.");
            return;
        }
        if (activeStep === 1 && preferencias.length < 4) {
            alert("Selecciona al menos 4 preferencias.");
            return;
        }
        if (activeStep === 2) {
        }

        setActiveStep((prev) => prev + 1);
    }, [activeStep, formState, preferencias]);

    const handleBack = useCallback(() => {
        setActiveStep((prev) => Math.max(prev - 1, 0));
    }, []);

    return (
        <div className="background">
            <div style={{ width: "100%", padding: "1rem", marginTop: "1.5rem" }}>
                <Box
                    component="form"
                    className="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                        m: 0
                    }}
                >
                    <CustomStepper activeStep={activeStep} />
                </Box>
            </div>
            <StepContent
                activeStep={activeStep}
                preferencias={preferencias}
                setPreferencias={setPreferencias}
                formState={formState}
                updateFormState={updateFormState}
                handleNext={handleNext}
                handleBack={handleBack}
                apiKey={apiKey}
                usuario={usuario}
                setFormState={setFormState}
            />
        </div>
    );
};

export default ModificarCafeteria;
