import React, { useState } from "react";
import { IconButton, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ruta from "./Ruta";
import ReusableModal from "../Modales/Modal";

interface SwipeToSlideProps {
  rutas: any[]; // Ajusta el tipo según tu necesidad
  loading: boolean;
  uid?: string; // Recibir el UID como prop
  eliminarActivo?: boolean; // Nuevo prop para controlar los botones de eliminar
  onEliminarRuta?: (id: number) => Promise<void>; // Callback para manejar la eliminación
}

export default function SliderRutas({
  rutas,
  loading,
  eliminarActivo = false,
  onEliminarRuta,
  uid, // Asegurarse de que uid sea recibido
}: SwipeToSlideProps) {
  const [modalConfirmacion, setModalConfirmacion] = useState(false);
  const [rutaSeleccionada, setRutaSeleccionada] = useState<number | null>(null);
  const [loadingEliminar, setLoadingEliminar] = useState(false); // Controla el estado de eliminación

  const settings = {
    className: "center",
    infinite: rutas.length > 1, // Desactivar el modo infinito si solo hay 1 ruta
    centerPadding: "50px",
    slidesToShow: rutas.length > 1 ? 2 : 1, // Mostrar solo 1 slide si hay 1 ruta
    swipeToSlide: true,
  };

  const handleEliminarClick = (id: number) => {
    setRutaSeleccionada(id);
    setModalConfirmacion(true); // Abre el modal
  };

  const handleConfirmarEliminar = async () => {
    if (rutaSeleccionada !== null && onEliminarRuta) {
      try {
        setLoadingEliminar(true); // Activar estado de carga
        if (uid) {
          await onEliminarRuta(rutaSeleccionada); // Ejecutar la eliminación
        } else {
          console.error("UID no disponible para eliminar la ruta");
        }
        setLoadingEliminar(false); // Desactivar estado de carga
        setModalConfirmacion(false); // Cerrar modal
      } catch (error) {
        console.error("Error al eliminar la ruta:", error);
        setLoadingEliminar(false); // Desactivar estado de carga en caso de error
      }
    }
  };

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {loading
            ? Array.from({ length: 2 }).map((_, index) => (
              <Grid item key={index}>
                {/* Aquí podrías añadir un Skeleton para mostrar mientras se cargan los datos */}
              </Grid>
            ))
            : rutas.map((ruta: any, index: number) => (
              <div key={index} style={{ position: "relative" }}>
                {/* Renderizar cada ruta */}
                <Ruta
                  {...ruta}
                  eliminarActivo={eliminarActivo} // Pasar el prop para controlar la visualización del botón
                  onEliminar={() => handleEliminarClick(ruta.id)} // Pasar el callback para abrir el modal
                />
              </div>
            ))}
        </Slider>
      </div>

      {/* Modal de confirmación */}
      <ReusableModal
        open={modalConfirmacion}
        onClose={() => setModalConfirmacion(false)}
        title="Confirmar eliminación"
        content="¿Estás seguro de que deseas eliminar esta ruta?"
        buttons={[
          {
            label: loadingEliminar ? "Eliminando..." : "Eliminar", // Mostrar estado de carga en el botón
            onClick: () => {
              if (!loadingEliminar) {
                handleConfirmarEliminar();
              }
            }, // Solo ejecutar la eliminación si no está en estado "Eliminando"
            sx: {
              background: "#F0ECE3",
              color: "#C60F0F",
              cursor: loadingEliminar ? "not-allowed" : "pointer", // Cambiar el cursor cuando está deshabilitado
              pointerEvents: loadingEliminar ? "none" : "auto", // Deshabilitar el clic si está eliminando
            },
          },
          {
            label: "Cancelar",
            onClick: () => setModalConfirmacion(false),
            sx: { background: "#44291E", color: "#F0ECE3" },
          },
        ]}
      />
    </>
  );
}
