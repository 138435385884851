import { Grid, Link, Typography } from "@mui/material";

interface IContacto {
  contacto: any
}

export default function Contacto (props: IContacto) {

  const handleClick = (pagina: string) => {
    window.open(pagina, "_blank", "noopener");
  };
  
  return (
    <div className="promociones">
      {/* Aquí va el contenido de contacto */}
      <div style={{}}>
        <Grid container alignItems={"center"}>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <img
              src="/assets/consultarCafeteria/contacto-icon.svg"
              alt="contatoIcon"
              style={{
                width: "1.375rem", // Definir el ancho explícito
                height: "1.375rem", // Definir la altura explícita
                verticalAlign: "middle", // Alineación vertical
                paddingBottom: "5px",
                paddingRight: "5px",
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontFamily: "Higuen",
                color: "#003147",
                fontSize: "1.25rem",
                fontWeight: "400",
                marginBottom: "0.625rem",
              }}
            >
              Contacto
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container margin={"0.2rem"}>
          <Grid item xs={1}>
            <img
              src="/assets/consultarCafeteria/telefonoIcon.svg"
              alt="telefonoIcon"
            />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#000",
                fontSize: "0.6875rem",
                fontWeight: "400",
              }}
            >
              {props.contacto?.telefono}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img src="/assets/consultarCafeteria/mailIcon.svg" alt="mailIcon" />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#000",
                fontSize: "0.6875rem",
                fontWeight: "400",
              }}
            >
              {props.contacto?.mail}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <img
              src="/assets/consultarCafeteria/linkWebIcon.svg"
              alt="linkIcon"
            />
          </Grid>
          <Grid item xs={11}>
            {/* Mostrar cada página web en una línea separada */}
            {props.contacto?.paginas_web?.map(
              (pagina: string, index: number) => (
                <Link
                  key={index}
                  component="button"
                  variant="body2"
                  onClick={() => handleClick(pagina)}
                  sx={{
                    display: "block", // Para mostrar cada link en una nueva línea
                    fontFamily: "Sora",
                    color: "#000",
                    fontSize: "0.6875rem",
                    fontWeight: "400",
                    marginTop: index > 0 ? "0.5rem" : "0", // Margen superior solo si hay más de un link
                  }}
                >
                  {pagina}
                </Link>
              )
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
