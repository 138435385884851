import React, { useEffect, useState } from "react";
import "../../styles/NavCafeteria.css"; // Importar los estilos CSS
import { IconButton } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useNavigate } from "react-router-dom";

const NavCafeteria: React.FC<{ select: number }> = (parametro) => {
  const [select, setSelect] = useState<number>(parametro.select);
  const navigate = useNavigate();

  const handleSelect = (idSelect: number) => {
    setSelect(idSelect);
  };

  useEffect(() => {
    switch (select) {
      case 1:
        navigate("/foro-cafeteria");
        break;
      case 2:
        navigate("/eventos-cafeteria");
        break;
      case 3:
        navigate("/inicio-cafeteria");
        break;
      case 4:
        navigate("/promociones-cafeteria");
        break;
      case 5:
        navigate("/ver-perfil-cafeteria");
        break;
    }
  }, [select, navigate]);

  return (
    <nav className="nav-cafeteria">
      <div className="nav-container-cafeteria">
        <IconButton
          aria-label="Chat"
          onClick={() => handleSelect(1)}
          sx={{
            backgroundColor: select === 1 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/chatIcon.svg"
            alt="Chat"
            style={{
              color: select === 1 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Eventos"
          onClick={() => handleSelect(2)}
          sx={{
            backgroundColor: select === 2 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/coffeCupIcon.svg"
            alt="Coffee Cup"
            style={{
              color: select === 2 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Home"
          onClick={() => handleSelect(3)}
          sx={{
            backgroundColor: select === 3 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/homeIconCremita.svg"
            alt="Home"
            style={{
              color: select === 3 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Promociones"
          onClick={() => handleSelect(4)}
          sx={{
            backgroundColor: select === 4 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/homeCafeteria/logoPromocionesCafeteriaNavIcon.svg"
            alt="Promociones"
            style={{
              color: select === 4 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="CafeteriaPerfil"
          onClick={() => handleSelect(5)}
          sx={{
            backgroundColor: select === 5 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/homeCafeteria/cafeteriaPerfilIcon.svg"
            alt="Promociones"
            style={{
              color: select === 5 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
      </div>
    </nav>
  );
};

export default NavCafeteria;