// src/components/InicioCafeteria.tsx
import React, { useEffect, useState } from "react";
import Cabecera from "../../components/home/Cabecera";
import "../../styles/Inicio.css";
import NavCafeteria from "../../components/home/NavCafeteria";
import { Box, Grid, Typography } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
  getCafeteria,
  //getPromociones,
  //getReseñas,
  //getEventos,
  getTags,
} from "../../App/Services/cafeteriaService";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
//import ReseñasCafeteria from "../../components/reseña/SliderReseña"; las tengo que hacer desde cero
import Evento from "../../components/eventos/Evento";
import { getEventosCafeteria } from "../../App/Services/Cafeteria/eventosServices";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import EventoCafeteria from "../../components/eventos/EventoCafeteria";

const EventosCafeteria: React.FC = () => {
  const usuario = useAuth();
  const [cafeteria, setCafeteria] = useState<any>(null);
  const [eventos, setEventos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleRegistrarEvento = () => {
    navigate(`/registrar-evento-cafeteria`);
  };

  useEffect(() => {
    const fetchDatosCafeteria = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosCafeteria = await getCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setCafeteria(datosCafeteria);

          const eventos = await getEventosCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setEventos(eventos);
        } catch (error) {
          console.error("Error al obtener datos de la cafetería:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDatosCafeteria();
  }, [usuario]);

  return (
    <div className="inicio">
      <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />
      <div className="contenedor-paravos">
        <Grid
          container
          justifyContent={"initial"}
          sx={{ mb: "1rem" }}
          spacing={1}
        >
          <Grid item>
            <EventIcon sx={{ color: "#44291E" }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#44291E",
                fontSize: "0.9375rem",
                fontWeight: "600",
              }}
            >
              Tus eventos
            </Typography>
          </Grid>
        </Grid>
        <div className="background-scroll">
          {loading ? (
            <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton
                  key={index}
                  style={{
                    height: "9.125rem", // Altura fija
                    width: "90%", // Ancho relativo al contenedor
                    margin: "0 auto", // Centrar el esqueleto horizontalmente
                    borderRadius: "1.5625rem",
                    padding: "1rem 0.8rem",
                    marginBottom: "1rem",
                  }}
                />
              ))}
            </SkeletonTheme>
          ) : eventos.length > 0 ? ( // Eliminar llaves adicionales
            eventos.map((evento: any) => (
              <EventoCafeteria
                key={evento.id}
                id={evento.id}
                nombre={evento.nombre}
                datos_foto={evento.datos_foto}
                direccion={evento.direccion}
                descripcion={evento.descripcion}
                fecha={evento.fecha}
                hora={evento.hora}
                puede_editar={true}
              />
            ))
          ) : (
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "0.875rem",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              No hay eventos disponibles en este momento.
            </Typography>
          )}
        </div>
      </div>
      <Fab
        size="large"
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "5rem", // Ajusta según la separación que desees del borde inferior
          right: "2rem", // Ajusta según la separación que desees del borde derecho
          zIndex: 1000, // Asegura que el botón esté por encima de otros elementos
          backgroundColor: "#003147", // Color personalizado
          "&:hover": {
            backgroundColor: "#002236", // Color al hacer hover
          },
        }}
        onClick={handleRegistrarEvento}
      >
        <AddIcon />
      </Fab>
      <NavCafeteria select={2} />
    </div>
  );
};

export default EventosCafeteria;
