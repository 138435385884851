import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton, Input, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/LoginPage.css"; // Importar los estilos CSS
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { validarCorreo } from "../components/common/UtilidadesForm/ValidacionesCampos";


const LoginPage: React.FC = () => {
  const auth = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [contrasenia, setContrasenia] = useState<string | undefined>(undefined);
  const [errorAutenticacion, setErrorAutenticacion] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleIniciarSesion = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    auth.iniciarSesion(email!, contrasenia!, setErrorAutenticacion)
    
    };

  useEffect(() => {
    if (email != undefined) {
      setErrorEmail(!validarCorreo(email!));
    }
    }
    ,[email]);

  return (
    <div className="background">
      <div className="textoBienvenida">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="hola"
          sx={{
            fontFamily: "Higuen",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: "normal",
            fontWeight: 400,
          }} 
        >
          Hola,
        </Typography>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="bienvenido"
          sx={{
            fontFamily: "Higuen",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }} 
        >
          Bienvenido de nuevo!
        </Typography>
      </div>
      <div className="rectanguloAzulLogin">
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className="labelIniciarSesion"
        >
          Iniciar sesión
        </Typography>
        <Box
          component="form"
          className="form"
          noValidate
          autoComplete="off"
          sx={{ m: 1 }}
        >
          <TextField
            className="text"
            id="standard-basic"
            label="Email"
            variant="standard"
            placeholder="email@gmail.com"
            size={"small"}
            sx={{
              margin: "0.5rem",
              //color de la línea inferior del "Email"
              "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
                {
                  borderBottomColor: "white", // Color de la línea por defecto
                },
              "& .MuiInputLabel-root": {
                color: "#fff", // Color del label por defecto
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#fff", // Color del label cuando el TextField está enfocado
              },
              input: {
                color: "white", // Color del texto del input
              },
              "& .MuiInputBase-input::placeholder": {
                color: "white", // Color del placeholder
                opacity: 0.5, // Opacidad del placeholder
              },
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errorEmail && (
            <Typography color="#F00" sx={{ fontFamily: "Sora" }}>
              No es un mail válido
            </Typography>
          )}
          <FormControl className="text" variant="standard">
            <InputLabel
              htmlFor="standard-adornment-password"
              sx={{
                color: "#fff", // Color del label por defecto
                "&.Mui-focused": {
                  color: "#fff", // Color del label cuando el TextField está enfocado
                },
              }}
            >
              Contraseña
            </InputLabel>
            <Input
              id="standard-adornment-password"
              className="inputContraseña"
              placeholder="***********"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{
                      color: "white", // Color del IconButton
                      opacity: 0.5,
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                input: {
                  color: "white", // Color del texto del input
                },
                "&:before": {
                  borderBottomColor: "white", // Color de la línea inferior antes del foco
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "white", // Color de la línea inferior en hover
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Color del placeholder
                  opacity: 0.5, // Opacidad del placeholder
                },
              }}
              onChange={(e) => setContrasenia(e.target.value)}
            />
          </FormControl>
          <Link to="/recuperar" className="link-olvidaste-contrasenia">
            ¿Olvidaste tu contraseña?
          </Link>
          {errorAutenticacion && (
            <Typography
              color="#F00"
              sx={{ fontFamily: "Sora", marginTop: "1rem" }}
            >
              El mail y/o la contraseña son incorrectos
            </Typography>
          )}
          <button
            disabled={!(email && contrasenia && !errorEmail)}
            onClick={handleIniciarSesion}
            className="custom-button"
          >
            Iniciar sesión
          </button>
          <Typography variant="body2" className="labelCuenta">
            No tienes una cuenta?{" "}
            <Link to="/signup" className="linkRegistrate">
              Registrate
            </Link>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default LoginPage;