import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Slider from "react-slick";
import "../../../styles/consultarCafeteria.css";

interface IReseña {
  idReview: number;
  puntaje: number;
  descripcion: string;
}

interface IReseñasHomeCafeteriaProps {
  reseñas: IReseña[];
}

const ReseñasHomeCafeteria: React.FC<IReseñasHomeCafeteriaProps> = ({
  reseñas,
}) => {
  const sliderSettings = {
    dots: false,
    infinite: reseñas.length > 3, // Solo habilita el infinito si hay más de 3 reseñas
    speed: 500,
    slidesToShow: Math.min(reseñas.length, 3), // Muestra solo la cantidad de reseñas disponibles
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Para pantallas más pequeñas
        settings: {
          slidesToShow: Math.min(reseñas.length, 2), // Muestra máximo 2 o menos
        },
      },
      {
        breakpoint: 480, // Para móviles
        settings: {
          slidesToShow: Math.min(reseñas.length, 2), // Muestra máximo 2 o menos
        },
      },
    ],
  };

  return (
    <Box className="reseñas-slider" sx={{ margin: "1rem 2rem" }}>
      <Slider {...sliderSettings}>
        {reseñas.map((reseña) => (
          <Box
            key={reseña.idReview}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                backgroundColor: "#F5F5F5",
                borderRadius: "1.0625rem",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                width: "8.3125rem",
                height: "5.0625rem",
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Sora",
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#003147",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {reseña.puntaje}
                  <img
                    src="/assets/consultarCafeteria/estrellaDeReseñasIcon.svg"
                    alt="estrella"
                    style={{
                      marginLeft: "0.5rem",
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    fontSize: "0.5625rem",
                    color: "#757575",
                    marginTop: "0.5rem",
                    lineHeight: "1.2",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                  }}
                >
                  {reseña.descripcion}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ReseñasHomeCafeteria;
