import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "../../styles/Cabecera.css";
import {
  getNotificaciones,
  getTieneNotificacionesSinLeer,
  INotificacion,
} from "../../App/Services/notificaciones";
import { useAuth } from "../../context/AuthContext";

export default function Cabecera(props: any) {
  const navigate = useNavigate();
  const usuario = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tieneNotifSinLeer, setTieneNotifSinLeer] = useState<boolean>(false);
  const [notificaciones, setNotificaciones] = useState<INotificacion[]>([]);

  // Maneja el clic en el icono de notificaciones
  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    handleOpenNotif();
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (props.es_home && usuario.user?.uid) {
      getTieneNotificacionesSinLeer(usuario.user?.uid).then((res: boolean) => {
        setTieneNotifSinLeer(res);
      });
    }
  }, [usuario.user]);

  const handleOpenNotif = () => {
    if (usuario.user?.uid) {
      getNotificaciones(usuario.user?.uid).then((res: INotificacion[]) => {
        setNotificaciones(res);
      });
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const notifications = [
    {
      id: 1,
      user: "Sofía",
      text: "ha posteado en “Cultura de café”",
      avatar: "https://via.placeholder.com/40",
    },
    {
      id: 2,
      user: "Sofía",
      text: "te ha contestado en tu post en “Cultura de café”",
      avatar: "https://via.placeholder.com/40",
    },
    {
      id: 3,
      user: "COOFF",
      text: "ha añadido una nueva promoción limitada",
      avatar: "https://via.placeholder.com/40",
    },
  ];

  const handleNavigateToFavs = () => {
    navigate("/ver-favs");
  };

  return (
    <footer className="cabecera">
      <div className="logo-cab"></div>
      {props.es_home && (
        <div className="botones-home">
          <button
            className="boton-fav"
            onClick={handleNavigateToFavs} // Agrega el evento onClick para manejar la navegación
          ></button>
          <IconButton
            onClick={handleNotificationClick}
            sx={{
              width: "2.8125rem",
              height: "2.8125rem",
              backgroundColor: "#fff",
              borderRadius: "50%", // Forma circular
              padding: "10px", // Espaciado interno para que el icono quede centrado
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra para dar relieve
              "&:hover": {
                backgroundColor: "#f5f5f5", // Fondo al pasar el mouse
              },
            }}
          >
            {tieneNotifSinLeer ? (
              <Badge
                color="primary"
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: "#003147", // Personaliza el color del indicador
                    width: 12,
                    height: 12,
                  },
                }}
              >
                <NotificationsIcon sx={{ color: "#003147" }} />
              </Badge>
            ) : (
              <NotificationsIcon sx={{ color: "#003147" }} />
            )}
          </IconButton>
        </div>
      )}
      {props.nombre_cafeteria && (
        <Typography
          className="nombre-cafeteria"
          sx={{ fontFamily: "Higuen", mr: "2.75rem", mt: "2rem", color: "#44291E", fontSize: "1.5rem"}}
        >
          {props.nombre_cafeteria}
        </Typography>
      )}
      {/* Popover de notificaciones */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Sora",
            fontSize: "1.125rem",
            fontStyle: "normal",
            fontWeight: "600",
            padding: "8px 16px",
          }}
        >
          Notificaciones
        </Typography>
        <List>
          {notificaciones.length != 0 ? (
            notificaciones.map((notificacion) => (
              <ListItem
                key={notificacion.id}
                alignItems="flex-start"
                sx={{
                  backgroundColor:
                    notificacion.estado === "unread" ? "#e0e0e0" : "#ffffff",
                  padding: "8px 16px",
                  borderBottom: "1px solid #ccc", // Línea separadora
                  "&:hover": {
                    backgroundColor: "#d5d5d5", // Fondo al pasar el mouse
                  },
                }}
              >
                <ListItemText
                  primary={notificacion.titulo}
                  secondary={notificacion.mensaje}
                />
              </ListItem>
            ))
          ) : (
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "1.0625rem",
                fontStyle: "normal",
                p: "2rem"
              }}
            >
              {" "}
              No tienes notificaciones sin leer{" "}
            </Typography>
          )}
        </List>
      </Popover>
    </footer>
  );
}
