import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  Dispatch,
  useRef,
} from "react";
import { auth } from "../firebase/firebase.config";
import { useCallback } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
  User,
  deleteUser,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  existeUsuario,
  postUsuario,
  tieneCafeteriaAsociada,
} from "../App/Services/UsuarioService/UsuarioService";
import { storeJWT } from "../App/Token";
import { getPreferencias } from "../App/Services/meriendistaService";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  user: User | null;
  loading: boolean;
  registrar: (
    email: string,
    contraseña: string,
    nombre: string,
    apellido: string,
    setError: Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  iniciarSesion: (
    email: string,
    contraseña: string,
    setError: Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  iniciarConGoogle: () => Promise<void>;
  deslogueo: () => Promise<void>;
  recuperarContrasenia: (email: string) => Promise<any>;
  actualizarContrasenia: (code: string, contrasenia: string) => Promise<any>;
  bajaUsuario: (user: User) => Promise<any>;
}

const defaultAuthContextValue: AuthContextType = {
  user: null,
  registrar: async () => {},
  iniciarSesion: async () => {},
  iniciarConGoogle: async () => {},
  deslogueo: async () => {},
  recuperarContrasenia: async () => {},
  actualizarContrasenia: async () => {},
  bajaUsuario: async () => {},
  loading: false,
};

export const authContext = createContext<AuthContextType>(
  defaultAuthContextValue
);

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) {
    console.log("Error creating auth context");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

const DEFAULT_TIMEOUT = 100000 * 60 * 1000; // 10 minutos en milisegundos

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [inactivityDuration, setInactivityDuration] = useState(DEFAULT_TIMEOUT); // Valor configurable
  const navigate = useNavigate();
  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);

  const storeLastActivity = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("lastActivity", currentTime.toString());
  };

  const deslogueo = useCallback(async () => {
    await signOut(auth);
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("lastActivity");
  }, []);

  const checkInactivity = useCallback(() => {
    const lastActivity = localStorage.getItem("lastActivity");
    if (lastActivity) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastActivity, 10);
      if (timeDifference > inactivityDuration) {
        deslogueo();
        navigate("/");
      }
    }
  }, [inactivityDuration, deslogueo, navigate]);

  const startInactivityTimeout = useCallback(() => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
    }

    inactivityTimeout.current = setTimeout(() => {
      deslogueo();
      navigate("/");
    }, inactivityDuration);

    storeLastActivity();
  }, [deslogueo, navigate, inactivityDuration]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkInactivity();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    checkInactivity();

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        localStorage.setItem("user", JSON.stringify(currentUser));
        startInactivityTimeout();
      } else {
        localStorage.removeItem("user");
      }
      setLoading(false);
    });

    const resetLastActivity = () => {
      startInactivityTimeout();
    };

    window.addEventListener("mousemove", resetLastActivity);
    window.addEventListener("mousedown", resetLastActivity);
    window.addEventListener("keypress", resetLastActivity);
    window.addEventListener("touchstart", resetLastActivity);

    return () => {
      unsubscribe();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (inactivityTimeout.current) {
        clearTimeout(inactivityTimeout.current);
      }
      window.removeEventListener("mousemove", resetLastActivity);
      window.removeEventListener("mousedown", resetLastActivity);
      window.removeEventListener("keypress", resetLastActivity);
      window.removeEventListener("touchstart", resetLastActivity);
    };
  }, [
    navigate,
    inactivityDuration,
    checkInactivity,
    startInactivityTimeout,
    inactivityTimeout,
  ]); // Escuchar cambios en inactivityDuration

  const registrar = async (
    email: string,
    contraseña: string,
    nombre: string,
    apellido: string,
    setError: Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        contraseña
      );
      await postUsuario({
        nombre,
        apellido,
        uuid: response.user.uid,
        email,
      });
      setUser(response.user);
      localStorage.setItem("user", JSON.stringify(response.user));
      navigate("/comencemos");
      startInactivityTimeout();
    } catch (error) {
      setError(true); // Cambia esto a `true` o `false` según corresponda
      console.error("Error en el registro:", error);
    }
  };

  //corrijo aca para que haga el siguiente camino
  // primero se fija si tiene cafeteria, si es asi redirije a seleccionar rol
  // si no tiene cafeteria se fija si tiene preferencias registradas, en caso de no tener redirije a registrar pref
  // sin caf y con pref redirije a inicio directamente
  const iniciarSesion = async (
    email: string,
    contraseña: string,
    setError: Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, contraseña);
      const token = await result.user.getIdToken();
      storeJWT(token);

      setUser(result.user);
      localStorage.setItem("user", JSON.stringify(result.user));
      const dataCafeteriaAsociada = await tieneCafeteriaAsociada(
        result.user.uid
      );
      const tieneCafAsociada = dataCafeteriaAsociada.tiene_cafeteria_asociada;
      if (tieneCafAsociada) {
        navigate("/seleccionar-rol");
      } else {
        const preferencias = await getPreferencias(result.user.uid);
        if (preferencias.message === "No tiene preferencias") {
          navigate("/registrar-preferencias");
        } else {
          navigate("/inicio");
        }
      }

      startInactivityTimeout();
    } catch (error) {
      setError(true);
      console.error("Error al iniciar sesión:", error);
    }
  };

  const iniciarConGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));

      const usuarioExiste = await existeUsuario(user.uid);
      if (!usuarioExiste.results) {
        const parts = user.displayName!.split(" ");
        const nombre = parts[0];
        const apellido = parts.slice(1).join(" ");

        await postUsuario({
          nombre: nombre,
          apellido: apellido,
          uuid: user.uid,
          email: user.email!,
        });
        navigate("/comencemos");
      } else {
        const dataCafeteriaAsociada = await tieneCafeteriaAsociada(user.uid);
        const tieneCafAsociada = dataCafeteriaAsociada.tiene_cafeteria_asociada;
        if (tieneCafAsociada) {
          navigate("/seleccionar-rol");
        } else {
          const preferencias = await getPreferencias(result.user.uid);
          if (preferencias.message === "No tiene preferencias") {
            navigate("/registrar-preferencias");
          } else {
            navigate("/inicio");
          }
        }
  
      }
      startInactivityTimeout();
    } catch (error) {
      console.error("Error en la autenticación con Google:", error);
      await deslogueo();
    }
  };

  const recuperarContrasenia = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const actualizarContrasenia = async (code: string, contrasenia: string) => {
    try {
      await verifyPasswordResetCode(auth, code);
      await confirmPasswordReset(auth, code, contrasenia);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const bajaUsuario = async (user: User): Promise<any> => {
    try {
      const response = await deleteUser(user);
      return response;
    } catch (error: any) {
      throw new Error(error?.message || "Error desconocido");
    }
  };

  return (
    <authContext.Provider
      value={{
        user,
        loading,
        registrar,
        iniciarSesion,
        iniciarConGoogle,
        deslogueo,
        recuperarContrasenia,
        actualizarContrasenia,
        bajaUsuario,
      }}
    >
      {loading ? null : children}
    </authContext.Provider>
  );
}
