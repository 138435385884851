import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import { useAuth } from "../../context/AuthContext"; // Asegúrate de ajustar la ruta según tu estructura
import { puntuarRuta } from "../../App/Services/rutasEventosServices"; // Importa la función

const FilledStarIcon = "/assets/rutasEventos/estrellaRutaRellenaIcon.svg";
const EmptyStarIcon = "/assets/rutasEventos/estrellaRutaVaciaIcon.svg";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "transparent",
    backgroundImage: `url(${FilledStarIcon})`,
    backgroundSize: "cover",
  },
  "& .MuiRating-iconEmpty": {
    backgroundImage: `url(${EmptyStarIcon})`,
    backgroundSize: "cover",
  },
  "& .MuiRating-iconHover": {
    backgroundImage: `url(${FilledStarIcon})`,
    backgroundSize: "cover",
  },
});

const ReseñaRuta: React.FC<{ idRuta: number }> = ({ idRuta }) => {
  const { user } = useAuth(); // Acceder al usuario autenticado
  const [open, setOpen] = useState(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [rating, setRating] = useState<number | null>(0);
  const [userHasRated, setUserHasRated] = useState(false);

  // Abrir el modal y resetear el estado de rating y userHasRated
  const handleClickOpen = () => {
    setUserHasRated(false); // Resetea el estado de si el usuario calificó
    setOpen(true);
  };

  // Cerrar el modal y resetear el estado
  const handleClose = () => {
    setOpen(false);
  };

  const handleThankYouClose = () => {
    setThankYouModalOpen(false);
  };

  const handleThankYouOpen = async () => {
    if (user && user.uid) {
      try {
        // Llamar al endpoint para puntuar la ruta
        await puntuarRuta(idRuta, user.uid, rating ?? 0);
        setOpen(false);
        setThankYouModalOpen(true);
      } catch (error) {
        console.error("Error al puntuar la ruta:", error);
      }
    } else {
      console.error("Usuario no autenticado");
    }
  };

  const handleRatingChange = (
    event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    setRating(newValue ?? 0);
    setUserHasRated(true);
  };

  return (
    <>
      {/* Botón para abrir el modal */}
      <Button
        onClick={handleClickOpen}
        endIcon={<StarIcon />}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "20.188rem",
          height: "1.875rem",
          margin: "0px auto",
          backgroundColor: "#003147",
          color: "#FFF",
          fontFamily: "Sora",
          fontSize: "0.75rem",
          fontStyle: "normal",
          textTransform: "none",
          fontWeight: 600,
          lineHeight: "normal",
          borderRadius: "8px",
        }}
      >
        ¿Ya hiciste esta ruta? Deja tu puntaje
      </Button>

      {/* Primer Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "20.1875rem",
            height: "13.6875rem",
            backgroundColor: "#F5F5F5",
            filter: "drop-shadow(-6px -6px 4px rgba(0, 0, 0, 0.25))",
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#44291E",
            fontFamily: "Higuen",
            fontSize: "1.563rem",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Dejanos tu calificación para esta Ruta de Café
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Calificación con estrellas */}
          <StyledRating
            name="customized-color"
            value={rating} // Usa el valor del estado
            onChange={handleRatingChange} // Cambia el estado al modificar la calificación
            precision={1}
            icon={<img src={FilledStarIcon} alt="filled star" />}
            emptyIcon={<img src={EmptyStarIcon} alt="empty star" />}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", paddingBottom: "1.688rem" }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              display: "flex",
              width: "7.563rem",
              height: "2.375rem",
              backgroundColor: "#44291E",
              borderRadius: "0.938rem",
              color: "#F0ECE3",
              textAlign: "center",
              fontFamily: "Sora",
              fontSize: "1.125rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "none",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleThankYouOpen} // Abre el segundo modal
            disabled={!userHasRated || rating === 0} // El botón se habilita solo si el usuario ha modificado la calificación y el valor del rating es mayor a 0
            sx={{
              display: "flex",
              width: "7.563rem",
              height: "2.375rem",
              backgroundColor:
                userHasRated && rating !== 0 ? "#F0ECE3" : "#ccc",
              borderRadius: "0.938rem",
              color: userHasRated && rating !== 0 ? "#44291E" : "#888",
              textAlign: "center",
              fontFamily: "Sora",
              fontSize: "1.125rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "none",
            }}
          >
            Listo
          </Button>
        </DialogActions>
      </Dialog>

      {/* Segundo Modal (Gracias por tu calificación) */}
      <Dialog
        open={thankYouModalOpen}
        onClose={handleThankYouClose}
        sx={{
          "& .MuiPaper-root": {
            width: "20.1875rem",
            height: "auto",
            backgroundColor: "#F5F5F5",
            filter: "drop-shadow(-6px -6px 4px rgba(0, 0, 0, 0.25))",
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            paddingTop: "1.875rem",
            textAlign: "center",
            color: "#44291E",
            fontFamily: "Higuen",
            fontSize: "1.563rem",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          ¡Gracias por tu calificación!
        </DialogTitle>
        <DialogActions
          sx={{ justifyContent: "center", paddingBottom: "1.688rem" }}
        >
          <Button
            onClick={handleThankYouClose}
            sx={{
              display: "flex",
              width: "7.563rem",
              height: "2.375rem",
              backgroundColor: "#F0ECE3",
              borderRadius: "0.938rem",
              color: "#44291E",
              textAlign: "center",
              fontFamily: "Sora",
              fontSize: "1.125rem",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "none",
            }}
          >
            Listo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReseñaRuta;
