import React from "react";
import "../styles/Comencemos.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, styled, Typography } from "@mui/material";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import { getPreferencias } from "../App/Services/meriendistaService";
import { useAuth } from "../context/AuthContext";

const StyledButton = styled(Button)({
  width: "14.375rem",
  height: "4.8125rem",
  borderRadius: "1.25rem",
  background: "linear-gradient(90deg, #F0ECE3 0.01%, #DAB587 149.98%)",
  color: "#000",
  textAlign: "center",
  fontFamily: "Sora",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  border: "none",
  cursor: "pointer",
  margin: "2rem",
});

const SeleccionRol: React.FC = () => {
  const navigate = useNavigate();
  const usuario = useAuth();

  const handleMeriendista = async () => {
    if (usuario.user) {
      try {
        const preferencias = await getPreferencias(usuario.user.uid);
        if (preferencias.message === "No tiene preferencias") {
          navigate("/registrar-preferencias");
        } else {
          navigate("/inicio");
        }
      } catch {}
    }
  };

  return (
    <div className="background-azul">
      <div>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontFamily: "Higuen",
            color: "#F0ECE3",
            fontSize: "2.1875rem",
            fontWeight: "400",
            marginBottom: "0.625rem",
          }}
        >
          ¿Cómo te gustaría ingresar?
        </Typography>
      </div>
      <div className="div-boton">
        <StyledButton onClick={() => navigate("/inicio-cafeteria")}>
          <span>Cafetería</span>
          <CoffeeMakerIcon fontSize="large" />
        </StyledButton>

        <StyledButton onClick={() => handleMeriendista()}>
          <span>Meriendista</span>
          <LocalCafeIcon fontSize="large" />
        </StyledButton>
      </div>
    </div>
  );
};

export default SeleccionRol;
