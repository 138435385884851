import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { logoForoPorId } from "../../pages/Foro";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface ITarjetaForo {
  id: number;
  id_tematica: number;
  titulo: string;
  descripcion: string;
  colorTitulo: string;
  icono?: React.ReactNode;
  onClick?: () => void; // Hacer que onClick sea opcional
  eliminarActivo?: boolean; // Prop opcional para habilitar modo eliminar
  onEliminar?: (id: number) => void; // Callback opcional para manejar la eliminación
}

export default function TarjetaForo({
  id,
  id_tematica,
  titulo,
  descripcion,
  colorTitulo,
  icono,
  onClick, // Recibir la función opcional
  eliminarActivo = false, // Recibir la prop eliminarActivo
  onEliminar, // Recibir el callback de eliminación
}: ITarjetaForo) {
  const navigate = useNavigate();

  // Función para manejar la navegación o invocar el onClick externo
  const handleConsultarForo = () => {
    if (onClick) {
      onClick(); // Llamar a la función pasada como prop si está definida
    } else {
      navigate(`/foro/${id}`); // Navegar a la ruta predeterminada si no hay onClick
    }
  };

  const handleEliminar = (event: React.MouseEvent) => {
    event.stopPropagation(); // Evitar que el clic también dispare el onClick del CardActionArea
    if (onEliminar) {
      onEliminar(id); // Invocar el callback de eliminación con el ID
    }
  };
  

  return (
    <Card
      sx={{
        borderRadius: "1.5625rem",
        padding: "1.3125rem 1.1875rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        height: "8.188",
        mb: "1rem",
        position: "relative", // Para posicionar el botón de eliminar
      }}
    >
      {eliminarActivo && (
        <IconButton
          onClick={handleEliminar}
          sx={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            backgroundColor: "#C60F0F",
            color: "white",
            borderRadius: "0.5rem",
            zIndex: 1,
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      )}
      <CardActionArea
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
        onClick={handleConsultarForo} // Usar la función que maneja ambos casos
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid>{logoForoPorId.get(id_tematica)}</Grid>
            <Grid>
              <Typography
                sx={{
                  fontFamily: "Higuen",
                  color: colorTitulo,
                  fontSize: "1.0625rem",
                  fontWeight: "400",
                  marginBottom: "0.625rem",
                }}
              >
                {titulo}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: "1.0625rem",
              variant: "body2",
              color: "textSecondary",
              style: { margin: "1rem 0" },
            }}
          >
            {descripcion}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
