import axios from "axios";
import { BASE_URL } from "./config";

export const getTieneNotificacionesSinLeer = async (idMeriendista: string): Promise<any> => {
    const response = await axios
      .get(`${BASE_URL}/notificaciones/activas/${idMeriendista}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw Error(error);
      });
    return response;
  };
  
  export interface INotificacion {
    id: number,
    meriendista: string,
    titulo: string,
    mensaje: string,
    estado: "read" | "unread",
    activo: boolean,
    creado: string,
    actualizado: string,
  }

  export const getNotificaciones = async (idMeriendista: string): Promise<INotificacion[]> => {
    const response = await axios
      .get(`${BASE_URL}/notificaciones/${idMeriendista}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw Error(error);
      });
    return response;
  };