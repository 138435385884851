import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cabecera from "../components/home/Cabecera";
import { Grid, Typography, Box, Modal, Rating, TextField, Button, IconButton } from "@mui/material";
import "../styles/consultarCafeteria.css";
import Nav from "../components/home/Nav";
import { getCafeteria, getReseñasCafeteria, postReseña } from "../App/Services/cafeteriaService";
import { IDatosCafeteria } from "./cafeterias/consultarCafeteria";
import RatingSize from "../components/pure/Estrellas";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ComponenteReseña from "../components/pure/Reseña";
import { useAuth } from "../context/AuthContext";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Reseñas() {

  const { id_cafeteria } = useParams();
  const usuario = useAuth();
  const [reseñas, setReseñas] = useState<any[]>();
  const [cafeteria, setCafeteria] = useState<IDatosCafeteria>();
  const [abrirModal, setAbrirModal] = useState<boolean>(false);
  const [puntos, setPuntos] = useState<number>(0);
  const [comentario, setComentario] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id_cafeteria) {
      actualizarReseñas();
    }
  }, [id_cafeteria]);

  const actualizarReseñas = () => {
    const id_cafeteria_number = parseInt(id_cafeteria!, 10); // Mueve la declaración aquí
    getCafeteria(id_cafeteria_number)
      .then((res: IDatosCafeteria) => setCafeteria(res))
      .catch(() => console.log("error al obtener informacion de la cafeteria"));

    getReseñasCafeteria(id_cafeteria_number)
      .then((res) => setReseñas(res))
      .catch(() => setReseñas([]));
  };

  const handleListo = () => {
    setAbrirModal(false); // Cierra el modal
  
    if (id_cafeteria && usuario?.user?.uid) { // Verificamos que id_cafeteria y usuario.user.uid existan
      const id_cafeteria_number = parseInt(id_cafeteria, 10); // Convertimos el ID a número
      
      postReseña(id_cafeteria_number, usuario.user.uid, puntos, comentario)
        .then(() => {
          actualizarReseñas(); // Actualiza las reseñas
          setPuntos(0); // Resetea los puntos
        })
        .catch((error) => {
          console.error("Error al registrar la reseña:", error); // Maneja el error
        });
    } else {
      console.error("El usuario no está autenticado o no tiene un UID, o el ID de la cafetería es inválido");
    }
  };

  const handleAtras = () => {
    navigate(`/cafeteria/${id_cafeteria}`);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '20.1875rem',
    height: 'auto',
    bgcolor: '#F5F5F5',
    boxShadow: '-6px -6px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
  };

  return (
    <>
      <IconButton 
        sx={{width: '2.1875rem',
            height: '2.1875rem',
            border:' none',
            borderRadius: '0.5625rem',
            zIndex: '3',
            top: '2rem', /* Alinea en la parte superior */
            right: '0', /* Fija a la derecha */
            marginRight: '2rem', /* Espaciado desde el borde derecho */
            color: '#000',
            backgroundColor: '#FFF',
            position: 'fixed',
          }}
        onClick={handleAtras}
        > 
        <ArrowBackIosNewIcon/> 
        </IconButton>
      <div className="inicio">
        <Cabecera es_home={false} />
        {cafeteria && (
          <div className="contenedor-margen">
            <Typography
              sx={{
                fontFamily: "Higuen",
                color: "#44291E",
                fontSize: "1.5625rem",
                fontWeight: "400",
                marginBottom: "0.625rem",
              }}
            >
              {cafeteria.nombre}
            </Typography>
            <Grid
              container
              alignItems={"center"}
              sx={{
                mt: '1rem',
                borderRadius: "0.5rem",
                background: "rgba(255, 255, 255, 0.57)",
                width: "auto",
                height: "3.07813rem",
              }}
              spacing={1}
            >
              <Grid item>
                <Typography
                  sx={{
                    justifyContent: "center",
                    marginLeft: "0.12rem",
                    color: "#003147",
                    fontFamily: "Sora",
                    fontSize: "1.25rem",
                    fontWeight: "600",
                  }}
                >
                  {cafeteria.puntaje}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    <RatingSize value={Math.round(cafeteria.puntaje)} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontSize: "0.5rem",
                      }}
                    >
                      {reseñas?.length} reseñas
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent={"initial"} sx={{ mt: "1rem" }} spacing={1}>
              <Grid item>
                <img src="/assets/reseña.svg" alt="Icono Reseña" />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Higuen",
                    color: "#003147",
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                  }}
                >
                  Reseñas
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        <div className="contenedor-margen" >
          <ComponenteReseña listaReseñas={reseñas} />
        </div>

        {/* Botón Fab fijo en la esquina inferior derecha */}
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: "5rem", // Ajusta según la separación que desees del borde inferior
            right: "2rem",  // Ajusta según la separación que desees del borde derecho
            zIndex: 1000,     // Asegura que el botón esté por encima de otros elementos
            backgroundColor: "#003147", // Color personalizado
            "&:hover": {
              backgroundColor: "#002236", // Color al hacer hover
            }
          }}
          onClick={() => setAbrirModal(true)}
        >
          <AddIcon />
        </Fab>
      </div>
      <Nav select={0} />
      <Modal
        open={abrirModal}
        onClose={() => setAbrirModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Higuen",
                  color: "#44291E",
                  fontSize: "1.5625rem",
                  fontWeight: "400",
                  marginBottom: "0.625rem",
                }}
              >
                Dejanos tu calificacion
              </Typography>
            </Grid>
            <Grid item>
              <Rating
                name="simple-controlled"
                value={puntos}
                onChange={(event, puntajeNuevo) => {
                  setPuntos(puntajeNuevo ? (puntajeNuevo) : (0));
                }}
              />
            </Grid>
            <Grid item container>
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Higuen",
                    color: "#44291E",
                    fontSize: "1.5625rem",
                    fontWeight: "400",
                    marginBottom: "0.625rem",
                  }}
                >
                  ¿Algún comentario?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  onChange={(e) => setComentario(e.target.value)}
                  fullWidth
                  sx={{height: '13.0625rem'}}
                />
              </Grid>
            </Grid>
            <Button 
              sx={{
                color: '#44291E',
                fontFamily: 'Sora',
                fontSize: '1.125rem',
                fontStyle: 'normal',
                fontWeight: '600',
                backgroundColor: '#F0ECE3',
                borderRadius: '4.75rem',
                width: '7.5625rem'
              }}
              onClick={handleListo}
              disabled={puntos === 0 || comentario === ""}
            >
              Listo
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
