import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/homePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignUpPage";
import Inicio from "./pages/inicio";
import Comencemos from "./pages/Comencemos";
import { AuthProvider } from "./context/AuthContext";
import RecuperarPage from "./pages/Recuperar";
import RestablecerPage from "./pages/Restablecer";
import ProtectedRoute from "./components/ProtectedRoute";
import ConsultarCafeteria from "./pages/cafeterias/consultarCafeteria"; // Make sure this import is correct
import RegistrarPreferencia from "./pages/RegistrarPreferencia";
import VerPerfil from "./pages/VerPerfil";
import Resultados from "./pages/Resultados";
import VerFavs from "./pages/VerFavs";
import MapaCafeterias from "./pages/MapaCafeterias";
import Reseñas from "./pages/Reseñas";
import Eventos from "./pages/rutasEventos/RutasEventosPage";
import RutaDelCafe from "./pages/rutasEventos/RutaDelCafePage";
import ConsultarEvento from "./pages/ConsultarEvento";
import CrearRuta from "./pages/CrearRuta";
import Foro from "./pages/Foro";
import ConsultarForo from "./pages/ConsultarForo";
import ResultadosForo from "./pages/ResultadosForo";
import ModificarPreferencia from "./pages/CambiarPreferencias";
import ComentariosPost from "./pages/ComentarioPost";
import SeleccionRol from "./pages/SeleccionRol";
import InicioCafeteria from "./pages/homeCafeteria/InicioCafeteria";
import RegistroCafeteria from "./pages/cafeterias/registrarCafeteria";
import { GoogleMapsProvider } from "./components/Mapa/GoogleMapsProvider";
import RegistrarEventoCafeteria from "./pages/cafeteria/registrarEventoCafeteria";
import PromocionesHomeCafeteria from "./pages/cafeteria/PromocionesHomeCafeteria";
import RegistrarPromocion from "./pages/cafeteria/registrarPromocion";
import ModificarPromocion from "./pages/cafeteria/modificarPromocion";
import ModificarEventoCafeteria from "./pages/cafeteria/modificarEventoCafeteria";
import ForoCafeteria from "./pages/Foros_Cafeterias/ForoCafe";
import ConsultarEventoCafeteria from "./pages/cafeteria/ConsultarEventoCafeteria";
import EventosCafeteria from "./pages/cafeteria/EventosCafeteria";
import VerPerfilCafeteria from "./pages/cafeteria/VerPerfilCafeteria";
import ModificarCafeteria from "./pages/cafeterias/ModificarCafeteria";
import RankingTopCafeterias from "./pages/RankingTopCafeterias";

const App: React.FC = () => {
  return (
    <Router>
      <GoogleMapsProvider apiKey="AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM">
        <AuthProvider>
          <Routes>
            {/* Rutas públicas */}
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />

            {/* Rutas protegidas */}
            <Route
              path="/inicio"
              element={
                <ProtectedRoute>
                  <Inicio />
                </ProtectedRoute>
              }
            />
            <Route
              path="/comencemos"
              element={
                <ProtectedRoute>
                  <Comencemos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/recuperar"
              element={
                <ProtectedRoute>
                  <RecuperarPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/restablecer"
              element={
                <ProtectedRoute>
                  <RestablecerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cafeteria/:id_cafeteria"
              element={
                <ProtectedRoute>
                  <ConsultarCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/registrar-preferencias"
              element={
                <ProtectedRoute>
                  <RegistrarPreferencia />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ver-perfil"
              element={
                <ProtectedRoute>
                  <VerPerfil />
                </ProtectedRoute>
              }
            />
            <Route
              path="/resultados"
              element={
                <ProtectedRoute>
                  <Resultados />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ver-favs"
              element={
                <ProtectedRoute>
                  <VerFavs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reseñas/:id_cafeteria"
              element={
                <ProtectedRoute>
                  <Reseñas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mapa"
              element={
                <ProtectedRoute>
                  <MapaCafeterias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rutas-eventos"
              element={
                <ProtectedRoute>
                  <Eventos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/evento/:id_evento"
              element={
                <ProtectedRoute>
                  <ConsultarEvento />
                </ProtectedRoute>
              }
            />
            <Route
              path="/evento-cafeteria/:id_evento"
              element={
                <ProtectedRoute>
                  <ConsultarEventoCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/crear-ruta/:uid"
              element={
                <ProtectedRoute>
                  <CrearRuta />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ruta-del-cafe/:id"
              element={
                <ProtectedRoute>
                  <RutaDelCafe
                    apiKey={"AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM"}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro"
              element={
                <ProtectedRoute>
                  <Foro />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro/:id_foro"
              element={
                <ProtectedRoute>
                  <ConsultarForo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro-cafeteria/:id_foro"
              element={
                <ProtectedRoute>
                  <ConsultarForo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/resultados-foro"
              element={
                <ProtectedRoute>
                  <ResultadosForo />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modificar-preferencias"
              element={
                <ProtectedRoute>
                  <ModificarPreferencia />
                </ProtectedRoute>
              }
            />
            <Route
              path="/registrar-cafeteria"
              element={
                <ProtectedRoute>
                  <RegistroCafeteria
                    apiKey={"AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM"}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro"
              element={
                <ProtectedRoute>
                  <Foro />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro/:id_foro"
              element={
                <ProtectedRoute>
                  <ConsultarForo />
                </ProtectedRoute>
              }
            />
            <Route
              path="posteo/:id_foro/:id_post"
              element={
                <ProtectedRoute>
                  <ComentariosPost />
                </ProtectedRoute>
              }
            />
            <Route
              path="/seleccionar-rol"
              element={
                <ProtectedRoute>
                  <SeleccionRol />
                </ProtectedRoute>
              }
            />
            <Route
              path="/inicio-cafeteria"
              element={
                <ProtectedRoute>
                  <InicioCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/eventos-cafeteria"
              element={
                <ProtectedRoute>
                  <EventosCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/registrar-evento-cafeteria"
              element={
                <ProtectedRoute>
                  <RegistrarEventoCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/promociones-cafeteria"
              element={
                <ProtectedRoute>
                  <PromocionesHomeCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/foro-cafeteria"
              element={
                <ProtectedRoute>
                  <ForoCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/registrar-promocion"
              element={
                <ProtectedRoute>
                  <RegistrarPromocion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modificar-promocion/:id_promocion"
              element={
                <ProtectedRoute>
                  <ModificarPromocion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modificar-evento/:id_evento"
              element={
                <ProtectedRoute>
                  <ModificarEventoCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ver-perfil-cafeteria"
              element={
                <ProtectedRoute>
                  <VerPerfilCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/evento-cafeteria/:id_evento"
              element={
                <ProtectedRoute>
                  <ConsultarEventoCafeteria />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modificar-cafeteria"
              element={
                <ProtectedRoute>
                  <ModificarCafeteria
                    apiKey={"AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM"}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/inicio-ranking"
              element={
                <ProtectedRoute>
                  <RankingTopCafeterias />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </GoogleMapsProvider>
    </Router>
  );
};

export default App;
