import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export interface IPromocion {
  nombre: string;
  descripcion: string;
  condiciones: string;
  fecha_inicio_vig: string;
  fecha_fin_vig: string;
  mostrarLapiz?: boolean;
  id_promocion?: number;
  activa: boolean;
}

export default function Promocion(props: IPromocion) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Tarjeta */}
      <Card
        sx={{
          position: "relative",
          width: "9.375rem",
          height: "10.125rem",
          background: props.activa ? "#003147" : "#D2C9C0",
          borderRadius: "1.5625rem",
          overflow: "hidden",
          marginBottom: "0.2rem",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          cursor: "pointer", // Añade un indicador visual de clic
        }}
        onClick={handleOpen} // Abre el modal al hacer clic
      >
        {props.mostrarLapiz && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation(); // Evita que se dispare el evento `onClick` del Card
              navigate(`/modificar-promocion/${props.id_promocion}`);
            }}
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              backgroundColor: "#F5F5F5",
              zIndex: 2,
              width: "1.5rem",
              height: "1.5rem",
              borderRadius: "50%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src="/assets/homeCafeteria/lapizMarronCafeteriaPromociones.svg"
              alt="Editar promoción"
              style={{ width: "1rem", height: "1rem" }}
            />
          </IconButton>
        )}
        <CardContent
          sx={{
            overflowY: "auto",
            height: "100%",
            padding: "0.75rem",
            overflowX: "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Higuen",
              fontWeight: "400",
              fontStyle: "normal",
              color: props.activa ? "#FFF" : "#000",
            }}
          >
            {props.nombre}
          </Typography>
          <Typography
            sx={{
              color: props.activa ? "#DAB587" : "#44291E",
              fontFamily: "Sora",
              fontSize: "0.5625rem",
              fontWeight: "400",
              my: "0.19rem",
            }}
          >
            {props.descripcion}
          </Typography>
          <Typography
            sx={{
              color: props.activa ? "#FFF" : "#000",
              fontFamily: "Sora",
              fontSize: "0.5625rem",
              fontWeight: "400",
            }}
          >
            {props.condiciones}
          </Typography>
        </CardContent>
      </Card>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: props.activa ? "#003147" : "#D2C9C0",
            borderRadius: "1.5625rem",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "visible",
              fontSize: "1.5rem",
              fontFamily: "Higuen",
              fontWeight: "400",
              fontStyle: "normal",
              color: props.activa ? "#FFF" : "#000",
              mb: 2,
            }}
          >
            {props.nombre}
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "visible",
              fontSize: "1rem",
              fontFamily: "Sora",
              color: props.activa ? "#DAB587" : "#44291E",
              mb: 2,
            }}
          >
            {props.descripcion}
          </Typography>
          <Typography
            sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflow: "visible",
              fontSize: "1rem",
              fontFamily: "Sora",
              color: props.activa ? "#FFF" : "#000",
            }}
          >
            {props.condiciones}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
