import React, { useState } from "react";
import "../../styles/Buscador.css"; // Importar los estilos CSS
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const Buscador: React.FC<{ label: string; onEnter: Function }> = ({
  label,
  onEnter,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onEnter(searchQuery);
    }
  };

  return (
    <div className="buscador-back">
      <SearchIcon sx={{ color: "#F5F5F5", my: 0.5 }} />
      <TextField
        id="input-with-sx"
        label={label}
        variant="standard"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown} // Captura el evento "Enter"
        inputProps={{ maxLength: 30 }} // Limitar a 50 caracteres
        sx={{
          width: "inherit",
          mb: 2,
          "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
            {
              borderBottomColor: "white",
            },
          "& .MuiInputLabel-root": {
            color: "#fff",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "white",
          },
          input: {
            color: "white",
          },
          "& .MuiInputBase-input::placeholder": {
            color: "white",
            opacity: 0.5,
          },
        }}
      />
    </div>
  );
};

export default Buscador;
