import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cabecera from "../../components/home/Cabecera";
import SwipeToSlideImg from "../../components/home/SliderImagenes";
import { Avatar, AvatarGroup, Box, Button, Skeleton, Typography } from "@mui/material";
import "../../styles/consultarCafeteria.css";
import DireccionCafeteria from "../../components/cafeteria/DireccionCafeteria";
import { getEvento, getParticipantes, suscribirAEvento } from "../../App/Services/rutasEventosServices";
import { useAuth } from "../../context/AuthContext";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Grid from '@mui/material/Grid2';
import NavCafeteria from "../../components/home/NavCafeteria";


export default function ConsultarEventoCafeteria() {
  const { id_evento } = useParams();
  const [evento, setEvento] = useState<any>();
  const [suscripto, setSuscripto] = useState<boolean>();
  const [participantes, setParticipantes] = useState<any[]>([]);
  const usuario = useAuth();
  const navigate = useNavigate();

  const fetchEventoData = async () => {
    if (id_evento && usuario.user) {
      try {
        const id_evento_number = parseInt(id_evento, 10);
        const datosEvento = await getEvento(id_evento_number);
        setEvento(datosEvento);
      } catch (error) {
        console.error("Error al consultar evento:", error);
      }
    }
  };

  const fetchParticipantes = async () => {
    if (id_evento) {
      try {
        const id_evento_number = parseInt(id_evento, 10);
        const suscripiones = await getParticipantes(id_evento_number);
        setParticipantes(suscripiones.participantes);
      } catch (error) {
        console.log("Error al obtener participantes:", error);
        setParticipantes([]); // En caso de error, asegúrate de que se maneje como un array vacío
      }
    }
  };

  useEffect(() => {
    fetchEventoData();
    fetchParticipantes();
  }, [id_evento, usuario.user]);

  useEffect(() => {
    if (participantes.length !== 0) {
      perteneceAEvento();
    }
  }, [participantes]);

  const handleClick = () => {
    if (evento?.id_cafeteria) {
      navigate(`/cafeteria/${evento.id_cafeteria}`);
    }
  };

  const handleSuscribir = () => {
    if (evento?.id && usuario?.user?.uid) {
      suscribirAEvento(usuario.user.uid, evento.id)
        .then((res: any) => {
          fetchParticipantes();
        })
        .catch((error) => console.log(error));
    }
  };

  const perteneceAEvento = () => {
    if (Array.isArray(participantes) && usuario?.user?.uid) {
      const isParticipant = participantes.some(
        (participante: { id: string }) => participante.id === usuario.user!.uid
      );
      setSuscripto(isParticipant);
    } else {
      console.error("Participantes no es un array válido o el usuario no está definido");
      setSuscripto(false);
    }
  };  

  function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <div className="container">
      <Cabecera es_home={false} />
      <div className="content" style={{ flex: 1, overflowX: "hidden" }}>
        {/* Carrusel de imágenes */}
        {evento?.imagenes ? (
          <div className="contenedor-carrusel-consultar-caf">
            <SwipeToSlideImg imagenes={evento.imagenes} />
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="22.875rem"
            height="11.813rem"
            sx={{ borderRadius: "8px", margin: "0 auto" }}
          />
        )}

        <Box style={{margin: '0 2rem', overflowX: "hidden"}}>
          {/* Información de la cafetería */}
          {evento ? (
            <>
              <Typography
              variant="h4"
              className="nombre-cafeteria"
              sx={{ fontFamily: "Higuen", mb: '1rem'}}
              >
                {evento.nombre}
              </Typography>

              <DireccionCafeteria direccion={evento.direccion} />
              <Grid
                container
                alignItems="center"
                justifyContent={"flex-start"} // Controlamos la alineación según la prop
              >
                <Grid>
                <AccessTimeOutlinedIcon
                  sx={{ color: "#44291E", width: "0.75rem", height: "0.75rem" }}
                />
                </Grid>
                <Grid>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "0.375rem",
                      fontFamily: "Sora",
                      fontSize: "0.75rem",
                      fontStyle: "normal",
                      color: "#44291E",
                      textAlign: "left",
                    }}
                  >
                    {evento.fecha} - {evento.hora}hs
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent={"flex-start"} // Controlamos la alineación según la prop
              >
                <Grid>
                  <img
                    src="/assets/iconocoffe.svg"
                    alt="Pin Coffe"
                    style={{
                      color: "#44291E",
                      width: "0.697rem",
                      height: "0.941rem",
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography
                    variant="body1"
                    sx={{
                      paddingLeft: "0.375rem",
                      fontFamily: "Sora",
                      fontSize: "0.75rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      color: "#44291E",
                      textAlign: "left",
                    }}
                  >
                    {evento.nombre_cafeteria}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Skeleton
              variant="text"
              width="auto"
              height="5.813rem"
              sx={{ borderRadius: "5px" }}
            />
          )}

          {/* Descripción */}
          {evento?.descripcion ? (
            <Box
              sx={{
                typography: "body1",
                my: "1rem",
                fontFamily: "Sora",
                overflowX: "hidden",
                width: "100%"
              }}
            >
              {evento.descripcion}
            </Box>
          ) : (
            <Skeleton
              variant="text"
              width="18.25rem"
              height="6.25rem"
              sx={{ borderRadius: "5px"}}
            />
          )}
          {participantes? (
            <Grid container justifyContent={"flex-start"} columns={1} spacing={0.5}>
              <Grid size={1}>
              <Typography
                sx={{
                  fontFamily: "Higuen",
                  color: "#44291E",
                  fontSize: "1.25rem",
                  fontWeight: "400",
                  marginBottom: "0.625rem",
                }}
              >
                Participantes
              </Typography>
              </Grid>
              <Grid>
              <AvatarGroup max={4}>
                {participantes.map((participante) => (
                <Avatar {...stringAvatar((participante.nombre + ' ' + participante.apellido))} />
              ))}
              </AvatarGroup>
              </Grid>
            </Grid>
          ) : (<></>)}
        </Box>
      </div>

      <NavCafeteria select={0} />
    </div>
  );
}
