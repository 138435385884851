import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { avatarPorId } from "../../common/const";
import { postInteraccion } from "../../../App/Services/foroService";
import { useAuth } from "../../../context/AuthContext";

interface IPosteo {
  id_foro: number;
  id: number;
  avatar: number;
  nombre: string;
  apellido: string;
  fecha: string;
  titulo: string;
  descripcion: string;
  cantidad_likes: number;
  cantidad_dislikes: number;
  cantidad_comentarios: number;
}

export function calcularDiferenciaDias(fechaAlta: string): string {
  let respuesta: string = "";
  const fechaAltaDate = new Date(fechaAlta); // Convierte la fecha de alta en un objeto Date
  const fechaActual = new Date(); // Fecha actual

  const diferenciaTiempo = fechaActual.getTime() - fechaAltaDate.getTime(); // Diferencia en milisegundos
  const diferenciaDias = Math.floor(diferenciaTiempo / (1000 * 3600 * 24)); // Convertir milisegundos a días

  if (diferenciaDias != 0) {
    respuesta = ` Hace ${diferenciaDias} días`;
  } else {
    respuesta = ` Hoy`;
  }

  return respuesta;
}

export default function Posteo({
  id_foro,
  id,
  avatar,
  nombre,
  apellido,
  fecha,
  titulo,
  descripcion,
  cantidad_likes,
  cantidad_dislikes,
  cantidad_comentarios,
}: IPosteo) {
  const navigate = useNavigate();
  const usuario = useAuth();

  function handleUtil(id_tipo_interaccion: number): void {
    const uid = usuario.user?.uid; // Verificar si uid existe

    if (uid) {
      // Si uid está definido, se llama a la función
      postInteraccion(uid, id, id_foro, id_tipo_interaccion, null, null)
        .then((res) => {})
        .catch((error) => console.log("error al marcar útil"));
    } else {
      // Manejar el caso en el que uid es undefined
      console.error("UID no disponible");
    }
  }

  function handleComentarios(): void {
    navigate(`/posteo/${id_foro}/${id}`);
  }

  function limitarPalabras(descripcion: string, limite: number): string {
    const palabras = descripcion.split(" "); // Dividir el texto en palabras
    if (palabras.length > limite) {
      return palabras.slice(0, limite).join(" ") + "..."; // Mostrar solo las primeras 'limite' palabras y añadir "..."
    }
    return descripcion; // Si tiene menos de 'limite' palabras, mostrar el texto completo
  }

  return (
    <Grid
      container
      sx={{
        padding: "1rem",
        height: "auto",
        borderRadius: "1.0625rem",
        background: "#F5F5F5",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        marginTop: "1rem",
        mx: "2rem",
      }}
      spacing={1}
      onClick={() => handleComentarios()}
    >
      <Grid>
        <img
          src={avatarPorId.get(avatar)}
          alt={nombre}
          width={19}
          height={19}
        />
      </Grid>
      <Grid container>
        <Grid>
          <Typography
            sx={{
              fontFamily: "Sora",
              fontSize: "0.8rem",
              fontWeight: "600",
            }}
          >
            {nombre} {apellido}
          </Typography>

          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.65)",
              fontFamily: "Sora",
              fontSize: "0.6rem",
              fontWeight: "600",
            }}
          >
            {" "}
            {calcularDiferenciaDias(fecha)}
          </Typography>
        </Grid>
      </Grid>
      <Grid size={12}>
        <Typography
          sx={{
            fontFamily: "Sora",
            fontSize: "0.8rem",
            fontWeight: "600",
          }}
        >
          {titulo}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Typography
          sx={{
            fontFamily: "Sora",
            fontSize: "0.7rem",
            fontWeight: "400",
          }}
        >
          {limitarPalabras(descripcion, 50)}
        </Typography>
      </Grid>
      <Grid>
        <Button
          sx={{
            height: "0.9375rem",
            borderRadius: "0.8125rem",
            background: "#D9D9D9",
            color: "#000",
            fontFamily: "Sora",
            fontSize: "0.6rem",
            textTransform: "none",
          }}
          startIcon={
            <ThumbUpOffAltOutlinedIcon
              sx={{ fontSize: "0.62813rem !important" }}
            />
          }
          onClick={() => handleUtil(1)}
        >
          {cantidad_likes}
        </Button>
      </Grid>
      <Grid>
        <Button
          sx={{
            height: "0.9375rem",
            borderRadius: "0.8125rem",
            background: "#D9D9D9",
            color: "#000",
            fontFamily: "Sora",
            fontSize: "0.6rem",
            textTransform: "none",
          }}
          startIcon={
            <ThumbDownAltOutlinedIcon
              sx={{ fontSize: "0.62813rem !important" }}
            />
          }
          onClick={() => handleUtil(2)}
        >
          {cantidad_dislikes}
        </Button>
      </Grid>
      <Grid>
        <Button
          sx={{
            height: "0.9375rem",
            borderRadius: "0.8125rem",
            background: "#D9D9D9",
            color: "#000",
            fontFamily: "Sora",
            fontSize: "0.6rem",
            textTransform: "none",
          }}
          startIcon={
            <InsertCommentIcon sx={{ fontSize: "0.62813rem !important" }} />
          }
          onClick={() => handleComentarios()}
        >
          {cantidad_comentarios}
        </Button>
      </Grid>
    </Grid>
  );
}
