import axios from "axios";
import { BASE_URL } from "./config";

export const getCafeteriasPopulares = async (): Promise<any> => {
    const response = await axios.get(
      `${BASE_URL}/cafeterias/populares`
    )
    .then((res) => {return res.data})
    .catch ((error) => {
      throw Error(error);
    });
    return response
};

export const getCafeteriasRecomendadas = async (uid_meriendista:string): Promise<any> => {
  const response = await axios.get(
    `${BASE_URL}/cafeterias/recomendador/${uid_meriendista}`
  )
  .then((res) => {return res.data})
  .catch ((error) => {
    throw Error(error);
  });
  return response
};

export const getFavoritos = async (uid_meriendista:string): Promise<any> => {
  const response = await axios.get(
    `${BASE_URL}/favoritos/${uid_meriendista}`
  )
  .then((res) => {return res.data})
  .catch ((error) => {
    throw Error(error);
  });
  return response
};