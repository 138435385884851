import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import RatingSize from "./Estrellas";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

export interface Cafeteria {
  id_cafeteria: number;
  nombre_cafeteria: string;
  imagen: string;
  estado: string;
  cantidad_estrellas: number;
  direccion: string;
  descripcion?: string;
  horario?: string;
  tags: any[];
  index?: number; // Nuevo índice para la posición
  mostrarMedalla?: boolean; // Nuevo prop opcional para mostrar medallas
}

const ComponenteCafeteriaParaVos: React.FC<Cafeteria> = (cafeteria) => {
  const navigate = useNavigate();

  const handleConsultarCafeteria = (id_cafeteria: number) => {
    navigate(`/cafeteria/${id_cafeteria}`);
  };

  // Ruta base de las medallas 
  const medallas = [
    "/assets/RankingIcons/Top1Icon.svg",
    "/assets/RankingIcons/Top2Icon.svg",
    "/assets/RankingIcons/Top3Icon.svg",
    "/assets/RankingIcons/Top4Icon.svg",
    "/assets/RankingIcons/Top5Icon.svg",
  ];

  const medalla = medallas[cafeteria.index ?? 0]; // Selecciona la medalla según el índice

  // Limitamos la cantidad de tags a 3
  const displayedTags = cafeteria.tags.slice(0, 3);

  const hasMoreTags = cafeteria.tags.length > 3; // Verificamos si tiene más de 3 tags

  return (
    <Card
      key={cafeteria.id_cafeteria}
      sx={{
        position: "relative", // Habilita la posición relativa para ubicar elementos dentro
        height: "9.125rem",
        backgroundColor: "#003147",
        borderRadius: "1.5625rem",
        padding: "1rem 0.8rem",
        mb: "1rem",
        overflow: "visible", // Permitir que la medalla se muestre fuera de los límites
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", //Sombreado para la card
      }}
    >
      {/* Renderiza la medalla solo si mostrarMedalla es true */}
      {cafeteria.mostrarMedalla && (
        <Box
          sx={{
            position: "absolute",
            top: "-1.25rem", // Ajusta la posición para sobresalir más
            left: "-0.5rem", // Ajusta según el diseño
            zIndex: 1, // Asegúrate de que esté encima del contenido de la tarjeta
          }}
        >
          <img
            src={medalla}
            alt={`Medalla posición ${cafeteria.index}`}
            style={{
              width: "3.75rem", // Tamaño de la medalla
              height: "3.75rem",
            }}
          />
        </Box>
      )}
      <CardActionArea
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
        onClick={() => {
          handleConsultarCafeteria(cafeteria.id_cafeteria);
        }}
      >
        <Grid container justifyContent={"center"} spacing={1}>
          <Grid item xs={6}>
            <CardMedia
              sx={{ height: "7.125rem", borderRadius: "1.125rem" }}
              image={`data:image/bmp;base64,${cafeteria.imagen}`}
              title={cafeteria.nombre_cafeteria}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems={"center"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Higuen",
                    color: "#FFF",
                    fontSize: "1.125rem",
                  }}
                >
                  {cafeteria.nombre_cafeteria}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        color: "#DAB587",
                        fontSize: "0.5625rem",
                        fontWeight: "bold",
                      }}
                    >
                      {cafeteria.estado}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <RatingSize
                      value={Math.round(cafeteria.cantidad_estrellas)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FmdGoodOutlinedIcon sx={{ color: "#FFFFFF" }} />
                  <Typography
                    sx={{
                      fontFamily: "Sora",
                      color: "#FFF",
                      fontSize: "0.5625rem",
                      ml: 1,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cafeteria.direccion}
                  </Typography>
                </Box>
              </Grid>
              {/* Mostramos solo los primeros 3 tags */}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {displayedTags.map((tag: any, index: number) => (
                    <Grid item xs={6} key={index}>
                      <div className="boton-tag-caf">{tag.nombre}</div>
                    </Grid>
                  ))}
                  {/* Si hay más de 3 tags, mostramos los puntos suspensivos */}
                  {hasMoreTags && (
                    <Grid item xs={6}>
                      <div
                        className="boton-tag-caf"
                        style={{ fontStyle: "italic" }}
                      >
                        Ver más{" "}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default ComponenteCafeteriaParaVos;
