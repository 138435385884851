import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import "../../styles/consultarCafeteria.css";
import DireccionCafeteria from "./DireccionCafeteria";
import HorariosCafeteria from "./HorariosCafeteria";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';

export const getCurrentDay = () => {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const today = new Date();
  return days[today.getDay()];
};

const InfoCafeteria: React.FC<{ cafeteria: any, habilitado_reseñas:boolean }> = ({ cafeteria, habilitado_reseñas }) => {
  const [horariosHoy, setHorariosHoy] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickEstrellas = () => {
    navigate(`/reseñas/${cafeteria.id_cafeteria}`);
  };

  useEffect(() => {
    setHorariosHoy(getTodaySchedule());
  }, []);

  const getTodaySchedule = () => {
    const diaActual = getCurrentDay();
    const horarioHoy = cafeteria.horarios.find(
      (horario: any) => horario.dia === diaActual
    );
    if (horarioHoy){
      return horarioHoy;
    }
    return { hora_apertura: "", hora_cierre: "" }
  };

  return (
    <div className="info-cafeteria">
      <Typography
        variant="h4"
        className="nombre-cafeteria"
        sx={{ fontFamily: "Higuen" }}
      >
        {cafeteria.nombre}
      </Typography>
      <Grid
        container
        flexDirection={"row"}
        sx={{ mt: "0.44rem" }}
      >
        {/* DIRECCIÓN DE CAFETERIA */}
        <Grid item xs={9}>
          <Grid container alignItems="center">
            <DireccionCafeteria direccion={cafeteria.direccion} />
          </Grid>
        </Grid>

        {/* ESTRELLAS DE RESEÑA */}
        <Grid item xs={3} container justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
            <Button
              sx={{
                pl: "0.3rem",
                pr: "0.2rem",
                height: "1.5rem",
                width: "4.25rem",
                background: "rgba(255, 255, 255, 0.57)",
                borderRadius: "0.5rem",
                color: "#003147",
                fontFamily: "Sora",
                fontSize: "0.75rem",
                fontWeight: "600",
              }}
              disabled={!habilitado_reseñas}
              onClick={handleClickEstrellas}
              endIcon={<><img
                src="/assets/consultarCafeteria/estrellaDeReseñasIcon.svg"
                alt="Estrella"
              /> <NavigateNextIcon/></>}
            >
              {cafeteria.puntaje}
            </Button>
          </Box>
        </Grid>
        {/* HORARIOS DE CAFETERIA */}
        <Grid item xs={9}>
          <HorariosCafeteria
            estado={cafeteria.estado}
            horariosHoy={horariosHoy}
            horarios={cafeteria.horarios}
            open={open}
            handleClick={handleClick}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoCafeteria;
// Aca irian todos los componentes de información como la descripcion, horarios, estrellas y ubicacion
