import React, { useEffect, useState } from "react";
import "../../styles/Nav.css"; // Importar los estilos CSS
import { IconButton } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useNavigate } from "react-router-dom";

const Nav: React.FC<{ select: number }> = (parametro) => {
  const [select, setSelect] = useState<number>(parametro.select);
  const navigate = useNavigate();

  const handleSelect = (idSelect: number) => {
    setSelect(idSelect);
  };

  useEffect(() => {
    switch (select) {
      case 1:
        navigate("/mapa");
        break;
      case 2:
        navigate("/rutas-eventos");
        break;
      case 3:
        navigate("/inicio");
        break;
      case 4:
        navigate("/foro");
        break;
      case 5:
        navigate("/ver-perfil");
        break;
    }
  }, [select]);
  return (
    <footer className="nav">
      <div className="div-nav">
        <IconButton
          aria-label="Mapa"
          onClick={() => handleSelect(1)}
          sx={{
            backgroundColor: select === 1 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <FmdGoodOutlinedIcon
            sx={{
              color: select === 1 ? "#F0ECE3" : "#DAB587",
              width: "2rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Cafes"
          onClick={() => handleSelect(2)}
          sx={{
            backgroundColor: select === 2 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/rutas.svg"
            alt="Coffee Cup"
            style={{
              color: select === 2 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "2.1875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Home"
          onClick={() => handleSelect(3)}
          sx={{
            backgroundColor: select === 3 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/homeIconCremita.svg"
            alt="Home"
            style={{
              width: "2.1875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="Chat"
          onClick={() => handleSelect(4)}
          sx={{
            backgroundColor: select === 4 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/chatIcon.svg"
            alt="Chat"
            style={{
              color: select === 4 ? "#F0ECE3" : "#DAB587",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="User"
          onClick={() => handleSelect(5)}
          sx={{
            backgroundColor: select === 5 ? "#44291E" : "transparent",
            borderRadius: "1.94rem 1.94rem 0 0",
            padding: "0.5rem",
            ":hover": {
              backgroundColor: "#44291E",
              borderRadius: "1.94rem 1.94rem 0 0",
            },
          }}
        >
          <img
            src="/assets/userIcon.svg"
            alt="User"
            style={{
              color: select === 5 ? "#F0ECE3" : "#DAB587",
              width: "1.64063rem",
              height: "1.875rem",
            }}
          />
        </IconButton>
      </div>
    </footer>
  );
};

export default Nav;
