import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  Libraries,
  DirectionsRenderer,
} from "@react-google-maps/api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../styles/MapWithGeolocation.css";
import ComponenteCafeteriaParaVos from "../../components/pure/CafeteriaParaVos";
import { getRutaById } from "../../App/Services/rutasEventosServices";
import {
  Button,
  CssBaseline,
  IconButton,
  styled,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import HorariosCafeteria from "../../components/cafeteria/HorariosCafeteria";
import DireccionCafeteria from "../../components/cafeteria/DireccionCafeteria";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "../../components/home/Nav";
import Encabezado from "../../components/Mapa/EncabezadoMapa";
import { useAuth } from "../../context/AuthContext";
import {
  getCafeteriasCiudad,
  getCiudadDeMeriendista,
  getNombreCiudad,
} from "../../App/Services/mapaService";
import { getCafeteriaReducida } from "../../App/Services/cafeteriaService";
import ReseñaRuta from "../../components/eventos/ReseñaRuta"; // Importar el nuevo componente
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useGoogleMaps } from "../../components/Mapa/GoogleMapsProvider";

interface RutaDelCafePageProps {
  apiKey: string;
}

interface IRuta {
  id_ruta: number;
  nombre_ruta: string;
  cantidad_cafeterias: number;
  puntaje: number;
  descripcion_ruta: string;
  paradas: IParada[];
}

interface IParada {
  id_parada: number;
  orden: number;
  id_cafeteria: number;
  descripcion_parada: string;
  cafeteria: ICafeteria;
}

interface ICafeteria {
  id_cafeteria: number;
  nombre: string;
  latitud: string;
  longitud: string;
  direccion: string;
  estado: string;
  horarios: IHorario[];
  imagen: string;
  puntaje: number;
  tags: string[];
}

interface IHorario {
  dia: string;
  hora_cierre: string;
  hora_apertura: string;
}

const libraries: Libraries = ["drawing", "marker"];

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.applyStyles("dark", {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled("div")(({ theme }) => ({
  width: "8rem", // 128px
  height: "0.318rem",
  backgroundColor: "#00000054",
  borderRadius: "0.875rem",
  position: "absolute",
  top: 8,
  left: "calc(50% - 4rem)", // Restar la mitad del ancho (4rem = 64px)
  ...theme.applyStyles("dark", {
    backgroundColor: grey[900],
  }),
}));

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: grey[100],
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.background.default,
  }),
}));

const RutaDelCafePage: React.FC<RutaDelCafePageProps> = ({ apiKey }) => {
  const { id } = useParams<{ id: string }>();
  const usuario = useAuth();
  const [userPosition, setUserPosition] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [selectedCafeteria, setSelectedCafeteria] = useState<any | null>(null);
  const [ruta, setRuta] = useState<IRuta | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(
    undefined
  );
  const [zoom, setZoom] = useState(14);
  const [cafeteriasRuta, setCafeteriasRuta] = useState<ICafeteria[]>([]);
  const [cafeterias, setCafeterias] = useState<any[]>([]);
  const [, setAnimationComplete] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [userMarker, setUserMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);
  const [mapReady, setMapReady] = useState(false);
  const [noCafeteriasFound, setNoCafeteriasFound] = useState(false); // Nuevo estado para el mensaje
  const [loadingCafeteria, setLoadingCafeteria] = useState(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [open, setOpen] = useState(true);
  const drawerBleeding = 56;
  const [horariosOpen, setHorariosOpen] = useState(false);
  const [directionsResponse, setDirectionsResponse] =
    useState<google.maps.DirectionsResult | null>(null);
  const navigate = useNavigate();

  const { isLoaded } = useGoogleMaps();

  const handleClick = () => {
    setHorariosOpen(!horariosOpen);
  };

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setZoom(16);
          setUserPosition(pos);
          fetchCafeterias(pos.lat, pos.lng);
        },
        async (error) => {
          console.error("Error al obtener la geolocalización: ", error);
          await fetchCafeterias(); // Obtener ciudad y cafeterías en caso de error con la geolocalización
        },
        {
          enableHighAccuracy: true,
          timeout: 1000000,
          maximumAge: 0,
        }
      );
    } else {
      fetchCafeterias(); // Obtener ciudad y cafeterías si la geolocalización no está disponible
    }
  }, [usuario, isLoaded]);

  const fetchCafeterias = useCallback(
    async (poslat?: number, poslng?: number): Promise<any> => {
      try {
        let nombreCiudad: string | null = null;
        let ciudadLatLng: { lat: number; lng: number } | null = null;

        // Verificar si se proporcionaron las coordenadas
        if (poslat !== undefined && poslng !== undefined) {
          // Obtener el nombre de la ciudad a partir de las coordenadas
          nombreCiudad = await getNombreCiudad(poslat, poslng, apiKey);
        } else if (usuario && usuario.user && usuario.user.uid) {
          // Obtener la ciudad a partir del usuario
          const ciudad = await getCiudadDeMeriendista(usuario.user.uid);
          nombreCiudad = ciudad.nombre;
          ciudadLatLng = {
            lat: parseFloat(ciudad.latitud),
            lng: parseFloat(ciudad.longitud),
          };
          setCenter(ciudadLatLng); // Actualizar el centro del mapa
        }

        if (nombreCiudad) {
          // Obtener las cafeterías de la ciudad detectada
          const cafes = await getCafeteriasCiudad(nombreCiudad);
          setCafeterias(cafes);
          setNoCafeteriasFound(cafes.length === 0); // Verificar si no se encontraron cafeterías
        }
      } catch (error) {
        console.error("Error al obtener las cafeterías: ", error);
      }
    },
    [apiKey, usuario]
  );

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setZoom(16);
          setUserPosition(pos);

          if (userMarker) {
            userMarker.map = null;
          }

          const newUserMarker = renderAdvancedMarker(
            mapInstance!,
            0,
            pos,
            "/assets/consultarCafeteria/Logo Cofflick (1).svg"
          );
          setUserMarker(newUserMarker);
        },
        (error) => {
          console.error("Error al obtener la geolocalización: ", error);
          if (userMarker) {
            userMarker.map = null;
            setUserMarker(null);
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 1000000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocalización no está disponible en este navegador.");
      if (userMarker) {
        userMarker.map = null;
        setUserMarker(null);
      }
    }
  };

  const handleMarkerClick = useCallback(async (id_cafeteria: number) => {
    if (id_cafeteria === 0) {
      return;
    }

    setLoadingCafeteria(true);
    setAnimationStarted(true);
    try {
      const cafeteriaData = await getCafeteriaReducida(id_cafeteria);
      setSelectedCafeteria(cafeteriaData);

      setTimeout(() => {
        setAnimationComplete(true);
        setLoadingCafeteria(false);
      }, 400);
    } catch (error) {
      console.error("Error al obtener los datos de la cafetería:", error);
      setLoadingCafeteria(false);
    }
  }, []);

  useEffect(() => {
    const fetchRuta = async () => {
      try {
        const data = await getRutaById(Number(id));
        setRuta(data);
        // Mapeo de paradas, agregando el id_cafeteria a cada cafetería
        const cafeteriasData = data.paradas
          .sort(
            (a: { orden: number }, b: { orden: number }) => a.orden - b.orden
          )
          .map((parada: { id_cafeteria: number; cafeteria: any }) => ({
            ...parada.cafeteria,
            id_cafeteria: Number(parada.id_cafeteria), // Agregar el id_cafeteria
          }));

        setCafeteriasRuta(cafeteriasData);

        if (cafeteriasData.length > 0) {
          const firstCafeteria = cafeteriasData[0];
          setCenter({
            lat: parseFloat(firstCafeteria.latitud),
            lng: parseFloat(firstCafeteria.longitud),
          });
        }
      } catch (error) {
        console.error("Error fetching route details:", error);
      }
    };

    if (isLoaded) {
      fetchRuta();
    }
  }, [id, isLoaded]);

  useEffect(() => {
    if (cafeteriasRuta.length > 1) {
      const waypoints = cafeteriasRuta.slice(1, -1).map((cafeteria) => ({
        location: {
          lat: parseFloat(cafeteria.latitud),
          lng: parseFloat(cafeteria.longitud),
        },
        stopover: true,
      }));

      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: {
            lat: parseFloat(cafeteriasRuta[0].latitud),
            lng: parseFloat(cafeteriasRuta[0].longitud),
          },
          destination: {
            lat: parseFloat(cafeteriasRuta[cafeteriasRuta.length - 1].latitud),
            lng: parseFloat(cafeteriasRuta[cafeteriasRuta.length - 1].longitud),
          },
          waypoints: waypoints,
          travelMode: google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setDirectionsResponse(result);
          } else {
            console.error(`Error fetching directions: ${result}`);
          }
        }
      );
    }
  }, [cafeteriasRuta]);

  const onLoad = (map: google.maps.Map) => {
    setMapInstance(map);
    setMapReady(true);

    if (userPosition) {
      const userMarker = renderAdvancedMarker(
        map,
        0,
        userPosition,
        "/assets/consultarCafeteria/Logo Cofflick (1).svg"
      );
      setUserMarker(userMarker);
    }
  };

  const renderAdvancedMarker = useCallback(
    (
      map: google.maps.Map,
      id_cafeteria: number,
      position: google.maps.LatLngLiteral,
      iconUrl: string
    ) => {
      if (!google?.maps?.marker?.AdvancedMarkerElement) {
        console.error("AdvancedMarkerElement no está disponible.");
        return null;
      }

      const createCustomMarkerContent = (imageUrl: string) => {
        const content = document.createElement("div");
        content.className = "custom-marker";
        content.style.backgroundImage = `url('${imageUrl}')`;
        content.style.width = "40px"; // Tamaño del ícono
        content.style.height = "40px"; // Tamaño del ícono
        content.style.backgroundSize = "cover";
        return content;
      };

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        map,
        title: `Cafetería ${id_cafeteria}`,
        content: createCustomMarkerContent(iconUrl),
      });

      marker.addListener("click", () => {
        handleMarkerClick(id_cafeteria);
      });

      return marker;
    },
    [handleMarkerClick]
  );

  useEffect(() => {
    if (mapInstance && cafeteriasRuta.length > 0) {
      cafeteriasRuta.forEach((cafeteria) => {
        renderAdvancedMarker(
          mapInstance,
          cafeteria.id_cafeteria,
          { lat: Number(cafeteria.latitud), lng: Number(cafeteria.longitud) },
          "/assets/consultarCafeteria/Logo_Cofflick.svg"
        );
      });
    }
  }, [mapInstance, cafeteriasRuta, renderAdvancedMarker]);

  if (!isLoaded || !ruta) {
    return <div>Cargando...</div>;
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleAtras = () => {
    navigate("/rutas-eventos");
  };

  return (
    <div className="map-container">
      <IconButton
        sx={{
          width: "2.1875rem",
          height: "2.1875rem",
          border: " none",
          borderRadius: "0.5625rem",
          zIndex: "3",
          top: "2rem" /* Alinea en la parte superior */,
          right: "0" /* Fija a la derecha */,
          marginRight: "2rem" /* Espaciado desde el borde derecho */,
          color: "#000",
          backgroundColor: "#FFF",
          position: "fixed",
        }}
        onClick={handleAtras}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <Encabezado />
      {center && (
        <GoogleMap
          id="map"
          mapContainerStyle={{ height: "100%", width: "100%" }}
          center={center}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            mapId: "80f094560809c165",
            minZoom: 10,
            maxZoom: 18,
          }}
          onLoad={onLoad}
        >
          {directionsResponse && (
            <DirectionsRenderer
              directions={directionsResponse}
              options={{
                polylineOptions: {
                  strokeColor: "#44291E",
                  strokeOpacity: 0.7,
                  strokeWeight: 5,
                },
                suppressMarkers: true, // Desactiva los pins predeterminados
              }}
            />
          )}
        </GoogleMap>
      )}

      {mapInstance && (
        <>
          <button
            onClick={() => updateLocation()}
            className={`location-button ${animationStarted ? "move-up" : ""}`}
          >
            <GpsFixedIcon />
          </button>

          <div className="cafeteria-details slide-up">
            {loadingCafeteria ? (
              <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
                <Skeleton
                  style={{
                    height: "9.125rem",
                    width: "90%",
                    maxWidth: "600px",
                    minWidth: "200px",
                    margin: "0 auto",
                    borderRadius: "1.5625rem",
                    padding: "1rem 0.8rem",
                    marginBottom: "1rem",
                  }}
                />
              </SkeletonTheme>
            ) : (
              selectedCafeteria && (
                <>
                  <ComponenteCafeteriaParaVos
                    id_cafeteria={selectedCafeteria.id_cafeteria}
                    nombre_cafeteria={selectedCafeteria.nombre}
                    imagen={selectedCafeteria.imagen}
                    estado={selectedCafeteria.estado}
                    cantidad_estrellas={selectedCafeteria.calificacion}
                    direccion={selectedCafeteria.direccion}
                    tags={selectedCafeteria.tags}
                  />
                </>
              )
            )}
          </div>
          <div className="cafeteria-details slide-up">
            <Root>
              <CssBaseline />
              <Global
                styles={{
                  ".MuiDrawer-root > .MuiPaper-root": {
                    height: `calc(50% - ${drawerBleeding}px)`,
                    overflow: "visible",
                  },
                }}
              />
              <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <StyledBox
                  sx={{
                    position: "absolute",
                    zIndex: 10,
                    top: -drawerBleeding,
                    borderTopLeftRadius: "2.313rem",
                    borderTopRightRadius: "2.313rem",
                    visibility: "visible",
                    right: 0,
                    left: 0,
                    background: "#F0ECE3",
                    boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  onClick={toggleDrawer(true)}
                >
                  <Puller />
                  <Typography
                    sx={{
                      p: 2,
                      color: "#003147",
                      fontFamily: "Higuen",
                      fontSize: "1.5625rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      textAlign: "center", // Para centrar el texto dentro del Typography
                      wordWrap: "break-word", // Asegura que el texto largo se divida en varias líneas
                      whiteSpace: "normal", // Permite el salto de línea
                    }}
                  >
                    {ruta.nombre_ruta}
                  </Typography>
                </StyledBox>
                <StyledBox
                  sx={{
                    px: 2,
                    pb: 2,
                    height: "100%",
                    overflow: "auto",
                    background:
                      "linear-gradient(180deg, #F0ECE3 0.02%, rgba(210, 201, 192, 0.61) 39.03%, rgba(160, 145, 135, 0.75) 70.46%, #44291E 128.34%)",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      mt: "1rem",
                      color: "#000",
                      fontFamily: "Sora",
                      fontSize: "0.938rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      textAlign: "center",
                      wordWrap: "break-word", // Asegura que el texto largo se divida en varias líneas
                      whiteSpace: "normal", // Permite el salto de línea
                    }}
                  >
                    {ruta.descripcion_ruta}
                  </Typography>

                  {cafeteriasRuta.length !== 0 && (
                    <>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                          },
                        }}
                      >
                        {cafeteriasRuta.map((cafeteria, index) => (
                          <TimelineItem key={index}>
                            <TimelineSeparator>
                              <img
                                src="/assets/consultarCafeteria/Logo_Cofflick.svg"
                                width="30rem"
                                height="30rem"
                                alt="Icono"
                              />
                              {index < cafeterias.length - 1 && (
                                <TimelineConnector
                                  sx={{
                                    backgroundColor: "#44291E",
                                    height: "2.938rem",
                                  }}
                                />
                              )}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "0px", px: "2px" }}>
                              <Typography
                                sx={{
                                  color: "#44291E",
                                  fontFamily: "Higuen",
                                  fontSize: "1.125rem",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                }}
                              >
                                {cafeteria.nombre}
                              </Typography>
                              <HorariosCafeteria
                                estado={cafeteria.estado}
                                horariosHoy={cafeteria.horarios[0]} // Asumiendo que el primer elemento es el horario de hoy
                                horarios={cafeteria.horarios}
                                open={horariosOpen}
                                handleClick={handleClick}
                              />
                              <DireccionCafeteria
                                direccion={cafeteria.direccion}
                                sx={{ color: "#202020", fontWeight: "500" }}
                              />
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </>
                  )}
                  <ReseñaRuta idRuta={ruta.id_ruta} />
                </StyledBox>
              </SwipeableDrawer>
            </Root>
          </div>
          <Nav select={0} />
        </>
      )}
    </div>
  );
};

export default RutaDelCafePage;
