import React, { useEffect, useState } from "react";
import Cabecera from "../../components/home/Cabecera";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../../styles/InicioCafeteria.css";
import Buscador from "../../components/home/Buscador";
import NavCafeteria from "../../components/home/NavCafeteria";
import { Box, Grid, Typography, Fab, IconButton } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
    obtenerHomeReviewsCafeteria,
    getCafeteria,
} from "../../App/Services/cafeteriaService";
import { getAllEventosCafeteria, getAllForosCafeteria } from "../../App/Services/rutasEventosServices";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
import PromocionesCafeteria from "../../components/cafeteria/promociones/PromocionesDelHomeCafeteria";
import ReseñasHomeCafeteria from "../../components/cafeteria/reseñas/ReseñasHomeCafeteria";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import EventoCafeteria from "../../components/eventos/EventoCafeteria";
import { useMediaQuery } from "@mui/material";
import ComponenteCafeteriaParaVos from "../../components/pure/CafeteriaParaVos";
import TarjetaForo from "../../components/foro/TarjetaForo";
import { deleteForo } from "../../App/Services/foroService";
import AddIcon from "@mui/icons-material/Add";
import ModalNuevoForo from "../../components/Modales/Foro/ModalNuevoForo";

const ForoCafeteria: React.FC = () => {
    const usuario = useAuth();
    const [cafeteria, setCafeteria] = useState<any>(null);
    const [foros, setForos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingForos, setLoadingForos] = useState<boolean>(true);
    const navigate = useNavigate();
    const [eliminarActivo, setEliminarActivo] = useState<boolean>(false);
    const [openModalNuevoForo, setOpenModalNuevoForo] = useState(false);
    const toggleEliminarActivo = () => {
        setEliminarActivo((prevState) => !prevState);
    };

    useEffect(() => {
        const fetchDatosCafeteria = async () => {
            if (usuario.user) {
                try {
                    const cafeteriaAsociada = await tieneCafeteriaAsociada(
                        usuario.user.uid
                    );
                    const datosCafeteria = await getCafeteria(
                        cafeteriaAsociada.id_cafeteria
                    );
                    setCafeteria(datosCafeteria);


                    const forosCafeteria = await getAllForosCafeteria(
                        cafeteriaAsociada.id_cafeteria
                    );
                    setForos(forosCafeteria);
                } catch (error) {
                    console.error("Error al obtener datos de la cafetería:", error);
                } finally {
                    setLoading(false);
                    setLoadingForos(false); // Indica que las promociones ya se han cargado
                }
            }
        };
        fetchDatosCafeteria();
    }, [usuario]);

    const fetchForos = async (idCafeteria: number) => {
        setLoadingForos(true);
        try {
            const forosCafeteria = await getAllForosCafeteria(idCafeteria);
            setForos(forosCafeteria);
        } catch (error) {
            console.error("Error al cargar los foros:", error);
        } finally {
            setLoadingForos(false);
        }
    };

    const handleEliminarForo = async (id: number) => {
        try {
            await deleteForo(id); // Llama al servicio de eliminación
            setForos((prevForos) => prevForos.filter((foro) => foro.id_foro !== id)); // Actualiza el estado
        } catch (error) {
            console.error("Error al eliminar el foro:", error);
        }
    };


    return (
        <Box className="inicio-cafeteria">
            <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />
            <Box
                className="promociones-cafeteria-texto"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "1rem 2rem 0 2rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <img
                        src="/assets/homeCafeteria/parlanteForos.svg"
                        alt="Icono de promociones"
                        style={{ width: "1.75rem", height: "1.75rem" }}
                    />
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "Sora",
                            color: "#44291E",
                            fontSize: "0.9375rem",
                            fontWeight: "600",
                            paddingLeft: "0.5rem",
                        }}
                    >
                        Tus foros
                    </Typography>
                </Box>
                <IconButton
                    onClick={toggleEliminarActivo}
                    sx={{
                        backgroundColor: eliminarActivo ? "#E0E0E0" : "#C60F0F",
                        color: "white",
                        borderRadius: "0.5rem",
                    }}
                >
                    <DeleteOutlineIcon />
                </IconButton>
            </Box>
            <div className="contenedor-paravos">
                <div className="background-scroll">
                    {loading ? (
                        <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
                            {Array.from({ length: 5 }).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    style={{
                                        height: "11rem", // Altura fija
                                        width: "90%", // Ancho relativo al contenedor
                                        margin: "0 auto", // Centrar el esqueleto horizontalmente
                                        borderRadius: "1.5625rem",
                                        padding: "1rem 0.8rem",
                                        marginBottom: "1rem",
                                    }}
                                />
                            ))}
                        </SkeletonTheme>
                    ) : foros.length > 0 ? (
                        foros.map((foro: any) => (
                            <TarjetaForo
                                key={foro.id_foro}
                                id={foro.id_foro}
                                id_tematica={foro.id_tematica}
                                titulo={foro.nombre}
                                descripcion={foro.descripcion}
                                colorTitulo={"#44291E"}
                                eliminarActivo={eliminarActivo} // Pasar el estado de eliminación
                                onEliminar={handleEliminarForo} // Pasar la función de eliminación
                                onClick={() => console.log()}
                            />
                        ))
                    ) : (
                        <Box className="sin-recomendaciones">
                            <div className="sin-recomendaciones-text">
                                <Typography
                                    sx={{
                                        fontFamily: "Sora",
                                        color: "#003147",
                                        fontSize: "0.9375rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    Aún no tienes foros creados
                                </Typography>
                            </div>
                            <img src="assets/Resultados/sadFaceIcon.svg" alt="Sad Face" />
                        </Box>
                    )}
                </div>
            </div>
            <Fab
                variant="extended"
                aria-label="add"
                sx={{
                    position: "fixed",
                    bottom: "5rem", // Ajusta según la separación que desees del borde inferior
                    right: "2rem", // Ajusta según la separación que desees del borde derecho
                    zIndex: 1000, // Asegura que el botón esté por encima de otros elementos
                    color: "#003147",
                    backgroundColor: "#FFF", // Color personalizado
                    "&:hover": {
                        backgroundColor: "#002236", // Color al hacer hover
                    },
                    fontFamily: "Sora",
                    fontSize: "0.75rem",
                    textTransform: "none",
                    fontWeight: 700,
                }}
                onClick={() => setOpenModalNuevoForo(true)}
            >
                Nuevo foro <AddIcon />
            </Fab>
            <ModalNuevoForo
                open={openModalNuevoForo}
                setOpen={setOpenModalNuevoForo}
                idCafeteria={cafeteria?.id_cafeteria || undefined}
                onForoCreado={() => fetchForos(cafeteria?.id_cafeteria!)} // Recarga los foros
            />
            <NavCafeteria select={1} />
        </Box>
    );
};

export default ForoCafeteria;
