import React from "react";
import Nav from "../components/home/Nav";
import Encabezado from "../components/Mapa/EncabezadoMapa"; 
import { useParams } from "react-router-dom";
import Ruta from "../components/Mapa/Ruta";

const CrearRuta: React.FC = () => {
  const googleMapsApiKey = "AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM";
  const { uid } = useParams();
  return (
    <div>
      {/* poner boton para ir para atras */}
      <Encabezado /> 
      <Ruta apiKey={googleMapsApiKey} />
    </div>
  );
};

export default CrearRuta;