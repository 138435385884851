import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cabecera from "../components/home/Cabecera";
import {
  Typography,
  Button,
  Fab,
  List,
  Skeleton,
  SwipeableDrawer,
  styled,
  CssBaseline,
  Box,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Nav from "../components/home/Nav";
import { calcularDiferenciaDias } from "../components/foro/posteo/Posteo";
import {
  getComentariosPost,
  getInfoPost,
  getInfoReducidaForo,
  postInteraccion,
} from "../App/Services/foroService";

import AddIcon from "@mui/icons-material/Add";
import CommentItem, {
  IComentario,
} from "../components/foro/posteo/Comentarios/ComentarioItem";
import { logoForoPorId } from "./Foro";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { useAuth } from "../context/AuthContext";
import { grey } from "@mui/material/colors";

interface IDatosPosteo {
  id_tematica: number;
  nombre_foro: string;
  id: number;
  nombre: string;
  apellido: string;
  fecha: string;
  titulo: string;
  descripcion: string;
  cantidad_likes: number;
  cantidad_dislikes: number;
}

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.applyStyles("dark", {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  ...theme.applyStyles("dark", {
    backgroundColor: grey[900],
  }),
}));

export default function ComentariosPost() {
  const { id_foro, id_post } = useParams();
  const id_foro_number = Number(id_foro);
  const id_post_number = Number(id_post);
  const usuario = useAuth();

  const [datosPost, setDatosPost] = useState<IDatosPosteo>();
  const [comentarios, setComentarios] = useState<IComentario[]>();
  const [loading, setLoading] = useState(true);
  const [actualizar, setActualizar] = useState(false);
  const [open, setOpen] = useState(false);
  const [descripcion, setDescripcion] = useState<string | null>(null);
  const [idComentario, setIdComentario] = useState<number | null>(null);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    if (!newOpen) {
      resetDescripcion();
    }
  };

  const fetchComentarios = () => {
    // Obtener los comentarios
    getComentariosPost(id_foro_number, id_post_number)
      .then((res: any) => setComentarios(res))
      .catch((error: any) => console.log(error));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const foroData = await getInfoReducidaForo(id_foro_number);
        const postData = await getInfoPost(id_foro_number, id_post_number);

        setDatosPost({
          id_tematica: foroData.id_tematica,
          nombre_foro: foroData.nombre_foro,
          id: postData.id_post,
          titulo: postData.titulo,
          descripcion: postData.descripcion,
          cantidad_likes: postData.cant_likes,
          cantidad_dislikes: postData.cant_dislikes,
          nombre: postData.info_meriendista.nombre_meriendista,
          apellido: postData.info_meriendista.apellido_meriendista,
          fecha: postData.info_meriendista.fecha_creacion,
        });
        setLoading(false); // Datos cargados, desactivar el loading
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setLoading(false);
      }
    };

    fetchData(); // Llama a la función async dentro del useEffect
  }, [id_foro_number, id_post_number]); // Asegúrate de incluir ambas dependencias si las necesitas

  useEffect(() => {
    fetchComentarios();
  }, [id_post, id_foro, actualizar]);

  
  const resetDescripcion = () => {
    setDescripcion(null);
    setIdComentario(null);
  };

  function handleUtil(id_tipo_interaccion: number): void {
    if (usuario?.user?.uid) {
    postInteraccion(
      usuario.user.uid,
      id_post_number,
      id_foro_number,
      id_tipo_interaccion,
      descripcion,
      idComentario,
    )
      .then((res) => {
        getInfoPost(id_foro_number, id_post_number)
          .then((postData) => {
            setDatosPost((prevState) => {
              if (!prevState) return prevState;

              return {
                ...prevState,
                cantidad_likes: postData.cant_likes,
                cantidad_dislikes: postData.cant_dislikes,
              };
            });
          })
          .catch((error) =>
            console.error("Error al actualizar datos del post:", error)
          );
        fetchComentarios();
        resetDescripcion();
      })
      .catch((error) => console.log("error al marcar util"));
    }
  }


  return (
    <div className="inicio">
      <Cabecera es_home={false} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFF",
          mx: "2rem",
          borderRadius: "1rem",
          overflowY: "scroll",
          overflowX: "hidden", // Evita el desplazamiento horizontal
          maxWidth: "100vw", // Asegúrate de que el ancho sea fijo
          scrollbarWidth: "none", // Oculta scrollbar en Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Oculta scrollbar en Chrome/Safari
          },
          pr: "0.5rem",
        }}
      >
        <Grid
          container
          display={"flex"}
          alignItems="center"
          sx={{ mt: "0.87rem", ml: "0.87rem" }}
        >
          <Grid alignSelf={"center"} sx={{ mr: "0.6rem" }}>
            {loading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              logoForoPorId.get(datosPost?.id_tematica ?? 0)
            )}
          </Grid>
          <Grid justifySelf={"initial"}>
            {loading ? (
              <Skeleton width="100px" height="20px" />
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Higuen",
                    color: "#44291E",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    marginBottom: "0.3rem",
                  }}
                >
                  {datosPost?.nombre_foro || "Cargando..."}{" "}
                  {/* Verificación para evitar acceder a undefined */}
                </Typography>
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography sx={{ fontFamily: "Sora", fontSize: "0.5rem" }}>
                    {datosPost
                      ? `${datosPost.nombre} ${
                          datosPost.apellido
                        } . ${calcularDiferenciaDias(datosPost.fecha)}`
                      : "Cargando..."}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid size={12} display={"flex"} flexDirection={"column"} sx={{ mt: "1rem" }}>
            {loading ? (
              <Skeleton width="200px" height="30px" />
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    fontSize: "0.9375rem",
                    fontWeight: "600",
                    mb: "0.8rem",
                  }}
                >
                  {datosPost?.titulo}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    fontSize: "0.625rem",
                    fontWeight: "400",
                  }}
                >
                  {datosPost?.descripcion}
                </Typography>
              </>
            )}
          </Grid>
          <Grid display={"flex"} flexDirection={"row"} sx={{ mt: "1rem" }}>
            <Button
              sx={{
                height: "0.9375rem",
                borderRadius: "0.8125rem",
                background: "#D9D9D9",
                color: "#000",
                fontFamily: "Sora",
                fontSize: "0.4375rem",
                textTransform: "none",
              }}
              startIcon={
                <ThumbUpOffAltOutlinedIcon
                  sx={{ fontSize: "0.62813rem !important" }}
                />
              }
              onClick={() => handleUtil(1)}
              disabled={loading} // Deshabilitar si está cargando
            >
              {loading ? <Skeleton width="20px" /> : datosPost?.cantidad_likes}
            </Button>
            <Button
              sx={{
                height: "0.9375rem",
                borderRadius: "0.8125rem",
                background: "#D9D9D9",
                color: "#000",
                fontFamily: "Sora",
                fontSize: "0.4375rem",
                textTransform: "none",
              }}
              startIcon={
                <ThumbDownAltOutlinedIcon
                  sx={{ fontSize: "0.62813rem !important" }}
                />
              }
              onClick={() => handleUtil(2)}
              disabled={loading} // Deshabilitar si está cargando
            >
              {loading ? (
                <Skeleton width="20px" />
              ) : (
                datosPost?.cantidad_dislikes
              )}
            </Button>
          </Grid>
        </Grid>

        <List>
          {comentarios?.map((comentario: any) => (
            <CommentItem
              key={comentario.id}
              id_interaccion={comentario.id_interaccion}
              id_avatar={comentario.id_avatar}
              nombre_meriendista={comentario.nombre_meriendista}
              apellido_meriendista={comentario.apellido_meriendista}
              fecha_hora_creacion={comentario.fecha_hora_creacion}
              descripcion={comentario.descripcion}
              cant_likes={comentario.cant_likes}
              cant_dislikes={comentario.cant_dislikes}
              respuestas={
                comentario.comentarios != null ? comentario.comentarios : []
              }
              setActualizar={setActualizar}
              setResponderA={setIdComentario}
              responder={toggleDrawer}
              id_foro={id_foro_number}
              id_post={id_post_number}
            />
          ))}
        </List>
      </Box>

      <Fab
        size="large"
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "5rem", // Ajusta según la separación que desees del borde inferior
          right: "2rem", // Ajusta según la separación que desees del borde derecho
          zIndex: 1000,
          // Asegura que el botón esté por encima de otros elementos
          backgroundColor: "#003147", // Color personalizado
          "&:hover": {
            backgroundColor: "#002236", // Color al hacer hover
          },
        }}
        onClick={toggleDrawer(true)}
      >
        <AddIcon />
      </Fab>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          borderRadius: "1.06rem",
        }}
      >
        <StyledBox
          sx={{
            height: "auto", // Ajuste automático de altura
            minHeight: "30vh", // Valor mínimo de altura
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflowY: "auto",
            my: "2rem",
            mx: "2.69rem",
          }}
        >
          <Puller />
          <Grid container alignItems="center">
            <Typography
              sx={{
                color: "#44291E",
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "400",
              }}
            >
              Responder {idComentario != null ? "a" : "en"}
            </Typography>
            <Typography
              sx={{
                color: "#44291E",
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                ml: 0.5, // Añade un margen a la izquierda para espacio
              }}
            >
              {idComentario != null ? (comentarios?.find((comentario) => comentario.id_interaccion == idComentario)?.nombre_meriendista) : (datosPost?.nombre_foro)} 
            </Typography>
          </Grid>
          <StyledBox>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              value={descripcion || ""}
              onChange={(e) => setDescripcion(e.target.value)}
              fullWidth
              sx={{ borderRadius: "1.69rem" }}
            />
            <Button
              sx={{
                backgroundColor: "#003147",
                borderRadius: "1.125rem",
                color: "#FFF",
                fontFamily: "Sora",
                fontSize: "0.6875rem",
                textTransform: "none",
                mt: "2rem",
              }}
              disabled={descripcion == null}
              onClick={() => {
                handleUtil(3);
                resetDescripcion();
                toggleDrawer(false)();
              }}
            >
              Publicar
            </Button>
          </StyledBox>
        </StyledBox>
      </SwipeableDrawer>
      <Nav select={0} />
    </div>
  );
}
