import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCafeteriasPorNombreYTags } from "../App/Services/cafeteriaService";
import Nav from "../components/home/Nav";
import ComponenteCafeteriaParaVos from "../components/pure/CafeteriaParaVos";
import Cabecera from "../components/home/Cabecera";
import Buscador from "../components/home/Buscador";
import useHandleSearch from "../components/hooks/useHandleSearch";
import { Box, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "../styles/Resultados.css";

const Resultados: React.FC = () => {
  const location = useLocation();
  const handleSearch = useHandleSearch();
  const [cafeterias, setCafeterias] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { tag } = location.state || {}; // Extraer el objeto tag del estado

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("search");

    setLoading(true); // Mostrar loading mientras carga

    if (tag && tag.id_tag) {
      // Si hay un tag, usar el id_tag en el endpoint
      getCafeteriasPorNombreYTags(undefined, tag.id_tag)
        .then((res) => {
          setCafeterias(res); // Actualiza con los resultados basados en el tag
        })
        .catch((error) => {
          console.log("Error al buscar cafeterías por tag", error);
          setCafeterias([]); // Maneja el error dejando la lista vacía
        })
        .finally(() => {
          setLoading(false); // Finaliza el loading
        });
    } else if (searchQuery) {
      // Si no hay tag pero hay una searchQuery
      setSearchQuery(searchQuery);
      getCafeteriasPorNombreYTags(searchQuery)
        .then((res) => {
          setCafeterias(res); // Actualiza con los resultados basados en el nombre
        })
        .catch((error) => {
          console.log("Error al buscar cafeterías por nombre", error);
          setCafeterias([]); // Maneja el error dejando la lista vacía
        })
        .finally(() => {
          setLoading(false); // Finaliza el loading
        });
    } else {
      // Si no hay ni searchQuery ni tag, limpiar los resultados
      setCafeterias([]);
      setLoading(false);
    }
  }, [location.search, tag]);

  return (
    <div className="resultado-inicio">
      <Cabecera es_home={false} />
      <Buscador label={"Buscar cafeterias"} onEnter={handleSearch} />

      <Typography
        sx={{
          fontFamily: "Sora",
          color: "#003147",
          fontSize: "1.125rem",
          fontWeight: 600,
          marginLeft: "3rem",
          marginTop: "1rem",
          width: "20.063rem",
        }}
      >
        {tag
          ? `Resultados para el tag "${tag.nombre}"`
          : `Resultados sobre "${searchQuery}"`}
      </Typography>

      {loading ? (
        <Box className="resultado-loading">
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              sx={{
                height: "9.125rem",
                borderRadius: "1.5625rem",
                marginBottom: "1rem",
                marginLeft: "2.063rem",
                marginRight: "2.313rem",
              }}
            >
              <Skeleton
                height="100%"
                width="100%"
                style={{
                  borderRadius: "1.5625rem",
                }}
              />
            </Box>
          ))}
        </Box>
      ) : cafeterias.length > 0 ? (
        <Box className="resultado-cafeteria">
          {cafeterias.slice(0, 5).map((cafeteria: any) => (
            <ComponenteCafeteriaParaVos
              key={cafeteria.id_cafeteria}
              id_cafeteria={cafeteria.id_cafeteria}
              nombre_cafeteria={cafeteria.nombre}
              imagen={cafeteria.imagen}
              estado={cafeteria.estado}
              cantidad_estrellas={cafeteria.calificacion}
              direccion={cafeteria.direccion}
              tags={cafeteria.tags}
            />
          ))}
        </Box>
      ) : (
        <Box className="resultado-no-encontrado">
          <img
            src="assets/Resultados/sadFaceIcon.svg"
            alt="Sad Face"
            className="resultado-no-encontrado-img"
          />
          <div className="resultado-no-encontrado-text">
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "1.125rem",
                fontWeight: 600,
              }}
            >
              No se encontraron cafeterías que coincidan
            </Typography>
          </div>
        </Box>
      )}
      <Nav select={0} />
    </div>
  );
};

export default Resultados;
