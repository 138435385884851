import React, { Dispatch, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../context/AuthContext";
import { getAllTematicas, postForo, postForoCafet } from "../../../App/Services/foroService";
import ReusableModal from "../Modal";

interface IModalNuevoPost {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  idCafeteria?: string; // Opcional: ID de la cafetería
  onForoCreado?: () => void;
}

interface ITematica {
  nombre: string;
  descripcion: string;
  id_tematica: number;
}

export default function ModalNuevoForo(props: IModalNuevoPost) {
  const [tematicas, setTematicas] = useState<ITematica[]>();
  const [tematica, setTematica] = useState<number | null>(null);
  const [titulo, setTitulo] = useState<string | null>(null);
  const [descripcion, setDescripcion] = useState<string | null>(null);
  const [esContenidoEducativo, setEsContenidoEducativo] = useState<boolean>(false);
  const usuario = useAuth();
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<any[]>([]);

  const handleClose = () => {
    limpiarCampos();
    props.setOpen(false);
  };

  const limpiarCampos = () => {
    setTematica(null);
    setTitulo(null);
    setDescripcion(null);
    setEsContenidoEducativo(false);
  };

  const handleCloseModal = () => {
    setOpenModalError(false);
  };

  const handlePublicar = () => {
    if (props.idCafeteria) {
      // Publicar para cafetería
      postForoCafet(
        props.idCafeteria,
        tematica!,
        titulo!,
        descripcion!,
        esContenidoEducativo
      )
        .then(() => {
          handleClose(); // Cierra el modal y limpia los campos
          if (props.onForoCreado) props.onForoCreado(); // Llama al callback si existe
        })
        .catch((error: Error) => {
          setTituloModal("Error");
          setContenidoModal(error.message);
          setBotonesModal([
            {
              label: "Aceptar",
              onClick: handleCloseModal,
              sx: {
                background: "#44291E",
                color: "#F0ECE3",
                fontFamily: "Sora",
                fontWeight: "600",
                borderRadius: "0.9375rem",
                width: "9.75rem",
              },
            },
          ]);
          setOpenModalError(true);
        });
    } else if (usuario?.user?.uid) {
      // Publicar para meriendista
      postForo(
        usuario.user.uid,
        tematica!,
        titulo!,
        descripcion!,
        esContenidoEducativo
      )
        .then(() => {
          handleClose(); // Cierra el modal y limpia los campos
          if (props.onForoCreado) props.onForoCreado(); // Llama al callback si existe
        })
        .catch((error: Error) => {
          setTituloModal("Error");
          setContenidoModal(error.message);
          setBotonesModal([
            {
              label: "Aceptar",
              onClick: handleCloseModal,
              sx: {
                background: "#44291E",
                color: "#F0ECE3",
                fontFamily: "Sora",
                fontWeight: "600",
                borderRadius: "0.9375rem",
                width: "9.75rem",
              },
            },
          ]);
          setOpenModalError(true);
        });
    }
  };


  useEffect(() => {
    getAllTematicas()
      .then((res) => setTematicas(res))
      .catch((error: any) => console.log("Error al recuperar temáticas", error));
  }, []);

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, left: 8 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: "1rem" }}>
            <Select
              value={tematica || ""}
              onChange={(e) => setTematica(Number(e.target.value))}
              displayEmpty
              variant="standard"
              sx={{ flex: 1 }}
              renderValue={(selected) => {
                if (!selected) {
                  return <span style={{ color: "#aaa" }}>Selecciona una temática</span>;
                }
                return tematicas?.find(
                  (tematica) => tematica.id_tematica === selected
                )?.nombre;
              }}
            >
              <MenuItem disabled value="">
                <span style={{ color: "#aaa" }}>Selecciona una temática</span>
              </MenuItem>
              {tematicas?.map((tematica: ITematica) => (
                <MenuItem key={tematica.id_tematica} value={tematica.id_tematica}>
                  {tematica.nombre}
                </MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={esContenidoEducativo}
                  onChange={(e) => setEsContenidoEducativo(e.target.checked)}
                />
              }
              label="Es contenido educativo"
            />
          </Box>

          <TextField
            label="Título"
            variant="standard"
            value={titulo || ""}
            onChange={(e) => setTitulo(e.target.value)}
            fullWidth
            sx={{ fontWeight: "bold", fontSize: "1.25rem" }}
          />
          <TextField
            multiline
            rows={4}
            placeholder="Descripción"
            variant="standard"
            value={descripcion || ""}
            onChange={(e) => setDescripcion(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "#003147",
              "&:hover": { backgroundColor: "#002236" },
              textTransform: "none",
              borderRadius: "1.125rem",
              fontFamily: "Sora",
              fontSize: "0.68875",
            }}
            disabled={!(titulo && descripcion && tematica) ? true : false}
            onClick={handlePublicar}
          >
            Publicar
          </Button>
        </Box>
      </Modal>
      <ReusableModal
        open={openModalError}
        onClose={handleCloseModal}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
    </>
  );
}