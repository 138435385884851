import React from "react";
import MapWithGeolocation from "../components/Mapa/MapWithGeolocation"; // Asegúrate de ajustar la ruta
import Nav from "../components/home/Nav";
import Encabezado from "../components/Mapa/EncabezadoMapa"; // Asegúrate de ajustar la ruta

const MapaCafeterias: React.FC = () => {
  const googleMapsApiKey = "AIzaSyBkU45ZVAiHckcL1dqBjg1W70thduLrXNM"; // Reemplaza con tu clave de API

  return (
    <div>
      <Encabezado />
      <MapWithGeolocation apiKey={googleMapsApiKey} />
      <Nav select={1} />
    </div>
  );
};


export default MapaCafeterias;
