import { Grid, Typography, Box } from "@mui/material";
import "../../../styles/consultarCafeteria.css";
import SwipeToSlidePromociones from "./SliderPromociones";

interface IPromociones {
  promociones: any[];
  editar?: boolean; // Nuevo prop para habilitar edición
}

export default function PromocionesCafeteria({
  promociones,
  editar,
}: IPromociones) {
  return (
    <Box className="promociones">
      {/* Carrusel de promociones */}
      {promociones && promociones.length > 0 ? (
        <SwipeToSlidePromociones promociones={promociones} editar={editar} />
      ) : (
        <Typography
          sx={{
            fontFamily: "Sora",
            color: "#757575",
            fontSize: "0.875rem",
            fontWeight: "400",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          No hay promociones disponibles en este momento.
        </Typography>
      )}
    </Box>
  );
}
