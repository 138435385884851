import React, { useEffect, useState } from "react";
import "../../styles/Inicio.css";
import NavCafeteria from "../../components/home/NavCafeteria";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getCafeteria } from "../../App/Services/cafeteriaService";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
import ReusableModal, { ModalButton } from "../../components/Modales/Modal";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import { crearPromocion } from "../../App/Services/Cafeteria/promocionesServices";

const RegistrarPromocion: React.FC = () => {
  const usuario = useAuth();
  const [cafeteria, setCafeteria] = useState<any>(null);
  const [nombre, setNombre] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");
  const [condiciones, setCondiciones] = useState<string>("");
  const [fechaInicio, setFechaInicio] = useState<Date | null>(null);
  const [fechaFin, setFechaFin] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFormValid, setIsFormValid] = useState<boolean>(false); // Nuevo estado
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<ModalButton[]>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDatosCafeteria = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosCafeteria = await getCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setCafeteria(datosCafeteria);
        } catch (error) {
          console.error("Error al obtener datos de la cafetería:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDatosCafeteria();
  }, [usuario]);

  useEffect(() => {
    const isValid =
      nombre.trim() !== "" &&
      descripcion.trim() !== "" &&
      condiciones.trim() !== "" &&
      fechaInicio !== null &&
      fechaFin !== null;
    setIsFormValid(isValid);
  }, [nombre, descripcion, condiciones, fechaInicio, fechaFin]);

  useEffect(() => {
    if (fechaFin && fechaInicio && fechaInicio > fechaFin) {
      setFechaFin(null);
    }
  }, [fechaInicio])

  // Funciones onChange para los campos
  const handleNombreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(e.target.value);
  };

  const handleDescripcionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescripcion(e.target.value);
  };

  const handleCondicionesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCondiciones(e.target.value);
  };

  const handleFechaInicioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFecha = e.target.value;

    // Verifica que el valor tenga el formato correcto (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(inputFecha)) {
      const newDate = new Date(inputFecha);
      // Verifica si el objeto Date es válido
      if (!isNaN(newDate.getTime())) {
        setFechaInicio(newDate);
      } else {
        return; // Si la fecha no es válida
      }
    } else {
      return; // Si el formato no es válido
    }
  };

  const handleFechaFinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFecha = e.target.value;

    // Verifica que el valor tenga el formato correcto (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(inputFecha)) {
      const newDate = new Date(inputFecha);
      // Verifica si el objeto Date es válido
      if (!isNaN(newDate.getTime())) {
        setFechaFin(newDate);
      } else {
        return; // Si la fecha no es válida
      }
    } else {
      return; // Si el formato no es válido
    }
  };

  const handleContinuar = () => {
    navigate("/promociones-cafeteria");
  };

  const handleGuardarPromocion = () => {
    // Aquí agregarías la lógica para guardar el evento, incluyendo la imagen.
    if (usuario.user?.uid && fechaInicio && fechaFin) {
        const fecha = new Date();
      crearPromocion(
        usuario.user?.uid,
        cafeteria.id_cafeteria,
        nombre,
        descripcion,
        condiciones,
        fecha.toISOString().split("T")[0],
        fechaInicio.toISOString().split("T")[0],
        fechaFin.toISOString().split("T")[0],
      )
        .then((res) => {
          setTituloModal("Éxito");
          setContenidoModal("La promoción se ha registrado correctamente");
          setBotonesModal([
            {
              label: "Continuar",
              onClick: handleContinuar,
              sx: {
                background: "#F0ECE3",
                color: "#44291E",
                borderRardius: "0.9375rem",
              },
            },
          ]);
        })
        .catch((error) => {
          setTituloModal("Error");
          setContenidoModal("Error al registrar la promoción, intente nuevamente");
          setBotonesModal([
            {
              label: "Cancelar",
              onClick: handleContinuar,
              sx: {
                background: "#44291E",
                color: "#F0ECE3",
                borderRardius: "0.9375rem",
              },
            },
            {
              label: "Reintentar",
              onClick: () => setOpenModal(false),
              sx: {
                background: "#F0ECE3",
                color: "#44291E",
                borderRardius: "0.9375rem",
              },
            },
          ]);
        })
        .finally(() => setOpenModal(true));
    }
  };

  return (
    <div className="registrar-cafeteria">
      <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />

      {/* Título */}
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontFamily: "Sora",
            color: "#44291E",
            fontSize: "1.5rem",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          ¡Registremos tu promoción!
        </Typography>
        <Typography
          sx={{
            fontFamily: "Sora",
            fontSize: "0.6875rem",
            fontWeight: "300",
            textAlign: "center",
          }}
        >
          (*) Indica que el campo es obligatorio
        </Typography>
      </Box>

      {/* Formulario */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "1.2rem",
          backgroundColor: "#FFF",
          p: "1rem",
          pb: "5rem",
          borderRadius: "1.75rem",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          overflow: "scroll",
        }}
      >
        {/* Nombre del evento */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Nombre de la promoción *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              value={nombre}
              placeholder="2x1 en IceCoffe"
              onChange={handleNombreChange}
              inputProps={{
                maxLength: 25, // Máximo de 50 caracteres
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>
        {/* Fecha y Hora */}
        <Grid container spacing={2}>
          {/* Fecha */}
          <Grid item xs={6} sm={6}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Fecha de inicio *
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              value={fechaInicio ? fechaInicio.toISOString().split("T")[0] : ""}
              onChange={handleFechaInicioChange}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate())) // Fecha mínima: hoy
                  .toISOString()
                  .split("T")[0],
              }}
              onKeyDown={(e) => e.preventDefault()} // Evita que el usuario escriba manualmente
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1887F", // Color del borde al pasar el mouse
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D7CCC8", // Color del borde inicial
                },
              }}
            />
          </Grid>

          {/* Fecha */}
          <Grid item xs={6} sm={6}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Fecha de fin *
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              disabled={fechaInicio == null}
              value={fechaFin ? fechaFin.toISOString().split("T")[0] : ""}
              onChange={handleFechaFinChange}
              inputProps={{
                min: fechaInicio ? (new Date(new Date().setDate(fechaInicio.getDate() + 1)) // Fecha mínima: fechaInicio
                  .toISOString()
                  .split("T")[0]) : (new Date(new Date().setDate(new Date().getDate())) // Fecha mínima: hoy
                  .toISOString()
                  .split("T")[0]),
              }}
              onKeyDown={(e) => e.preventDefault()} // Evita que el usuario escriba manualmente
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1887F", // Color del borde al pasar el mouse
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D7CCC8", // Color del borde inicial
                },
              }}
            />
          </Grid>
        </Grid>
        
        {/* Condiciones */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Condiciones *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={condiciones}
              onChange={handleCondicionesChange}
              placeholder="Pagando en efectivo"
              inputProps={{
                maxLength: 60,
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Descripción */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Descripción *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={descripcion}
              onChange={handleDescripcionChange}
              placeholder="2x1 en Ice Coffe seleccionados solo pagando en efectivo"
              inputProps={{
                maxLength: 100, // Máximo de 50 caracteres
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Botón de Guardar */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#44291E",
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "Sora",
              fontSize: "1rem",
              fontWeight: "600",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#A1887F",
              },
              mr: "0.5rem"
            }}
            onClick={handleContinuar}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            sx={{
              backgroundColor: "#44291E",
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "Sora",
              fontSize: "1rem",
              fontWeight: "600",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#A1887F",
              },
            }}
            onClick={handleGuardarPromocion}
          >
            Registrar
          </Button>
        
        </Box>
      </Box>
      <ReusableModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
      <NavCafeteria select={0} />
    </div>
  );
};

export default RegistrarPromocion;