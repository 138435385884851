// export const getCafeteria (id_Cafeteria) =>
import axios, { AxiosResponse, AxiosError } from "axios";
import { BASE_URL } from "./config";

// Función para obtener los datos de una cafetería por su ID
export const getCafeteria = async (id_cafeteria: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/cafeterias/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getTags = async (): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/tags/`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const esFavorita = async (
  id_cafeteria: number,
  uid: string
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/favoritos/${id_cafeteria}/${uid}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const marcarFavorita = async (
  id_cafeteria: number,
  uuid: string,
  favorito: boolean
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/favoritos/${id_cafeteria}/${uuid}`, {
      es_favorito: favorito,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

// Función para obtener los datos de una cafetería por su nombre y/o por su tag
export const getCafeteriasPorNombreYTags = async (
  nombre_cafeteria?: string,
  id_tag?: number
): Promise<any> => {
  const params: any = {};

  if (nombre_cafeteria) {
    params.palabraClave = nombre_cafeteria;
  }

  if (id_tag) {
    params.tags = id_tag; // Agregamos el id_tag a los parámetros
  }

  const response = await axios
    .get(`${BASE_URL}/cafeterias/`, { params })
    .then((res) => res.data)
    .catch((error) => {
      throw Error(error);
    });

  return response;
};

export const getCafeteriaReducida = async (
  id_cafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/cafeterias/reducida/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getReseñasCafeteria = async (
  id_cafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/reviews/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const postReseña = async (
  id_cafeteria: number,
  uid: string,
  puntaje: number,
  descrip: string
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/reviews/${id_cafeteria}`, {
      descripcion: descrip,
      calificacion: puntaje,
      uid_meriendista: uid,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const marcarUtilReseña = async (
  id_resenia: number,
  es_util: boolean
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/reviews/utilidad/${id_resenia}`, {
      es_util: es_util,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};


export const obtenerHomeReviewsCafeteria = async (
  id_cafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/reviews/reducidas/${id_cafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const postregistrarCafeteria = async (
  datos: {
    nombre: string;
    descripcion: string;
    latitud: string;
    longitud: string;
    direccion: string;
    telefono: string;
    email: string;
    carta: string;
    id_barrio: number;
    uid_administrador: string | null;
    ids_tags: number[];
    links: string[];
    franjas_horarias: {
      id_dia: number;
      hora_apertura: string;
      hora_cierre: string;
    }[];
    fotos_base_64: string[];
  }
) => {
  try {
    const response = await fetch(`${BASE_URL}/cafeterias/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos), // Serializa el objeto `datos` como JSON
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error al registrar la cafetería:", errorText);
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    const result = await response.json();
    console.log("Cafetería registrada exitosamente:", result);
    return result; // Retorna el resultado en caso de éxito
  } catch (error) {
    console.error("Error al realizar la solicitud:", error);
    throw error; // Propaga el error para manejarlo fuera de la función
  }
};

export const patchCafeteria = async (data: any) => {
  try {
    const response = await fetch(`${BASE_URL}/cafeterias/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Verifica si hay contenido en la respuesta antes de procesarlo como JSON
    if (response.ok && response.headers.get("Content-Length") !== "0") {
      const result = await response.json();
      return result;
    } else if (response.ok) {
      // Si la respuesta es 200 pero no hay cuerpo
      return null;
    } else {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error al enviar la solicitud:", error);
    throw error;
  }
};
