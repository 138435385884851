import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

export interface ModalButton {
  label: string;
  onClick: () => void;
  variant?: "text" | "contained" | "outlined";
  color?: string;
  sx?: object;
}

interface ReusableModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content: React.ReactNode;
  buttons?: ModalButton[];
}

const ReusableModal: React.FC<ReusableModalProps> = ({
  open,
  onClose,
  title,
  content,
  buttons,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "20.1875rem",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        {title && (
          <Typography variant="h6" component="h2" gutterBottom
          sx={{
            fontFamily: "Sora",
            color: "#000",
            fontSize: " 1.125rem",
            fontWeight: "400",
            }}
            >
            {title}
          </Typography>
        )}
        <Box sx={{ mb: 2, color: '#44291E', fontFamily: "Sora", }}>{content}</Box>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {buttons &&
            buttons.map((button, index) => (
              <Button
                key={index}
                onClick={button.onClick}
                sx={button.sx}
              >
                {button.label}
              </Button>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ReusableModal;