import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardActionArea, CardMedia, Grid, Typography } from "@mui/material";
import RatingSize from "./Estrellas";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import Skeleton from 'react-loading-skeleton';

export interface Cafeteria {
    id_cafeteria: number,
    nombre_cafeteria: string,
    imagen: string,
    estado: string,
    cantidad_estrellas: number,
    direccion: string,
    descripcion?: string,
    horario?: string,
}

const ComponenteCafeteria: React.FC<Cafeteria> = (cafeteria) => {
    const navigate = useNavigate();
    const handleConsultarCafeteria = (id_cafeteria: number) => {
        //crear un loader para poder activarlo y que no seleccionen como loquitos
        navigate(`/cafeteria/${id_cafeteria}`);
    }

    return (
        <Card key={cafeteria.id_cafeteria} sx={{ width: "7.775rem", height: "12.125rem", backgroundColor: "#44291E", borderRadius: "1.5625rem", padding: "1rem 0.8rem" }}>
            <CardActionArea
                sx={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                }}
                onClick={() => {
                    handleConsultarCafeteria(cafeteria.id_cafeteria);
                }}>
                <Grid 
                    container
                    justifyContent={"center"}
                    spacing={1}>
                    <Grid item xs={12}>
                        <CardMedia
                            sx={{height: "5rem", borderRadius: "1.125rem", objectFit: "cover"}}
                            image={`data:image/bmp;base64,${cafeteria.imagen}`}
                            title={cafeteria.nombre_cafeteria}>
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{fontFamily: "Higuen", color: "#FFF", fontSize: "1.125rem", height: "3rem"}}>
                                    {cafeteria.nombre_cafeteria}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{fontFamily: "Sora", color: "#DAB587", fontSize: "0.5625rem", fontWeight: "bold"}}>
                                    {cafeteria.estado}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <RatingSize value={Math.round(cafeteria.cantidad_estrellas)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item xs={2}>
                                <FmdGoodOutlinedIcon sx={{color: '#FFFFFF'}} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    sx={{fontFamily: "Sora", color: "#FFF", fontSize: "0.5625rem", fontWeight: "400"}}>
                                    {cafeteria.direccion}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}

export default ComponenteCafeteria;