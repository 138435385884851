import { Grid, Link, Typography } from "@mui/material";

interface ICarta {
  link: string
}

export default function Carta (props: ICarta) {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        window.open(`${props.link}`, '_blank', 'noopener');
        };
  return (
    <div className="promociones">
      {/* Aquí va el contenido de contacto */}
      <div style={{}}>
        <Grid container alignItems={"center"}>
          <Grid item xs={1}>
            <img
              src="\assets\consultarCafeteria\medialunaCartaIcon.svg"
              alt="Icono Carta"
              style={{
                width: "1.375rem", // Definir el ancho explícito
                height: "1.375rem", // Definir la altura explícita
                verticalAlign: "middle", // Alineación vertical
                paddingBottom: "5px",
                paddingRight: "5px",
              }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontFamily: "Higuen",
                color: "#003147",
                fontSize: "1.25rem",
                fontWeight: "400",
                marginBottom: "0.625rem",
              }}
            >
              Carta
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container margin={"0.2rem"}>
          <Grid item xs={1}>
            <img
              src="/assets/consultarCafeteria/linkWebIcon.svg"
              alt="linkIcon"
            />
          </Grid>
          <Grid item xs={11}>
            <Link
              component="button"
              variant="body2"
              onClick={handleClick}
              sx={{
                fontFamily: "Sora",
                color: "#000",
                fontSize: "0.6875rem",
                fontWeight: "400",
                wordBreak: "break-all", // Permitir que el link largo se divida en varias líneas
                overflowWrap: "break-word", // Romper la palabra si es necesario
              }}
            >
              {props.link}
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
