import React from "react";
import { Typography, Grid, SxProps } from "@mui/material";

interface DireccionCafeteriaProps {
  direccion: string;
  centrar?: boolean; // Prop para controlar la alineación
  sx?: SxProps; // Añadir 'sx' como propiedad opcional
}

const DireccionCafeteria: React.FC<DireccionCafeteriaProps> = ({ direccion, centrar, sx }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent={centrar ? "center" : "flex-start"} // Controlamos la alineación según la prop
    >
      <Grid item>
        <img
          src="/assets/consultarCafeteria/pinDeMapaDireccionIcon.svg"
          alt="Pin de Mapa"
          style={{
            color: "#44291E",
            width: "0.697rem",
            height: "0.941rem",
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{
            paddingLeft: centrar ? "0.5rem" : "0.375rem", // Ajustar margen para centrado
            fontFamily: "Sora",
            fontSize: "0.75rem",
            fontStyle: "normal",
            fontWeight: "400",
            color: "#44291E",
            textAlign: centrar ? "center" : "left", // Ajustar alineación del texto
            ...sx, // Permite sobreescribir estilos desde sx
          }}
        >
          {direccion}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DireccionCafeteria;