import React, { useEffect, useState } from "react";
import Cabecera from "../../components/home/Cabecera";
import "../../styles/Inicio.css"; // Importar los estilos CSS
import Nav from "../../components/home/Nav";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Grid, IconButton, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import RouteIcon from "@mui/icons-material/Route";
import AddIcon from "@mui/icons-material/Add";
import SliderRutas from "../../components/eventos/SliderRutas";
import { getEventos, getRutas } from "../../App/Services/rutasEventosServices";
import Evento from "../../components/eventos/Evento";
import EventIcon from "@mui/icons-material/Event";
import { useNavigate } from "react-router-dom";

const Eventos: React.FC = () => {
  const navigate = useNavigate();
  const usuario = useAuth();
  const [rutas, setRutas] = useState<any[]>([]);
  const [eventos, setEventos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Declarar el estado de carga
  const [loading2, setLoading2] = useState<boolean>(true); // Declarar el estado de carga

  const handleCrearRuta = () => {
    if (usuario?.user) {
      navigate(`/crear-ruta/${usuario.user.uid}`);
    } else {
      console.error("El usuario no está autenticado");
    }
  };

  useEffect(() => {
    getRutas()
      .then((rutas) => {
        setRutas(rutas);
        setLoading2(false);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading2(false); // Finaliza la carga siempre, incluso si hay errores
      });

    getEventos()
      .then((eventos) => {
        setEventos(eventos);
        setLoading(false);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false); // Finaliza la carga siempre, incluso si hay errores
      });
  }, []);

  return (
    <div className="inicio">
      <Cabecera es_home={false} />
      <div className="contenedor-carrusel">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "1rem" }}
          spacing={1}
        >
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <RouteIcon sx={{ color: "#003147" }} />
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "1.1875rem",
                fontWeight: "600",
                ml: 1, // Añade un margen para separar el ícono del texto
              }}
            >
              Rutas de Café
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="delete"
              size="small"
              sx={{ background: "#DAB587" }}
              onClick={handleCrearRuta}
            >
              <AddIcon fontSize="small" sx={{ color: "#003147" }} />
            </IconButton>
          </Grid>
        </Grid>
        <SliderRutas
          rutas={rutas!}
          loading={loading2} // Pasar el estado de carga al slider
        />
        <Grid item sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "0.90rem",
                fontWeight: "600",
                textAlign: 'center', // Añade un margen para separar el ícono del texto
              }}
            >
              ¡Podés ver tus rutas en tu perfil!
            </Typography>
          </Grid>
      </div>
      <div className="contenedor-paravos">
        <Grid
          container
          justifyContent={"initial"}
          sx={{ mb: "1rem" }}
          spacing={1}
        >
          <Grid item>
            <EventIcon sx={{ color: "#44291E" }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#44291E",
                fontSize: "0.9375rem",
                fontWeight: "600",
              }}
            >
              Eventos de Café
            </Typography>
          </Grid>
        </Grid>
        <div className="background-scroll">
          {loading ? (
            <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton
                  key={index}
                  style={{
                    height: "9.125rem", // Altura fija
                    width: "90%", // Ancho relativo al contenedor
                    margin: "0 auto", // Centrar el esqueleto horizontalmente
                    borderRadius: "1.5625rem",
                    padding: "1rem 0.8rem",
                    marginBottom: "1rem",
                  }}
                />
              ))}
            </SkeletonTheme>
          ) : eventos.length > 0 ? ( // Eliminar llaves adicionales
            eventos.map((evento: any) => (
              <Evento
                key={evento.id}
                id={evento.id}
                nombre={evento.nombre}
                imagen={evento.datos_foto}
                cantidad_estrellas={evento.calificacion}
                direccion={evento.direccion}
                descripcion={evento.descripcion}
                fecha={evento.fecha}
                hora={evento.hora}
              />
            ))
          ) : (
            <Typography>No tienes recomendaciones aún.</Typography>
          )}
        </div>
      </div>
      <Nav select={2} />
    </div>
  );
};

export default Eventos;
