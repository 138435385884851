import React, { useEffect, useState } from "react";
import Cabecera from "../components/home/Cabecera";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Box, Grid, Typography } from "@mui/material";
import { getFavoritos } from "../App/Services/homeService";
import { useAuth } from "../context/AuthContext";
import ComponenteCafeteriaParaVos from "../components/pure/CafeteriaParaVos";
import Nav from "../components/home/Nav";

const VerFavs: React.FC = () => {
  const usuario = useAuth();
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFavoritos = async () => {
      if (usuario?.user?.uid) {  // Verificamos que usuario.user.uid esté definido
        setLoading(true);  // Iniciamos la carga
        try {
          const res = await getFavoritos(usuario.user.uid);
          setFavoritos(res);  // Actualizamos los favoritos con la respuesta
        } catch (err) {
          console.error("Error al obtener favoritos", err);
          setError("Error al obtener favoritos");
        } finally {
          setLoading(false);  // Finalizamos la carga en cualquier caso
        }
      } else {
        console.error("El usuario no está autenticado o no tiene un UID");
        setLoading(false);  // Aseguramos que setLoading se llame si el usuario no está definido
      }
    };
  
    fetchFavoritos();
  }, [usuario?.user?.uid]);

  return (
    <div className="inicio">
      <Cabecera es_home={false} />
      <div className="contenedor-paravos">
        <Grid container justifyContent={"initial"} sx={{ mb: "1rem" }}>
          <Grid item xs={1}>
            <img src="/assets/soloParaVosIcon.svg" alt="Icono Carta" />
          </Grid>
          <Grid item xs={9}>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "0.9375rem",
                fontWeight: "600",
              }}
            >
              Favoritos
            </Typography>
          </Grid>
        </Grid>
        <div className="background-scroll">
          {loading ? (
            <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton
                  key={index}
                  style={{
                    height: "9.125rem", // Altura relativa a la anchura de la ventana
                    width: "90%", // Ancho relativo al contenedor
                    maxWidth: "600px", // Máximo ancho para pantallas grandes
                    minWidth: "200px", // Mínimo ancho para pantallas pequeñas
                    margin: "0 auto", // Centrar el esqueleto horizontalmente
                    borderRadius: "1.5625rem",
                    padding: "1rem 0.8rem",
                    marginBottom: "1rem",
                  }}
                />
              ))}
            </SkeletonTheme>
          ) : favoritos.length > 0 ? (
            favoritos.map((cafeteria: any) => (
              <ComponenteCafeteriaParaVos
                key={cafeteria.id_cafeteria}
                id_cafeteria={cafeteria.id_cafeteria}
                nombre_cafeteria={cafeteria.nombre}
                imagen={cafeteria.imagen}
                estado={cafeteria.estado}
                cantidad_estrellas={cafeteria.calificacion}
                direccion={cafeteria.direccion}
                tags={cafeteria.tags}
              />
            ))
          ) : (
            <Box className="resultado-no-encontrado">
              <img
                src="assets/Resultados/sadFaceIcon.svg"
                alt="Sad Face"
                className="resultado-no-encontrado-img"
              />
              <div className="resultado-no-encontrado-text">
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    color: "#003147",
                    fontSize: "1.125rem",
                    fontWeight: 600,
                  }}
                >
                  No se encontraron cafeterías en favoritos
                </Typography>
              </div>
            </Box>
          )}
        </div>
      </div>
      <Nav select={0} />
    </div>
  );
};

export default VerFavs;
