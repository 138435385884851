import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

interface RatingSizeProps {
    value: number;
}

const RatingSize: React.FC<RatingSizeProps> = ({ value }) => {
    return (
        <Stack spacing={1}>
            <Rating name="size-small" value={value} size="small" readOnly />
        </Stack>
    );
};

export default RatingSize;