import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getForosPorNomYTematica } from "../App/Services/foroService"; // Asegúrate de importar tu servicio de foros
import useForoSearch from "../components/hooks/useForoSearch";
import { Box, Skeleton, Typography } from "@mui/material";
import Nav from "../components/home/Nav";
import TarjetaForo from "../components/foro/TarjetaForo";
import CoffeeMakerOutlinedIcon from "@mui/icons-material/CoffeeMakerOutlined";
import Cabecera from "../components/home/Cabecera";
import Buscador from "../components/home/Buscador";
import "../styles/ResultadosForo.css";
import { logoForoPorId } from "./Foro";


const ResultadosForo: React.FC = () => {
  const location = useLocation();
  const handleSearch = useForoSearch();
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [foros, setForos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { tematica } = location.state || {}; // Extraer el objeto tematica del estado

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("search");

    setLoading(true); // Mostrar loading mientras carga

    if (tematica && tematica.id_tematica) {
      getForosPorNomYTematica(undefined, tematica.id_tematica)
        .then((res) => {
          setForos(res); // Actualiza con los resultados basados en la tematica
        })
        .catch((error) => {
          console.log("Error al buscar foros por temática", error);
          setForos([]); // Maneja el error dejando la lista vacía
        })
        .finally(() => {
          setLoading(false); // Finaliza el loading
        });
    } else if (searchQuery) {
      // Si no hay una tematica pero hay una searchQuery
      setSearchQuery(searchQuery);
      getForosPorNomYTematica(searchQuery)
        .then((res) => {
          setForos(res); // Actualiza con los resultados basados en el nombre
        })
        .catch((error) => {
          console.log("Error al buscar foros por nombre", error);
          setForos([]); 
        })
        .finally(() => {
          setLoading(false); // Finaliza el loading
        });
    } else {
      // Si no hay ni searchQuery ni tematica, limpiar los resultados
      setForos([]);
      setLoading(false);
    }
  }, [location.search, tematica]);

  return (
    <div className="resultado-inicio">
      <Cabecera es_home={false} />
      <Buscador label={"Buscar foros"} onEnter={handleSearch} />

      <Typography
        sx={{
          fontFamily: "Sora",
          color: "#003147",
          fontSize: "1.125rem",
          fontWeight: 600,
          marginLeft: "3rem",
          marginTop: "1rem",
          width: "20.063rem",
        }}
      >
        {tematica
          ? `Resultados para la temática "${tematica.nombre}"`
          : `Resultados sobre "${searchQuery}"`}
      </Typography>

      {loading ? (
        <Box className="resultado-loading">
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              sx={{
                height: "9.125rem",
                borderRadius: "1.5625rem",
                marginBottom: "1rem",
                marginLeft: "2.063rem",
                marginRight: "2.313rem",
              }}
            >
              <Skeleton
                height="100%"
                width="100%"
                style={{
                  borderRadius: "1.5625rem",
                }}
              />
            </Box>
          ))}
        </Box>
      ) : foros.length > 0 ? (
        <div className="resultado-foro">
          {foros.slice(0, 5).map((foro: any) => (
            <TarjetaForo
              id={foro.id_foro}
              titulo={foro.nombre}
              descripcion={foro.descripcion}
              colorTitulo={"#44291E"}
              id_tematica={foro.id_tematica}
              icono={logoForoPorId.get(foro.id_tematica)}
            />
          ))}
        </div>
      ) : (
        <Box className="resultado-no-encontrado">
          <img
            src="assets/Resultados/sadFaceIcon.svg"
            alt="Sad Face"
            className="resultado-no-encontrado-img"
          />
          <div className="resultado-no-encontrado-text">
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "1.125rem",
                fontWeight: 600,
              }}
            >
              No se encontraron foros que coincidan
            </Typography>
          </div>
        </Box>
      )}
      <Nav select={0} />
    </div>
  );
};

export default ResultadosForo;
