import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComponenteCafeteria from "../pure/Cafeteria";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';

interface SwipeToSlideProps {
    imagenes: any[],
}
  
export default function SwipeToSlideImg(props: SwipeToSlideProps) {
  const settings = {
    className: "center",
    infinite: props.imagenes.length > 1, // Desactiva el modo infinito si hay solo 1 imagen
    centerPadding: "50px",
    slidesToShow: 1, // Mostrar solo una imagen a la vez
    swipeToSlide: true,
    arrows: props.imagenes.length > 1, // Solo mostrar las flechas si hay más de una imagen
  };

    return (
        <div className="slider-container">
          <div>
            <Slider {...settings}>
                {props.imagenes.map((imagen: any) => (
                    <Card 
                      sx={{ 
                        width: "18.375rem",
                        height: "11.8125rem", 
                        borderRadius: "10px"}}>
                      <CardMedia
                        component="img"
                        width= "100%"
                        height= "100%"
                        image={`data:image/bmp;base64,${imagen.datos}`}
                      />
                    </Card>
                ))}
            </Slider>
          </div>
        </div>
      );
  };
