import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton, Input, Tooltip, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/LoginPage.css"; // Importar los estilos CSS
import { useAuth } from "../context/AuthContext";
import InfoIcon from '@mui/icons-material/Info';
import { validarContrasenia, validarCorreo } from "../components/common/UtilidadesForm/ValidacionesCampos";
import { useLocation } from "react-router-dom";


const RestablecerPage: React.FC = () => {
  const auth = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [contrasenia, setContrasenia] = useState<string | undefined>(undefined);
  const [confirmContrasenia, setConfirmContrasenia] = useState<string | undefined>(undefined);
  const [respuesta, setRespuesta] = useState<string | undefined>(undefined);
  const [contraseniasValidas, setContraseniasValidas] = useState<boolean | undefined >(undefined);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery();
  let codigo = query.get("oobCode");


  const handleRestablecer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (codigo != null ) {
        auth.actualizarContrasenia(codigo, contrasenia!)
            .then(() =>
                 window.location.href = '/login')
            .catch((error) => setRespuesta("Error al restablecer su contraseña"))
        }
    };

    useEffect(() => {
        validarContrasenias();
    }, [contrasenia, confirmContrasenia]);

    const validarContrasenias = () => {
        if (contrasenia != "" && confirmContrasenia != "" && contrasenia != undefined && confirmContrasenia != undefined) {
            if ( contrasenia === confirmContrasenia && validarContrasenia(contrasenia) && validarContrasenia(confirmContrasenia)) {
            setContraseniasValidas(true);
            } else {
            setContraseniasValidas(false);
            }
        } else setContraseniasValidas(undefined)
        };

  return (
    <div className="background">
      <div className="textoBienvenida">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="bienvenido"
          sx={{ fontFamily: "Lora, serif" }} // Asignar la fuente Lora
        >
          Ya casi...
        </Typography>
      </div>
      <div className="rectanguloAzulLogin">
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className="labelIniciarSesion"
        >
          Restablecer contraseña
        </Typography>
        <Typography
          gutterBottom
          margin={'0.5rem'}
          className="labelRecuperar"
        >
          Ingrese su nueva contraseña
        </Typography>
        <Box component="form" className="form" noValidate autoComplete="off" sx={{ m: 1 }}>
        <FormControl className="text" variant="standard">
            <InputLabel
            htmlFor="standard-adornment-password"
            sx={{
                color: "#fff", // Color del label por defecto
                "&.Mui-focused": {
                color: "#fff", // Color del label cuando el TextField está enfocado
                },
            }}
            >
            Contraseña
            </InputLabel>
            <Input
            required
            id="standard-adornment-password"
            placeholder="***********"
            type={showPassword ? "text" : "password"}
            error={contraseniasValidas === undefined ? false : !contraseniasValidas}
            endAdornment={
                <InputAdornment position="end">
                <Tooltip title="La contraseña debe poseer como mínimo 6 caracteres">
                    <IconButton
                    sx={{
                    color: "white", // Color del IconButton
                    opacity: 0.5,
                    }}>
                    <InfoIcon />
                    </IconButton>
                </Tooltip>
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{
                    color: "white", // Color del IconButton
                    opacity: 0.5,
                    }}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            }
            sx={{
                input: {
                color: "white", // Color del texto del input
                },
                "&:before": {
                borderBottomColor: "white", // Color de la línea inferior antes del foco
                },
                "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "white", // Color de la línea inferior en hover
                },
                "& .MuiInputBase-input::placeholder": {
                color: "white", // Color del placeholder
                opacity: 0.5, // Opacidad del placeholder
                },
            }}
            onChange={(e) => setContrasenia(e.target.value)}
            />
        </FormControl>
        <FormControl className="text" variant="standard">
            <InputLabel
            htmlFor="standard-adornment-password"
            sx={{
                color: "#fff", // Color del label por defecto
                "&.Mui-focused": {
                color: "#fff", // Color del label cuando el TextField está enfocado
                },
            }}
            >
            Confirmar contraseña
            </InputLabel>
            <Input
            required
            id="standard-adornment-password"
            placeholder="***********"
            type={showPassword ? "text" : "password"}
            error={contraseniasValidas === undefined ? false : !contraseniasValidas}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{
                    color: "white", // Color del IconButton
                    opacity: 0.5,
                    }}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                </InputAdornment>
            }
            sx={{
                input: {
                color: "white", // Color del texto del input
                },
                "&:before": {
                borderBottomColor: "white", // Color de la línea inferior antes del foco
                },
                "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "white", // Color de la línea inferior en hover
                },
                "& .MuiInputBase-input::placeholder": {
                color: "white", // Color del placeholder
                opacity: 0.5, // Opacidad del placeholder
                },
            }}
            onChange={(e) => setConfirmContrasenia(e.target.value)}
            />
            </FormControl>
          {respuesta != undefined && ( 
            <Typography
            color={respuesta === "Error al restablecer su contraseña" ? "#F00" : "#FFF"}
            sx={{ fontFamily: "Sora"}}

            >
              {respuesta}
            </Typography>
          )}

         
          <button disabled={!contrasenia || !confirmContrasenia || !contraseniasValidas} onClick={handleRestablecer} className="custom-button">Restablecer contraseña</button>
        </Box>
      </div>
    </div>
  );
};

export default RestablecerPage;
