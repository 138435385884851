import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import RatingSize from "./Estrellas";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { marcarUtilReseña } from "../../App/Services/cafeteriaService";
import { avatarPorId } from "../common/const";
import { calcularDiferenciaDias } from "../foro/posteo/Posteo";

interface ComponenteReseñaProps {
  listaReseñas: any[] | undefined;
}

const ComponenteReseña: React.FC<ComponenteReseñaProps> = ({
  listaReseñas,
}) => {

  const handleUtil = (id: number, util: boolean) => {
    marcarUtilReseña(id, util)
      .then((res: any) => {})
      .catch((error: any) => {});
  };

  return (
    <>
      {listaReseñas ? (
        listaReseñas.length != 0 ? (
          listaReseñas.map((reseña: any) => (
            <Grid
              container
              sx={{
                padding: "1rem",
                width: "100%",
                height: "auto",
                borderRadius: "1.0625rem",
                background: "#F5F5F5",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                marginTop: "1rem",
              }}
              spacing={1}
              alignItems={"center"}
            >
              <Grid item>
                <img src={avatarPorId.get(reseña.id_avatar)} alt={reseña.nombre_reviewer} width={19} height={19}/>

              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontSize: "0.625rem",
                        fontWeight: "600",
                      }}
                    >
                      {reseña.nombre_reviewer}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} alignItems={"end"} spacing={1}>
                    <Grid item>
                      <RatingSize value={reseña.puntaje} />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "rgba(0, 0, 0, 0.65)",
                          fontFamily: "Sora",
                          fontSize: "0.4375rem",
                          fontWeight: "600",
                        }}
                      >
                        {calcularDiferenciaDias(reseña.fecha)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    fontSize: "0.8375rem",
                    fontWeight: "400",
                  }}
                >
                  {reseña.descripcion}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    height: "0.9375rem",
                    borderRadius: "0.8125rem",
                    background: "#D9D9D9",
                    color: "#000",
                    fontFamily: "Sora",
                    fontSize: "0.4375rem",
                    textTransform: "none",
                  }}
                  startIcon={
                    <ThumbUpOffAltOutlinedIcon
                      sx={{ fontSize: "0.62813rem !important" }}
                    />
                  }
                  onClick={() => handleUtil(reseña.id_review, true)}
                >
                  Útil
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{
                    height: "0.9375rem",
                    borderRadius: "0.8125rem",
                    background: "#D9D9D9",
                    color: "#000",
                  }}
                  onClick={() => handleUtil(reseña.id_review, false)}
                >
                  <ThumbDownAltOutlinedIcon sx={{ fontSize: "0.62813rem" }} />
                </IconButton>
              </Grid>
            </Grid>
          ))
        ) : (
          <>
            <Typography
              sx={{
                color: "#44291E",
                fontFamily: "Sora",
                fontSize: "1.125rem",
                fontWeight: "600",
                mt:'1rem'
              }}
            >
              Aún no tiene reseñeas, sé el primero en contar tu experiencia
            </Typography>
          </>
        )
      ) : (
        <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton
              key={index}
              style={{
                height: "6.75rem", // Altura fija
                width: "100%",
                margin: "0 auto", // Centrar el esqueleto horizontalmente
                borderRadius: "1.0625rem",
                marginBottom: "1rem",
              }}
            />
          ))}
        </SkeletonTheme>
      )}
    </>
  );
};

export default ComponenteReseña;
