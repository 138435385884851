import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Promocion from "./promocion";

interface SwipeToSlideProps {
  promociones: any[];
  editar?: boolean; // Prop to enable edit mode
}

export default function SwipeToSlidePromociones(props: SwipeToSlideProps) {
  const settings = {
    className: "center",
    infinite: props.promociones.length > 1,
    centerPadding: "50px",
    slidesToShow: 2,
    swipeToSlide: true,
    arrows: props.promociones.length > 1,
  };
  return (
    <div className="slider-container">
      <div>
        <Slider {...settings}>
          {props?.promociones?.map((promocion) => (
            <Promocion
              key={promocion.nombre}
              nombre={promocion.nombre}
              descripcion={promocion.descripcion}
              condiciones={promocion.condiciones}
              fecha_inicio_vig={promocion.fecha_inicio_vig}
              fecha_fin_vig={promocion.fecha_fin_vig}
              mostrarLapiz={props.editar}
              id_promocion={promocion.id_promocion}
              activa={promocion.activa}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}
