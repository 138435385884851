import React from "react";
import { Card, CardActionArea, Grid, Typography, IconButton } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react"; // Importar para las animaciones

// Animación ajustada para el temblor (menos intensa y más lenta)
const shakeAnimation = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-0.5deg); }
  20% { transform: translate(-2px, 0px) rotate(0.5deg); }
  30% { transform: translate(2px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(0.5deg); }
  50% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  60% { transform: translate(-2px, 0px) rotate(0deg); }
  70% { transform: translate(2px, 0px) rotate(-0.5deg); }
  80% { transform: translate(1px, 1px) rotate(0.5deg); }
  90% { transform: translate(0px, 0px) rotate(0deg); }
  100% { transform: translate(1px, -1px) rotate(-0.5deg); }
`;

export interface IRuta {
  id: number;
  nombre: string;
  cantidad_cafeterias: number;
  puntaje: number;
  descripcion: string;
  eliminarActivo?: boolean; // Prop para controlar la visualización del botón de eliminar
  onEliminar?: (id: number) => void; // Callback para manejar la eliminación
}

const Ruta: React.FC<IRuta> = ({
  id,
  nombre,
  cantidad_cafeterias,
  puntaje,
  descripcion,
  eliminarActivo = false,
  onEliminar,
}) => {
  const navigate = useNavigate();

  const handleConsultarRuta = (id_ruta: number) => {
    navigate(`/ruta-del-cafe/${id_ruta}`);
  };

  // Ajustar el tamaño de la fuente en función de la longitud del nombre
  const fontSize = nombre.length > 26 ? "0.875rem" : "1.125rem"; // Si el nombre es muy largo, reducir el tamaño de la fuente

  return (
    <div style={{ position: "relative", width: "7.775rem", overflow: "visible" }}>
      {eliminarActivo && (
        <IconButton
          aria-label="eliminar"
          sx={{
            borderRadius: "0.375rem", // Borde redondeado
            background: "lightgrey", // Color gris claro
            width: "1.5rem", // Ajustar el tamaño del botón
            height: "1.5rem", // Tamaño cuadrado
            position: "absolute", // Posición absoluta para colocarlo sobre el componente
            top: "0.5rem", // Posicionar sobre la tarjeta
            right: "-0.90rem", // Posicionar a la derecha de la tarjeta
            zIndex: 999, // Asegura que esté por encima de la tarjeta y otros elementos
            color: "#000", // Color del ícono
            animation: `${shakeAnimation} 1s ease infinite`, // Hacer que el temblor sea más lento y menos intenso
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Agregar una sombra para darle un poco de profundidad
            transition: "transform 0.2s ease", // Transición suave
          }}
          onClick={() => onEliminar && onEliminar(id)}
        >
          <RemoveIcon sx={{ color: "black", fontSize: "1.25rem" }} />
        </IconButton>
      )}

      <Card
        key={id}
        sx={{
          width: "7.775rem",
          height: "14.125rem",
          backgroundColor: "#003147",
          borderRadius: "1.5625rem",
          padding: "1rem 0.8rem",
          display: "flex",
          flexDirection: "column", // Alinea los elementos de arriba hacia abajo
          lineHeight: "normal",
          overflow: "visible"
        }}
      >
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Mantiene elementos distribuidos entre la parte superior y la inferior
          }}
          onClick={() => handleConsultarRuta(id)}
        >
          {/* Nombre de la ruta */}
          <Typography
            sx={{
              fontFamily: "Higuen",
              color: "#FFF",
              width: "7.438rem",
              height: "3.75rem",
              fontSize: fontSize, // Tamaño dinámico basado en la longitud del nombre
              textOverflow: "ellipsis", // Agrega puntos suspensivos si el texto es muy largo
              display: "-webkit-box",
              WebkitLineClamp: 2, // Limita el número de líneas
              WebkitBoxOrient: "vertical",
              paddingTop: "1.063rem",
              marginBottom: "0.25rem",
              overflow: "hidden", // Oculta desbordamientos
            }}
          >
            {nombre}
          </Typography>

          {/* Descripción */}
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#FFF",
              fontSize: "0.625rem",
              fontWeight: 300,
              textOverflow: "ellipsis", // Agrega puntos suspensivos si el texto es muy largo
              display: "-webkit-box",
              WebkitLineClamp: 6, // Limita el número de líneas de la descripción
              WebkitBoxOrient: "vertical",
              height: "5.813rem", // Altura fija para la descripción
              width: "7.625rem",
              overflow: "hidden", // Oculta desbordamientos
            }}
          >
            {descripcion}
          </Typography>

          {/* Cantidad de cafeterías y puntaje */}
          <Grid container justifyContent="space-between" alignItems="center">
            {/* Cantidad de cafeterías */}
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#DAB587",
                  fontSize: "1.3125rem",
                }}
              >
                {cantidad_cafeterias}
              </Typography>
              <img
                src="/assets/coffeCupIcon.svg"
                alt="Coffee Cup"
                style={{
                  width: "1.64063rem",
                  height: "1.2305rem",
                  paddingBottom: "0.313rem",
                }}
              />
            </Grid>

            {/* Puntaje */}
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#DAB587",
                  fontSize: "1.3125rem",
                }}
              >
                {puntaje}
              </Typography>
              <StarRoundedIcon
                sx={{ color: "#DAB587", paddingBottom: "0.313rem" }}
              />
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default Ruta;
