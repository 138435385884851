import React, { useEffect, useState } from "react";
import "../../styles/PromocionesCafeteria.css";
import NavCafeteria from "../../components/home/NavCafeteria";
import { Box, Fab, Grid, IconButton, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getCafeteria } from "../../App/Services/cafeteriaService";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
import PromocionesCafeteria from "../../components/cafeteria/promociones/PromocionesDelHomeCafeteria";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import { useMediaQuery } from "@mui/material";
import { getAllPromocionesActivas, getAllPromocionesInactivas } from "../../App/Services/Cafeteria/promocionesServices";
import AddIcon from "@mui/icons-material/Add";

const PromocionesHomeCafeteria: React.FC = () => {
  const usuario = useAuth();
  const [cafeteria, setCafeteria] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPromociones, setLoadingPromociones] = useState<boolean>(true);
  const [loadingPromocionesInactivas, setLoadingPromocionesInactivas] = useState<boolean>(true);
  const [editar, setEditar] = useState<boolean>(false); // Estado para habilitar edición
  const [promociones, setPromociones] = useState<any[]>([]); 
  const [promocionesInactivas, setPromocionesInactivas] = useState<any[]>([]); // Inicializa como arreglo vacío
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchDatosCafeteria = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosCafeteria = await getCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setCafeteria(datosCafeteria);
        } catch (error) {
          console.error("Error al obtener datos de la cafetería:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDatosCafeteria();
  }, [usuario]);

  useEffect(() => {
    const fetchPromociones = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );

          // Llama al endpoint para obtener todas las promociones activas
          const promociones = await getAllPromocionesActivas(
            cafeteriaAsociada.id_cafeteria
          );
          console.log("PROMOCIONES: ", promociones);

          setPromociones(promociones); // Actualiza solo el estado de promociones
        } catch (error) {
          console.error("Error al obtener promociones:", error);
        } finally {
          setLoadingPromociones(false); // Indica que las promociones ya se han cargado
        }
      }
    };
    fetchPromociones();
  }, [usuario]);

  useEffect(() => {
    const fetchPromocionesInactivas = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );

          // Llama al endpoint para obtener las promociones inactivas
          const inactivas = await getAllPromocionesInactivas(
            cafeteriaAsociada.id_cafeteria
          );
          console.log("PROMOCIONES INACTIVAS: ", inactivas);

          // Actualiza el estado con las promociones inactivas obtenidas
          setPromocionesInactivas(inactivas);
        } catch (error) {
          console.error("Error al obtener promociones inactivas:", error);
        }
        finally {
          setLoadingPromocionesInactivas(false); // Indica que las promociones inactivas ya se han cargado
        }
      }
    };
    fetchPromocionesInactivas();
  }, [usuario]);

  return (
    <Box className="inicio-promociones-cafeteria">
      <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />
      {/* Sección de Promociones */}
      <Box
        className="promociones-cafeteria-texto"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Asegura que los elementos estén separados
          margin: "1rem 2rem 0 2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/homeCafeteria/promocionesActualesIcon.svg"
            alt="Icono de promociones actuales"
            style={{ width: "1.75rem", height: "1.75rem" }}
          />
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Sora",
              color: "#44291E",
              fontSize: "0.9375rem",
              fontWeight: "600",
              paddingLeft: "0.5rem",
            }}
          >
            Promociones Activas
          </Typography>
        </Box>

        <IconButton
          aria-label="editan"
          onClick={() => setEditar(!editar)} // Cambia el estado de edición
          sx={{
            borderRadius: "0.4375rem",
            background: editar ? "#003147" : "#44291E",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            color: "#FFF", // Color del ícono
            "&:hover": {
              background: editar ? "#003147" : "#44291E", // Hover dinámico
            },
          }}
        >
          <img
            src={"/assets/edit.svg"} // Ícono dinámico
            alt="Editar promociones"
          />
        </IconButton>
      </Box>
      {loadingPromociones ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Espaciado uniforme entre los skeletons
            padding: "0 2rem",
            gap: "1rem", // Espaciado entre skeletons
          }}
        >
          {[...Array(isMobile ? 2 : 3)].map((_, index) => (
            <Skeleton
              key={index}
              height="10.125rem"
              width="9.375rem"
              style={{ borderRadius: "1rem" }}
            />
          ))}
        </Box>
      ) : promociones.length > 0 ? (
        <PromocionesCafeteria
          promociones={promociones}
          editar={editar} // Pasar el estado de edición
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 2rem 0 2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#003147",
              fontSize: "0.875rem",
              fontWeight: "600",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No hay promociones activas disponibles en este momento.
          </Typography>
        </Box>
      )}

      {/* Sección de Promociones Inactivas */}

      <Box
        className="reseñas-cafeteria"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Asegura que los elementos estén separados
          margin: "1rem 2rem 0 2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/homeCafeteria/promocionesInactivasIcon.svg"
            alt="Reseñas"
            style={{
              width: "1.75rem",
              height: "1.75rem",
              marginRight: "0.5rem",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Sora",
              color: "#44291E",
              fontSize: "0.9375rem",
              fontWeight: "600",
            }}
          >
            Promociones Inactivas
          </Typography>
        </Box>
      </Box>

      {loadingPromocionesInactivas ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Espaciado uniforme
            padding: "0 2rem",
            gap: "1rem", // Espaciado entre skeletons
          }}
        >
          {[...Array(isMobile ? 2 : 3)].map((_, index) => (
            <Skeleton
              key={index}
              height="5.0625rem"
              width="8.3125rem"
              style={{ borderRadius: "1.0625rem" }}
            />
          ))}
        </Box>
      ) : promocionesInactivas.length > 0 ? (
        <PromocionesCafeteria
          promociones={promocionesInactivas} // Pasa las promociones inactivas
          editar={editar} // Estado de edición
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 2rem 0 2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#003147",
              fontSize: "0.875rem",
              fontWeight: "600",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No hay promociones inactivas en este momento.
          </Typography>
        </Box>
      )}

      {/* Sección de Mensaje Consejo */}
      <Box
        className="mensaje-consejo"
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "2rem auto",
        }}
      >
        <img
          src="/assets/homeCafeteria/lamparitaPromocionesCafeteriaIcon.svg"
          alt="Icono de eventos"
          style={{
            width: "1.75rem",
            height: "1.75rem",
            marginRight: "0.5rem",
            marginLeft: "1rem",
            paddingBottom: "5.5rem",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Sora",
            color: "#000",
            fontSize: "0.8125rem",
            fontWeight: "300",
            lineHeight: "normal",
            width: "16.375rem",
            height: "7.625rem",
          }}
        >
          Las <strong>promociones inactivas</strong> pueden estar{" "}
          <strong>vencidas</strong> o <strong>desactivadas</strong>. Cuando
          creas una <u>nueva promoción</u> se encontrará inactiva. Para activar
          una promoción, lo podes hacer desde la opción{" "}
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: "1rem",
              height: "1rem",
              borderRadius: "0.4375rem",
              backgroundColor: "#44291E",
              verticalAlign: "middle", // Alineación con el texto
              marginLeft: "0.2rem", // Espaciado a la izquierda
              marginRight: "0.2rem", // Espaciado a la derecha
            }}
          >
            <img
              src="/assets/edit.svg"
              alt="editar"
              style={{ width: "0.63rem", height: "0.63rem" }}
            />
          </div>
          , en la opción “activar” o ingresando fechas válidas.
        </Typography>
      </Box>
      <Fab
        variant="extended"
        aria-label="add"
        onClick={() => navigate("/registrar-promocion")}
        sx={{
          position: "fixed",
          bottom: "5rem", // Ajusta según la separación que desees del borde inferior
          right: "2rem", // Ajusta según la separación que desees del borde derecho
          zIndex: 1000, // Asegura que el botón esté por encima de otros elementos
          color: "#003147",
          backgroundColor: "#FFF", // Color personalizado
          fontFamily: "Sora",
          fontSize: "0.75rem",
          textTransform: "none",
          fontWeight: 700,
        }}
      >
        Nueva Promoción <AddIcon />
      </Fab>
      <NavCafeteria select={4} />
    </Box>
  );
};

export default PromocionesHomeCafeteria;
