import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComponenteCafeteria from "../pure/Cafeteria";
import { Grid } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { useState, useEffect } from "react";

interface Cafeteria {
  id_cafeteria: number;
  nombre: string;
  imagen: string;
  estado: string;
  calificacion: number;
  direccion: string;
}

interface SwipeToSlideProps {
  cafeterias: Cafeteria[];
  loading: boolean; // Nuevo prop para controlar el estado de carga
}

export default function SwipeToSlideCafeterias(props: SwipeToSlideProps) {
  // Calcular slidesToShow inicial basado en el ancho de la pantalla
  const getInitialSlidesToShow = () => {
    const width = window.innerWidth;
    if (width >= 1440) return 4;
    if (width >= 1024) return 3;
    if (width >= 768) return 2;
    return 1;
  };

  const [slidesToShow, setSlidesToShow] = useState(getInitialSlidesToShow); // Inicializa con el cálculo dinámico

  const settings = {
    className: "center",
    infinite: props.cafeterias.length > 1,
    centerPadding: "50px",
    swipeToSlide: true,
    arrows: props.cafeterias.length > 1,
    slidesToShow: slidesToShow, // Se actualizará según el tamaño de la pantalla
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Función para actualizar slidesToShow según el ancho de la ventana
  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width >= 1440) {
      setSlidesToShow(4);
    } else if (width >= 1024) {
      setSlidesToShow(3);
    } else if (width >= 767) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    // Actualiza el número de slides cuando cambia el tamaño de la pantalla
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  return (
    <div className="slider-container">
      <div>
        <Slider {...settings}>
          {props.loading
            ? Array.from({ length: 2 }).map((_, index) => (
                <Grid item key={index}>
                  <Skeleton
                    height={200}
                    width={150}
                    style={{
                      borderRadius: "1.5625rem",
                      margin: "0 1rem",
                    }}
                  />
                </Grid>
              ))
            : props.cafeterias.map((cafeteria: Cafeteria) => (
                <ComponenteCafeteria
                  key={cafeteria.id_cafeteria}
                  id_cafeteria={cafeteria.id_cafeteria}
                  nombre_cafeteria={cafeteria.nombre}
                  imagen={cafeteria.imagen}
                  estado={cafeteria.estado}
                  cantidad_estrellas={cafeteria.calificacion}
                  direccion={cafeteria.direccion}
                />
              ))}
        </Slider>
      </div>
    </div>
  );
}
