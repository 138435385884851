import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cabecera from "../../components/home/Cabecera";
import SwipeToSlideImg from "../../components/home/SliderImagenes";
import SwipeToSlide, { Tag } from "../../components/home/SliderTags";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import InfoCafeteria from "../../components/cafeteria/InfoCafeteria";
import "../../styles/consultarCafeteria.css";
import Nav from "../../components/home/Nav";
import Contacto from "../../components/cafeteria/Contacto";
import Carta from "../../components/cafeteria/Carta";
import {
  esFavorita,
  getCafeteria,
  marcarFavorita,
} from "../../App/Services/cafeteriaService";
import Reseña_Favorito from "../../components/cafeteria/Raseña-Favorito";
import { useAuth } from "../../context/AuthContext";
import Promociones from "../../components/cafeteria/promociones/PromocionesCafeteria";
import { IPromocion } from "../../components/cafeteria/promociones/promocion";

export interface IDatosCafeteria {
  id_cafeteria: number;
  nombre: string;
  direccion: string;
  descripcion: string;
  puntaje: number;
  estado: string;
  horarios: any[];
  tags: any[];
  carta: string;
  promociones: any[];
  datos_contacto: any;
  imagenes: any[];
}

export default function ConsultarCafeteria() {
  const { id_cafeteria } = useParams();
  const [cafeteria, setCafeteria] = useState<IDatosCafeteria | null>(null);
  const [favorita, setFavorita] = useState<boolean | undefined>(undefined);
  const usuario = useAuth();
  const navigate = useNavigate();

  const fetchCafeteriaData = async () => {
    if (id_cafeteria && usuario.user) {
      try {
        const id_cafeteria_number = parseInt(id_cafeteria, 10);
        const cafeteriaData = await getCafeteria(id_cafeteria_number);

        // Filtrar solo promociones activas
        const promocionesActivas = cafeteriaData.promociones.filter(
          (promo: IPromocion) => promo.activa === true
        );
        setCafeteria({ ...cafeteriaData, promociones: promocionesActivas });

        console.log("Cafetería consultada:", cafeteriaData);

        const favoritaData = await esFavorita(
          id_cafeteria_number,
          usuario.user.uid
        );
        setFavorita(favoritaData.esFavorito);
      } catch (error) {
        console.error("Error al consultar cafetería o favoritos:", error);
      }
    }
  };

  useEffect(() => {
    fetchCafeteriaData();
  }, [id_cafeteria, usuario.user]);

  useEffect(() => {
    if (id_cafeteria && favorita !== undefined && usuario.user) {
      const id_cafeteria_number = parseInt(id_cafeteria, 10);
      marcarFavorita(id_cafeteria_number, usuario.user.uid, favorita)
        .then((res) => {})
        .catch((error) => console.log("Error al marcar como favorita:", error));
    }
  }, [favorita, usuario.user]);

  // Pasar el objeto tag completo al hacer clic en el slider tags
  const handleTagClick = (tag: any) => {
    if (tag) {
      navigate(`/resultados`, { state: { tag } });
    }
  };

  return (
    <div className="container">
      <Cabecera es_home={false} />
      <div className="content">
        {/* Carrusel de imágenes */}
        {cafeteria?.imagenes ? (
          <div className="contenedor-carrusel-consultar-caf">
            <SwipeToSlideImg imagenes={cafeteria.imagenes} />
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="22.875rem"
            height="11.813rem"
            sx={{ borderRadius: "8px", margin: "0 auto" }}
          />
        )}
        {/* Información de la cafetería */}
        {cafeteria ? (
          <InfoCafeteria cafeteria={cafeteria} habilitado_reseñas={true} />
        ) : (
          <Skeleton
            variant="text"
            width="22.875rem"
            height="5.813rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}
        {/* Carrusel de tags */}
        {cafeteria?.tags ? (
          <div className="contenedor-carrusel-tags">
            <SwipeToSlide tags={cafeteria.tags} onClick={handleTagClick} />
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width="22.875rem"
            height="1.594rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}
        {/* Descripción */}
        {cafeteria?.descripcion ? (
          <Box
            sx={{
              typography: "body1",
              mx: "2rem",
              marginTop: "1rem",
              fontFamily: "Sora",
              textAlign: "justify", // Justifica el texto
            }}
          >
            {cafeteria.descripcion}
          </Box>
        ) : (
          <Skeleton
            variant="text"
            width="18.25rem"
            height="6.25rem"
            sx={{ borderRadius: "5px", margin: "0 auto" }}
          />
        )}
        {/* /assets/consultarCafeteria/promocionesIcon.svg */}
        {/* Promociones */}
        {cafeteria?.promociones && cafeteria.promociones.length > 0 ? (
          <Promociones promociones={cafeteria.promociones} />
        ) : (
          <>
            <div style={{}}>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "2rem",
                  alignItems: "center",
                }}
              >
                <Box sx={{ paddingRight: "5px" }}>
                  <img
                    src="/assets/consultarCafeteria/promocionesIcon.svg"
                    alt="promocionesIcon"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Higuen",
                      color: "#003147",
                      fontSize: "1.25rem",
                      fontWeight: "400",
                      marginBottom: "0.625rem",
                    }}
                  >
                    Promociones
                  </Typography>
                </Box>
              </Box>
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1rem 2rem 0 2rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                No hay promociones activas disponibles en este momento.
              </Typography>
            </Box>
          </>
        )}
        {/* Contacto */}
        {cafeteria?.datos_contacto ? (
          <Contacto contacto={cafeteria.datos_contacto} />
        ) : (
          <Skeleton
            variant="rectangular"
            width="12.5rem"
            height="7.25rem"
            sx={{ borderRadius: "5px", margin: "2rem 0", marginLeft: "2rem" }}
          />
        )}
        {/* Carta */}
        {cafeteria?.carta != null && <Carta link={cafeteria.carta} />}
      </div>

      {/* Reseña y favorito */}
      {favorita !== null && (
        <div>
          <Reseña_Favorito
            id_cafeteria={cafeteria?.id_cafeteria!}
            favorito={favorita}
            setFavorito={setFavorita}
          />
        </div>
      )}
      <Nav select={0} />
    </div>
  );
}
