import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton, Input, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/SignUpPage.css"; // Importar los estilos CSS
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { validarContrasenia, validarCorreo} from "../components/common/UtilidadesForm/ValidacionesCampos";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';


const SignupPage: React.FC = () => {
  const auth = useAuth()
  const [showPassword, setShowPassword] = React.useState(false);
  const [nombre, setNombre] = useState<string | undefined>(undefined);
  const [apellido, setApellido] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [contrasenia, setContrasenia] = useState<string | undefined>(undefined);
  const [confirmContrasenia, setConfirmContrasenia] = useState<string | undefined>(undefined);
  const [contraseniasValidas, setContraseniasValidas] = useState<boolean | undefined >(undefined);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [emailExistente, setEmailExistente] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    validarContrasenias();
  }, [contrasenia, confirmContrasenia]);

  useEffect(() => {
    if (email != undefined && email != "") {
      setErrorEmail(!validarCorreo(email!));
    }
    else setErrorEmail(false)
    setEmailExistente(false);
    }
    ,[email]);

  const validarContrasenias = () => {
    if (contrasenia != "" && confirmContrasenia != "" && contrasenia != undefined && confirmContrasenia != undefined) {
      if ( contrasenia === confirmContrasenia && validarContrasenia(contrasenia) && validarContrasenia(confirmContrasenia)) {
        setContraseniasValidas(true);
      } else {
        setContraseniasValidas(false);
      }
    } else setContraseniasValidas(undefined)
  };

  const handleCrearCuenta = ( 
    e : React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    auth.registrar(email!, contrasenia!, nombre!, apellido!, setEmailExistente)
  };

  return (
    <div className="background">
      <div className="textoBienvenida">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="hola"
          sx={{
            fontFamily: "Higuen",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          Hola,
        </Typography>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="bienvenido"
          sx={{
            fontFamily: "Higuen",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          Vamos a empezar!
        </Typography>
      </div>

      <div className="rectanguloAzulLogin">
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className="labelRegistrate"
          sx={{fontWeight: "700", fontFamily: "Sora", }}
        >
          Regístrate
        </Typography>
        <div className="background-scroll">
          <Box
            component="form"
            className="form"
            noValidate
            autoComplete="off"
            sx={{ m: 1 }}
          >
            <TextField
              required
              className="text"
              id="standard-basic"
              label="Nombre"
              variant="standard"
              placeholder="Maestro"
              size={"small"}
              sx={{
                //color de la línea inferior del "Email"
                "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
                  {
                    borderBottomColor: "white", // Color de la línea por defecto
                  },
                "& .MuiInputLabel-root": {
                  color: "#fff", // Color del label por defecto
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#fff", // Color del label cuando el TextField está enfocado
                },
                input: {
                  color: "white", // Color del texto del input
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Color del placeholder
                  opacity: 0.5, // Opacidad del placeholder
                },
              }}
              onChange={(e) => setNombre(e.target.value)}
            />
            <TextField
              required
              className="text"
              id="standard-basic"
              label="Apellido"
              variant="standard"
              placeholder="De La Taza"
              size={"small"}
              sx={{
                //color de la línea inferior del "Email"
                "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
                  {
                    borderBottomColor: "white", // Color de la línea por defecto
                  },
                "& .MuiInputLabel-root": {
                  color: "#fff", // Color del label por defecto
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#fff", // Color del label cuando el TextField está enfocado
                },
                input: {
                  color: "white", // Color del texto del input
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Color del placeholder
                  opacity: 0.5, // Opacidad del placeholder
                },
              }}
              onChange={(e) => setApellido(e.target.value)}
            />
            <TextField
              required
              className="text"
              id="standard-basic"
              label="Email"
              variant="standard"
              placeholder="ejemplo@gmail.com"
              size={"small"}
              error={errorEmail}
              sx={{
                //color de la línea inferior del "Email"
                "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
                  {
                    borderBottomColor: "white", // Color de la línea por defecto
                  },
                "& .MuiInputLabel-root": {
                  color: "#fff", // Color del label por defecto
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#fff", // Color del label cuando el TextField está enfocado
                },
                input: {
                  color: "white", // Color del texto del input
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white", // Color del placeholder
                  opacity: 0.5, // Opacidad del placeholder
                },
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl className="text" variant="standard">
              <InputLabel
                required
                htmlFor="standard-adornment-password"
                sx={{
                  color: "#fff", // Color del label por defecto
                  "&.Mui-focused": {
                    color: "#fff", // Color del label cuando el TextField está enfocado
                  },
                }}
              >
                Contraseña
              </InputLabel>
              <Input
                required
                id="standard-adornment-password"
                placeholder="***********"
                type={showPassword ? "text" : "password"}
                error={
                  contraseniasValidas === undefined
                    ? false
                    : !contraseniasValidas
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title="La contraseña debe poseer como mínimo 6 caracteres">
                      <IconButton
                        sx={{
                          color: "white", // Color del IconButton
                          opacity: 0.5,
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{
                        color: "white", // Color del IconButton
                        opacity: 0.5,
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  input: {
                    color: "white", // Color del texto del input
                  },
                  "&:before": {
                    borderBottomColor: "white", // Color de la línea inferior antes del foco
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // Color de la línea inferior en hover
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Color del placeholder
                    opacity: 0.5, // Opacidad del placeholder
                  },
                }}
                onChange={(e) => setContrasenia(e.target.value)}
              />
            </FormControl>
            <FormControl className="text" variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                sx={{
                  color: "#fff", // Color del label por defecto
                  "&.Mui-focused": {
                    color: "#fff", // Color del label cuando el TextField está enfocado
                  },
                }}
              >
                Confirmar contraseña
              </InputLabel>
              <Input
                required
                id="standard-adornment-password"
                placeholder="***********"
                type={showPassword ? "text" : "password"}
                error={
                  contraseniasValidas === undefined
                    ? false
                    : !contraseniasValidas
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{
                        color: "white", // Color del IconButton
                        opacity: 0.5,
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  input: {
                    color: "white", // Color del texto del input
                  },
                  "&:before": {
                    borderBottomColor: "white", // Color de la línea inferior antes del foco
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "white", // Color de la línea inferior en hover
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Color del placeholder
                    opacity: 0.5, // Opacidad del placeholder
                  },
                }}
                onChange={(e) => setConfirmContrasenia(e.target.value)}
              />
            </FormControl>
            {emailExistente && (
              <Typography
                color="#F00"
                sx={{ fontFamily: "Sora", marginTop: "1rem" }}
              >
                El email ingresado ya está registrado
              </Typography>
            )}
            <button
              disabled={
                !nombre ||
                !apellido ||
                !email ||
                !contrasenia ||
                !confirmContrasenia ||
                !contraseniasValidas
              }
              onClick={handleCrearCuenta}
              className="create-button"
            >
              Crear cuenta
            </button>
            <Typography variant="body2" className="labelCuenta">
              Ya tienes una cuenta?{" "}
              <Link to="/login" className="linkRegistrate">
                Inicia sesión
              </Link>
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
