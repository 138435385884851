import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagComponente from "../pure/Tag";
import { useEffect, useState } from "react";

export interface Tag {
  id_tag: number;
  nombre: string;
  descripcion: string;
}

interface SwipeToSlideProps {
  tags: Tag[];
  onClick: (tag: Tag) => void; // Cambiar el tipo a Tag
}


export default function SwipeToSlide({ tags, onClick }: SwipeToSlideProps) {
  // Calcular slidesToShow inicial basado en el ancho de la pantalla
  const getInitialSlidesToShow = () => {
    const width = window.innerWidth;
    if (width >= 1440) return 4;
    if (width >= 1024) return 3;
    if (width >= 768) return 2;
    return 1;
  };

  const [slidesToShow, setSlidesToShow] = useState(getInitialSlidesToShow); // Inicializa con el cálculo dinámico

  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "50px",
    slidesToShow: slidesToShow, // Se actualizará según el tamaño de la pantalla
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // Función para actualizar slidesToShow según el ancho de la ventana
  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width >= 1440) {
      setSlidesToShow(5);
    } else if (width >= 1024) {
      setSlidesToShow(4);
    } else if (width >= 767) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(2);
    }
  };

  useEffect(() => {
    // Actualiza el número de slides cuando cambia el tamaño de la pantalla
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {tags.map((tag: Tag) => (
          <div key={tag.id_tag} onClick={() => onClick && onClick(tag)}>
            {/* Pasar el nombre del tag al hacer click */}
            <TagComponente id_tag={tag.id_tag} nombre_tag={tag.nombre} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
