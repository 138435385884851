const tokenStorageName = "TokenUsuario";
const sessionStorageName = "userDataPublico"

export const storeJWT = (jwt: string): void => {
    localStorage.setItem(tokenStorageName, jwt);
  };

export const readJWT = (): string | false => {
if (typeof window == "undefined") return false;
return localStorage.getItem(tokenStorageName) || false;
};