import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: React.ReactNode;
  route?: string; // Hacemos que la propiedad 'route' sea opcional
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, route = "/" }) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate(route); // Redirigimos al valor de 'route' o por defecto a "/"
    }
  }, [user, loading, navigate, route]);

  if (loading) {
    return null; // No renderizamos nada mientras el estado de autenticación está cargando
  }

  return <>{user ? children : null}</>;
};

export default ProtectedRoute;
