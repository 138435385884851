import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Dispatch, useState } from "react";
import Grid from "@mui/material/Grid2";
import { avatarPorId } from "../../../common/const";
import { calcularDiferenciaDias } from "../Posteo";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { useAuth } from "../../../../context/AuthContext";
import { postInteraccion } from "../../../../App/Services/foroService";
import ReplyIcon from "@mui/icons-material/Reply";

export interface IComentario {
  id_interaccion: number;
  id_avatar: number;
  nombre_meriendista: string;
  apellido_meriendista: string;
  fecha_hora_creacion: string;
  descripcion: string;
  cant_likes: number;
  cant_dislikes: number;
  respuestas: IComentario[];
  setActualizar: Dispatch<React.SetStateAction<boolean>>;
  setResponderA: Dispatch<React.SetStateAction<number | null>>;
  responder: (newOpen: boolean) => () => void;
}

interface IDatosPost {
  id_foro: number;
  id_post: number;
}

export default function ComentarioItem(comentario: IComentario & IDatosPost) {
  const [open, setOpen] = useState(false);
  const usuario = useAuth();
  const [actualizar, setActualizar] = useState(false);

  function handleUtil(
    id_tipo_interaccion: number,
    id_interaccion_principal: number | null
  ): void {
    if (usuario?.user?.uid) {
      postInteraccion(
        usuario.user.uid,
        comentario.id_post,
        comentario.id_foro,
        id_tipo_interaccion,
        null,
        id_interaccion_principal
      )
        .then((res) => {
          setActualizar(!actualizar);
          comentario.setActualizar(!actualizar);
        })
        .catch((error) => console.log("error al marcar util"));
    }
  }

  const handleResponder = () => {
    comentario.setResponderA(comentario.id_interaccion);
  };

  return (
    <div>
      <ListItem
        key={comentario.id_interaccion}
        sx={{
          borderLeft: "2px solid lightgray", // Añadimos la línea gris
          ml: 3,
        }}
      >
        <Grid container display={"flex"} flexDirection={"column"}>
          {/* Avatar, nombre, apellido y hora */}
          <Grid display={"flex"} flexDirection={"row"} alignItems="center">
            {/* Avatar */}
            <img
              src={avatarPorId.get(comentario.id_avatar)}
              alt={comentario.nombre_meriendista}
              style={{ marginRight: "0.5rem" }}
              width={19}
              height={19}
            />
            {/* Nombre y apellido */}
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.5625rem",
                fontWeight: "400",
                marginRight: "0.5rem", // Espacio entre el nombre y la fecha
              }}
            >
              {comentario.nombre_meriendista} {comentario.apellido_meriendista}
              {" . "}
            </Typography>
            {/* Fecha */}
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.375rem",
                fontWeight: "400",
              }}
            >
              {calcularDiferenciaDias(comentario.fecha_hora_creacion)}
            </Typography>
          </Grid>

          {/* Descripción del comentario */}
          <Grid>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.6375rem",
                fontWeight: "400",
              }}
            >
              {comentario.descripcion}
            </Typography>
          </Grid>

          {/* Botones de interacción */}
          <Grid display={"flex"} flexDirection={"row"} sx={{ mt: "1rem" }}>
            <Button
              sx={{
                height: "0.9375rem",
                borderRadius: "0.8125rem",
                background: "#D9D9D9",
                color: "#000",
                fontFamily: "Sora",
                fontSize: "0.4375rem",
                textTransform: "none",
              }}
              startIcon={
                <ThumbUpOffAltOutlinedIcon
                  sx={{ fontSize: "0.62813rem !important" }}
                />
              }
              onClick={() => handleUtil(1, comentario.id_interaccion)}
            >
              {comentario.cant_likes}
            </Button>
            <Button
              sx={{
                height: "0.9375rem",
                borderRadius: "0.8125rem",
                background: "#D9D9D9",
                color: "#000",
                fontFamily: "Sora",
                fontSize: "0.4375rem",
                textTransform: "none",
              }}
              startIcon={
                <ThumbDownAltOutlinedIcon
                  sx={{ fontSize: "0.62813rem !important" }}
                />
              }
              onClick={() => handleUtil(2, comentario.id_interaccion)}
            >
              {comentario.cant_dislikes}
            </Button>
            {comentario.respuestas.length != 0 && (
              <Button
                sx={{
                  height: "0.9375rem",
                  borderRadius: "0.8125rem",
                  background: "#D9D9D9",
                  color: "#000",
                  fontFamily: "Sora",
                  fontSize: "0.4375rem",
                  textTransform: "none",
                }}
                startIcon={
                  <InsertCommentIcon
                    sx={{ fontSize: "0.62813rem !important" }}
                  />
                }
                onClick={() => setOpen(!open)}
              >
                {comentario.respuestas.length}
              </Button>
            )}
            <Button
              sx={{
                height: "0.9375rem",
                borderRadius: "0.8125rem",
                background: "#D9D9D9",
                color: "#000",
                fontFamily: "Sora",
                fontSize: "0.4375rem",
                textTransform: "none",
              }}
              startIcon={
                <ReplyIcon sx={{ fontSize: "0.62813rem !important" }} />
              }
              onClick={() => {
                comentario.responder(true)(); // Abre el SwipeableDrawer
                handleResponder(); // Llama a tu función handleResponder
              }}
            >
              Responder
            </Button>
          </Grid>
        </Grid>
      </ListItem>
      {comentario.respuestas.length != 0 && (
        <Collapse in={open}>
          <List component="div" disablePadding>
            {comentario.respuestas.map((respuesta) => (
              <ListItem
                key={respuesta.id_interaccion}
                sx={{
                  pl: 4,
                  borderLeft: "2px solid lightgray", // Añadimos la línea gris
                  ml: 6,
                }}
              >
                <Grid container display={"flex"} flexDirection={"column"}>
                  {/* Avatar, nombre, apellido y hora */}
                  <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems="center"
                  >
                    {/* Avatar */}
                    <img
                      src={avatarPorId.get(comentario.id_avatar)}
                      alt={comentario.nombre_meriendista}
                      style={{ marginRight: "0.5rem" }}
                      width={19}
                      height={19}
                    />
                    {/* Nombre y apellido */}
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontSize: "0.5625rem",
                        fontWeight: "400",
                        marginRight: "0.5rem", // Espacio entre el nombre y la fecha
                      }}
                    >
                      {respuesta.nombre_meriendista}{" "}
                      {respuesta.apellido_meriendista}
                      {" . "}
                    </Typography>
                    {/* Fecha */}
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontSize: "0.375rem",
                        fontWeight: "400",
                      }}
                    >
                      {calcularDiferenciaDias(respuesta.fecha_hora_creacion)}
                    </Typography>
                  </Grid>

                  {/* Descripción del comentario */}
                  <Grid>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontSize: "0.6375rem",
                        fontWeight: "400",
                      }}
                    >
                      {respuesta.descripcion}
                    </Typography>
                  </Grid>

                  {/* Botones de interacción */}
                  <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    sx={{ mt: "1rem" }}
                  >
                    <Button
                      sx={{
                        height: "0.9375rem",
                        borderRadius: "0.8125rem",
                        background: "#D9D9D9",
                        color: "#000",
                        fontFamily: "Sora",
                        fontSize: "0.4375rem",
                        textTransform: "none",
                      }}
                      startIcon={
                        <ThumbUpOffAltOutlinedIcon
                          sx={{ fontSize: "0.62813rem !important" }}
                        />
                      }
                      onClick={() => handleUtil(1, respuesta.id_interaccion)}
                    >
                      {respuesta.cant_likes}
                    </Button>
                    <Button
                      sx={{
                        height: "0.9375rem",
                        borderRadius: "0.8125rem",
                        background: "#D9D9D9",
                        color: "#000",
                        fontFamily: "Sora",
                        fontSize: "0.4375rem",
                        textTransform: "none",
                      }}
                      startIcon={
                        <ThumbDownAltOutlinedIcon
                          sx={{ fontSize: "0.62813rem !important" }}
                        />
                      }
                      onClick={() => handleUtil(2, respuesta.id_interaccion)}
                    >
                      {respuesta.cant_dislikes}
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
}
