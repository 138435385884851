import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  ListItemButton,
  NativeSelect,
  Typography,
} from "@mui/material";
import "../styles/LoginPage.css"; // Importar los estilos CSS
import { useNavigate, useLocation } from "react-router-dom";
import { getTags } from "../App/Services/cafeteriaService";
import {
  getCiudades,
  getPreferencias,
  postPreferencias,
  deletePreferencias,
} from "../App/Services/meriendistaService";
import { useAuth } from "../context/AuthContext";
import { getCiudadDeMeriendista } from "../App/Services/mapaService";
import ReusableModal from "../components/Modales/Modal"; // Importar el modal reutilizable

const ModificarPreferencias: React.FC = () => {
  const [preferencias, setPreferencias] = useState<number[]>([]); // Almacenar IDs de las preferencias seleccionadas
  const [tags, setTags] = useState<any[]>([]);
  const [ciudades, setCiudades] = useState<any[]>([]);
  const [ciudad, setCiudad] = useState<number>();
  const [modalConfirmacion, setModalConfirmacion] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<any[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // Estado para el modal de éxito
  const usuario = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // Para saber si venimos de la página de guardar

  // Función para cargar los datos al iniciar el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener los tags de preferencias disponibles
        const tagsResponse = await getTags();
        setTags(tagsResponse);

        // Obtener las ciudades disponibles
        const ciudadesResponse = await getCiudades();
        setCiudades(ciudadesResponse);

        // Si el usuario ya tiene preferencias guardadas, las cargamos
        if (usuario?.user?.uid) {
          const preferenciasGuardadas = await getPreferencias(usuario.user.uid);

          // Almacenar solo los id_tag de las preferencias guardadas
          const idsPreferencias = preferenciasGuardadas.map(
            (preferencia: any) => preferencia.id_tag
          );
          setPreferencias(idsPreferencias); // Guardamos solo los IDs de las preferencias

          const ciudadGuardada = await getCiudadDeMeriendista(usuario.user.uid);
          setCiudad(ciudadGuardada.idCiudad); // También cargamos la ciudad si está disponible
        }
      } catch (error) {
        console.error("Error al cargar los datos", error);
      }
    };

    fetchData(); // Llamamos a la función que obtiene los datos

    // Mostrar modal de éxito si venimos de la redirección después de guardar
    if (location.state?.fromSave) {
      setTituloModal("Preferencias actualizadas");
      setContenidoModal("Tus preferencias se han actualizado con éxito.");
      setBotonesModal([
        {
          label: "Aceptar",
          onClick: handleCloseModal,
          sx: {
            background: "#44291E",
            color: "#F0ECE3",
            fontFamily: "Sora",
            fontWeight: "600",
            borderRadius: "0.9375rem",
            width: "9.75rem",
          },
        },
      ]);
      setShowSuccessModal(true);
    }
  }, [usuario, location]);

  // Función para manejar el clic en un tag
  const handleClick = (id: number) => {
    setPreferencias((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((tagId) => tagId !== id) // Si ya está seleccionado, lo quitamos
        : [...prevSelected, id] // Si no está seleccionado, lo añadimos
    );
  };

  // Función para manejar el cambio de ciudad
  const handleChange = (event: { target: { value: string } }) => {
    setCiudad(Number(event.target.value));
  };

  // Función para confirmar si el usuario está seguro de guardar los cambios
  const handleConfirmacionGuardar = () => {
    setTituloModal("Confirmación");
    setContenidoModal("¿Estás seguro de que deseas guardar los cambios?");
    setBotonesModal([
      {
        label: "Cancelar",
        onClick: () => setModalConfirmacion(false),
        sx: {
          background: "#D9D9D9",
          color: "#44291E",
          fontFamily: "Sora",
          fontWeight: "600",
          borderRadius: "0.9375rem",
          width: "9.75rem",
        },
      },
      {
        label: "Confirmar",
        onClick: handleGuardarCambios,
        sx: {
          background: "#44291E",
          color: "#F0ECE3",
          fontFamily: "Sora",
          fontWeight: "600",
          borderRadius: "0.9375rem",
          width: "9.75rem",
        },
      },
    ]);
    setModalConfirmacion(true);
  };

  // Función para manejar la actualización de preferencias
  const handleGuardarCambios = async () => {
    setModalConfirmacion(false); // Cerrar el modal de confirmación
    if (usuario?.user?.uid) {
      try {
        // Obtener las preferencias actuales del usuario
        const preferenciasGuardadas = await getPreferencias(usuario.user.uid);
        const idsPreferenciasGuardadas = preferenciasGuardadas.map(
          (pref: any) => pref.id_tag
        );

        // Preferencias que ya no están seleccionadas y deben ser eliminadas
        const preferenciasAEliminar = idsPreferenciasGuardadas.filter(
          (id: number) => !preferencias.includes(id)
        );

        // Preferencias nuevas que deben ser agregadas
        const preferenciasAAgregar = preferencias.filter(
          (id) => !idsPreferenciasGuardadas.includes(id)
        );

        // Eliminar preferencias no seleccionadas
        if (preferenciasAEliminar.length > 0) {
          await deletePreferencias(usuario.user.uid, preferenciasAEliminar, ciudad!);
        }

        // Agregar nuevas preferencias
        if (preferenciasAAgregar.length > 0) {
          await postPreferencias(usuario.user.uid, preferenciasAAgregar, ciudad!);
        }

        navigate("/ver-perfil", { state: { fromSave: true } }); // Redirigir al perfil y pasar estado de éxito
      } catch (error) {
        console.error("Error al actualizar las preferencias", error);
      }
    } else {
      console.error("El usuario no está autenticado o no tiene un UID");
    }
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="preferencia-page">
      <Typography
        sx={{
          justifyContent: "left",
          color: "#000",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          fontFamily: "Higuen",
          fontSize: "2.1875rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        }}
      >
        Modificar tus preferencias
      </Typography>

      <Typography
        sx={{
          mt: "2rem",
          mb: "1rem",
          justifyContent: "left",
          color: "#44291E",
          fontFamily: "Sora",
          fontSize: "0.8125rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        Selecciona tu ciudad:
      </Typography>

      <FormControl
        sx={{
          width: "100%",
          height: "2.063rem",
          border: "none",
          boxShadow: "none",
        }}
        variant="filled"
      >
        <NativeSelect
          id="demo-simple-select-helper"
          value={ciudad}
          onChange={handleChange}
          inputProps={{ "aria-label": "Ciudad" }}
          sx={{
            background: "#fff",
            paddingLeft: "1rem",
            borderRadius: "32px",
            border: "1px solid #ccc", // Bordes redondeados
            "&:focus": {
              backgroundColor: "white",
            },
            "&:before, &:after": {
              display: "none", // Ocultar las líneas antes y después del campo
            },
          }}
        >
          <option value="" disabled hidden>
            Selecciona una ciudad
          </option>
          {ciudades &&
            ciudades.map((ciu: any) => (
              <option value={ciu.idCiudad} key={ciu.idCiudad}>
                {ciu.nombre}
              </option>
            ))}
        </NativeSelect>
      </FormControl>

      <Typography
        sx={{
          mt: "2rem",
          mb: "1rem",
          justifyContent: "left",
          color: "#44291E",
          fontFamily: "Sora",
          fontSize: "0.8125rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        Selecciona tus preferencias
      </Typography>

      <div>
        <Grid
          container
          sx={{
            mt: "1rem",
            justifyContent: "center", // Centrar los elementos
            alignItems: "center", // Alinearlos verticalmente
            flexWrap: "wrap", // Permitir que los elementos se ajusten en múltiples líneas
          }}
          spacing={2} // Espaciado entre los elementos
        >
          {tags &&
            tags.map((tag: any) => (
              <Grid item key={tag.id_tag}>
                <ListItemButton
                  component="button"
                  value={tag.id_tag}
                  sx={{
                    height: "auto", // Dejar que la altura se ajuste según el contenido
                    borderRadius: "4.75rem",
                    background: preferencias.includes(tag.id_tag)
                      ? "#44291E" // Fondo cuando está seleccionado
                      : "#003147", // Fondo cuando no está seleccionado
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.16)",
                    color: "#FFF",
                    fontSize: "0.875rem",
                    padding: "0.5rem 1rem", // Espaciado interno
                    textAlign: "center", // Centrar el texto
                    justifyContent: "center", // Centrar el contenido horizontalmente
                    whiteSpace: "nowrap", // Evitar que el texto se corte en varias líneas
                  }}
                  onClick={() => handleClick(tag.id_tag)}
                >
                  {tag.nombre}
                </ListItemButton>
              </Grid>
            ))}
        </Grid>
      </div>

      <div className="div-boton-preferencias">
        <button
          disabled={!ciudad || preferencias.length < 4} // Desactivar el botón si no hay ciudad o menos de 4 preferencias seleccionadas
          className="boton-continuar"
          onClick={handleConfirmacionGuardar} // Mostrar el modal de confirmación al guardar
        >
          Guardar cambios
        </button>
      </div>

      {/* Modal de confirmación */}
      <ReusableModal
        open={modalConfirmacion}
        onClose={() => setModalConfirmacion(false)}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />

      {/* Modal de éxito */}
      <ReusableModal
        open={showSuccessModal}
        onClose={handleCloseModal}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
    </div>
  );
};

export default ModificarPreferencias;
