import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  ListItemButton,
  NativeSelect,
  Typography,
  InputLabel,
} from "@mui/material";
import "../styles/LoginPage.css"; // Importar los estilos CSS
import { Link } from "react-router-dom";
import Buscador from "../components/home/Buscador";
import { getTags } from "../App/Services/cafeteriaService";
import {
  getCiudades,
  postPreferencias,
} from "../App/Services/meriendistaService";
import { useAuth } from "../context/AuthContext";

const RegistrarPreferencia: React.FC = () => {
  const [preferencias, setPreferencias] = useState<number[]>([]);
  const [tags, setTags] = useState<any[]>();
  const [ciudades, setCiudades] = useState<any[]>();
  const [ciudad, setCiudad] = useState<number>();
  const usuario = useAuth();

  useEffect(() => {
    getTags()
      .then((response) => setTags(response))
      .catch((error) => console.log("error al obtener los tags"));

    getCiudades()
      .then((response) => setCiudades(response))
      .catch((error) => console.log("error al obtener ciudades"));
  }, []);

  const handleClick = (id: number) => {
    setPreferencias((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((tagId) => tagId !== id)
        : [...prevSelected, id]
    );
  };

  const handleContinuar = () => {
    if (usuario?.user?.uid) {
      postPreferencias(usuario.user.uid, preferencias, ciudad!)
        .then(() => {
        })
        .catch((error) => {
          console.error("Error al guardar las preferencias:", error);
        });
    } else {
      console.error("El usuario no está autenticado o no tiene un UID");
    }
  };


  const handleChange = (event: { target: { value: string } }) => {
    setCiudad(Number(event.target.value));
  };

  return (
    <div className="preferencia-page">
      <Typography
        sx={{
          justifyContent: "left",
          color: "#000",
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          fontFamily: "Higuen",
          fontSize: "2.1875rem",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        }}
      >
        ¡Vamos a crear tu perfil ideal!
      </Typography>
      <Typography
        sx={{
          mt: "2rem",
          mb: "1rem",
          justifyContent: "left",
          color: "#44291E",
          fontFamily: "Sora",
          fontSize: "0.8125rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        Selecciona tu ciudad:
      </Typography>
      <FormControl
        sx={{
          width: "100%",
          height: "2.063rem",
          border: "none",
          boxShadow: "none",
        }}
        variant="filled"
      >
        <NativeSelect
          id="demo-simple-select-helper"
          value={ciudad}
          onChange={handleChange}
          inputProps={{ "aria-label": "Ciudad" }}
          sx={{
            background: "#fff",
            paddingLeft: "1rem",
            borderRadius: "32px",
            border: "1px solid #ccc", // Bordes redondeados
            "&:focus": {
              backgroundColor: "white",
            },
            "&:before, &:after": {
              display: "none", // Ocultar las líneas antes y después del campo
            },
          }}
        >
          <option value="" disabled selected hidden>
            Selecciona una ciudad
          </option>
          {ciudades &&
            ciudades.map((ciu: any) => (
              <option value={ciu.idCiudad} key={ciu.idCiudad}>
                {ciu.nombre}
              </option>
            ))}
        </NativeSelect>
      </FormControl>
      <Typography
        sx={{
          mt: "2rem",
          mb: "1rem",
          justifyContent: "left",
          color: "#44291E",
          fontFamily: "Sora",
          fontSize: "0.8125rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        Selecciona al menos 4 preferencias
      </Typography>
      <div>
        <Grid
          container
          sx={{
            mt: "1rem",
            justifyContent: "center", // Centrar los elementos
            alignItems: "center", // Alinearlos verticalmente
            flexWrap: "wrap", // Permitir que los elementos se ajusten en múltiples líneas
          }}
          spacing={2} // Espaciado entre los elementos
        >
          {tags &&
            tags.map((tag: any) => (
              <Grid item key={tag.id_tag}>
                <ListItemButton
                  component="button"
                  value={tag.id_tag}
                  sx={{
                    height: "auto", // Dejar que la altura se ajuste según el contenido
                    borderRadius: "4.75rem",
                    background: preferencias.includes(tag.id_tag)
                      ? "#44291E"
                      : "#003147",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.16)",
                    color: "#FFF",
                    fontSize: "0.875rem", // Ajusta el tamaño de fuente según sea necesario
                    padding: "0.5rem 1rem", // Espaciado interno
                    textAlign: "center", // Centrar el texto
                    justifyContent: "center", // Centrar el contenido horizontalmente
                    whiteSpace: "nowrap", // Evitar que el texto se corte en varias líneas
                  }}
                  onClick={() => handleClick(tag.id_tag)}
                >
                  {tag.nombre}
                </ListItemButton>
              </Grid>
            ))}
        </Grid>
      </div>
      <div className="div-boton-preferencias">
        <Link to="/inicio">
          <button
            disabled={!ciudad || preferencias.length < 4}
            className="boton-continuar"
            onClick={handleContinuar}
          >
            Continuar
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RegistrarPreferencia;
