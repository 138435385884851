import React, { useEffect, useState } from "react";
import Cabecera from "../../components/home/Cabecera";
import "../../styles/InicioCafeteria.css";
import Buscador from "../../components/home/Buscador";
import NavCafeteria from "../../components/home/NavCafeteria";
import { Box, Grid, Typography } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  obtenerHomeReviewsCafeteria,
  getCafeteria,
} from "../../App/Services/cafeteriaService";
import { getAllEventosCafeteria } from "../../App/Services/rutasEventosServices";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
import PromocionesCafeteria from "../../components/cafeteria/promociones/PromocionesDelHomeCafeteria";
import ReseñasHomeCafeteria from "../../components/cafeteria/reseñas/ReseñasHomeCafeteria";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";
import EventoCafeteria from "../../components/eventos/EventoCafeteria";
import { useMediaQuery } from "@mui/material";
import { getAllPromocionesActivas } from "../../App/Services/Cafeteria/promocionesServices";

const InicioCafeteria: React.FC = () => {
  const usuario = useAuth();
  const [cafeteria, setCafeteria] = useState<any>(null);
  const [promociones, setPromociones] = useState<any[]>([]);
  const [reseñas, setReseñas] = useState<any[]>([]);
  const [reseñasPuntaje, setReseñasPuntaje] = useState<number>(0); // Número para el puntaje promedio
  const [eventos, setEventos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPromociones, setLoadingPromociones] = useState<boolean>(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loadingEventos, setLoadingEventos] = useState<boolean>(true);

  useEffect(() => {
    const fetchDatosCafeteria = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosCafeteria = await getCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setCafeteria(datosCafeteria);

          const eventosCafeteria = await getAllEventosCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setEventos(eventosCafeteria);
        } catch (error) {
          console.error("Error al obtener datos de la cafetería:", error);
        } finally {
          setLoading(false);
          setLoadingPromociones(false); // Indica que las promociones ya se han cargado
        }
      }
    };
    fetchDatosCafeteria();
  }, [usuario]);

  useEffect(() => {
    const fetchPromociones = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );

          // Llama al endpoint para obtener todas las promociones activas
          const promociones = await getAllPromocionesActivas(
            cafeteriaAsociada.id_cafeteria
          );
          console.log("PROMOCIONES: ", promociones);

          setPromociones(promociones); // Actualiza solo el estado de promociones
        } catch (error) {
          console.error("Error al obtener promociones:", error);
        } finally {
          setLoadingPromociones(false); // Indica que las promociones ya se han cargado
        }
      }
    };
    fetchPromociones();
  }, [usuario]);

  useEffect(() => {
    const fetchReseñas = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosReseñas = await obtenerHomeReviewsCafeteria(
            cafeteriaAsociada.id_cafeteria
          );

          // Si no hay datos de reseñas, establece un puntaje predeterminado
          if (datosReseñas.length > 0) {
            setReseñas(datosReseñas[0].reviews || []); // Asegura que sea un array
            setReseñasPuntaje(datosReseñas[0].puntaje || 0); // Asegura que sea 0 si no hay puntaje
          } else {
            setReseñas([]); // Sin reseñas
            setReseñasPuntaje(0); // Puntaje predeterminado
          }
        } catch (error) {
          console.error("Error al obtener reseñas:", error);
          setReseñas([]); // En caso de error, inicializa como vacío
          setReseñasPuntaje(0); // Puntaje predeterminado
        } finally {
          setLoading(false);
        }
      }
    };
    fetchReseñas();
  }, [usuario]);

  useEffect(() => {
    const fetchDatosEventos = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const eventosCafeteria = await getAllEventosCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setEventos(eventosCafeteria);
        } catch (error) {
          console.error("Error al obtener eventos:", error);
        } finally {
          setLoadingEventos(false); // Se asegura de que cambie a false
        }
      }
    };
    fetchDatosEventos();
  }, [usuario]);

  return (
    <Box className="inicio-cafeteria">
      <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />
      {/* Sección de Promociones */}
      <Box
        className="promociones-cafeteria-texto"
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "1rem 2rem 0 2rem",
        }}
      >
        <img
          src="/assets/homeCafeteria/tusPromocionesCafeteriaIcon.svg"
          alt="Icono de promociones"
          style={{ width: "1.75rem", height: "1.75rem" }}
        />
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Sora",
            color: "#44291E",
            fontSize: "0.9375rem",
            fontWeight: "600",
            paddingLeft: "0.5rem",
          }}
        >
          Promociones Activas
        </Typography>
      </Box>
      {loadingPromociones ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Espaciado uniforme entre los skeletons
            padding: "0 2rem",
            gap: "1rem", // Espaciado entre skeletons
          }}
        >
          {[...Array(isMobile ? 2 : 3)].map((_, index) => (
            <Skeleton
              key={index}
              height="10.125rem"
              width="9.375rem"
              style={{ borderRadius: "1rem" }}
            />
          ))}
        </Box>
      ) : promociones.length > 0 ? (
        <PromocionesCafeteria promociones={promociones} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 2rem 0 2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#003147",
              fontSize: "0.875rem",
              fontWeight: "600",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No hay promociones activas disponibles en este momento.
          </Typography>
        </Box>
      )}

      {/* Sección de Reseñas */}

      <Box
        className="reseñas-cafeteria"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Asegura que los elementos estén separados
          margin: "1rem 2rem 0 2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/homeCafeteria/tusReseñasCafeteriaIcon.svg"
            alt="Reseñas"
            style={{
              width: "1.75rem",
              height: "1.75rem",
              marginRight: "0.5rem",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Sora",
              color: "#44291E",
              fontSize: "0.9375rem",
              fontWeight: "600",
            }}
          >
            Tus Reseñas
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F5F5F5",
            borderRadius: "1.0625rem",
            width: "6.875rem", // Ajusta el ancho para dispositivos más pequeños
            height: "2.3125rem",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
          }}
        >
          <Typography
            sx={{
              color: "#44291E",
              fontFamily: "Sora",
              fontSize: "0.8125rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            Tenés {reseñasPuntaje ?? 0} {/* Usa 0 si reseñasPuntaje es null o undefined */}
            <img
              src="/assets/consultarCafeteria/estrellaDeReseñasIcon.svg"
              alt="Estrella"
              style={{
                width: "1rem",
                height: "1rem",
                marginLeft: "0.31rem",
                marginBottom: "0.2rem",
              }}
            />
          </Typography>
        </Box>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Espaciado uniforme
            padding: "0 2rem",
            gap: "1rem", // Espaciado entre skeletons
          }}
        >
          {[...Array(isMobile ? 2 : 3)].map((_, index) => (
            <Skeleton
              key={index}
              height="5.0625rem"
              width="8.3125rem"
              style={{ borderRadius: "1.0625rem" }}
            />
          ))}
        </Box>
      ) : reseñas?.length > 0 ? (
        <ReseñasHomeCafeteria reseñas={reseñas} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 2rem 0 2rem",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#003147",
              fontSize: "0.875rem",
              fontWeight: "600",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No hay reseñas disponibles en este momento.
          </Typography>
        </Box>
      )}

      {/* Sección de Eventos */}
      <Box
        className="eventos-cafeteria"
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "1rem 2rem 0 2rem",
        }}
      >
        <img
          src="/assets/homeCafeteria/tusEventosCafeteriaIcon.svg"
          alt="Icono de eventos"
          style={{ width: "1.75rem", height: "1.75rem", marginRight: "0.5rem" }}
        />
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Sora",
            color: "#44291E",
            fontSize: "0.9375rem",
            fontWeight: "600",
          }}
        >
          Próximos Eventos
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "1rem 2rem 0 2rem",
        }}
      >
        {loadingEventos ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Cambia la dirección a columna
              alignItems: "center", // Centra los skeletons horizontalmente
              gap: "1rem", // Espaciado entre los skeletons
              marginTop: "1rem",
            }}
          >
            {[...Array(isMobile ? 2 : 3)].map((_, index) => (
              <Skeleton
                key={index}
                height="9.125rem"
                width="100%"
                style={{ borderRadius: "1.5rem" }}
              />
            ))}
          </Box>
        ) : eventos.length > 0 ? (
          eventos.map((evento) => (
            <EventoCafeteria key={evento.id} puede_editar={false} {...evento} />
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 2rem 0 2rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#003147",
                fontSize: "0.875rem",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              No hay eventos disponibles en este momento.
            </Typography>
          </Box>
        )}
      </Box>
      <NavCafeteria select={3} />
    </Box>
  );
};

export default InicioCafeteria;
