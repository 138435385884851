import React, { useEffect, useState } from "react";
import Cabecera from "../components/home/Cabecera";
import "../styles/Inicio.css"; // Importar los estilos CSS
import Buscador from "../components/home/Buscador";
import SwipeToSlide from "../components/home/SliderTags";
import SwipeToSlideCafeterias from "../components/home/SliderCafeterias";
import Nav from "../components/home/Nav";
import { getTags } from "../App/Services/cafeteriaService";
import {
  getCafeteriasPopulares,
  getCafeteriasRecomendadas,
} from "../App/Services/homeService";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box, Grid, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import ComponenteCafeteriaParaVos from "../components/pure/CafeteriaParaVos";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import useHandleSearch from "../components/hooks/useHandleSearch"; // Importar la función
import useTagSearch from "../components/hooks/useTagSearch";
import { useNavigate } from "react-router-dom";

const RankingTopCafeterias: React.FC = () => {
  const usuario = useAuth();
  const handleSearch = useHandleSearch();
  const navigate = useNavigate(); // Mover el hook aquí
  const [tags, setTags] = useState<any[]>([]);
  const [cafeterias, setCafeterias] = useState<any[]>([]);
  const [recomendadas, setRecomendadas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true); // Declarar el estado de carga

  useEffect(() => {
    getCafeteriasPopulares()
      .then((res) => setCafeterias(res))
      .catch((error) =>
        console.log("error al consultar cafeterias mas populares")
      )
      .finally(() => {
        setLoading2(false); // Finaliza la carga siempre, incluso si hay errores
      });
      console.log("cafeterias",cafeterias)

    getTags()
      .then((res: any[]) => setTags(res))
      .catch((error: any) =>
        console.log("Error al recuperar datos de los tags")
      );

    if (usuario?.user?.uid) {
      getCafeteriasRecomendadas(usuario.user.uid)
        .then((res: any[]) => {
          setRecomendadas(res);
        })
        .catch((error) => {
          console.error("Error al obtener recomendadas", error);
        })
        .finally(() => {
          setLoading(false); // Finaliza la carga siempre, incluso si hay errores
        });
        console.log("recomendadas",recomendadas)
    } else {
      console.error("El usuario no está autenticado o no tiene un UID");
      setLoading(false); // Finaliza la carga si el usuario no está definido
    }
  }, []);

  // Pasar el objeto tag completo al hacer clic
  const handleTagClick = (tag: any) => {
    if (tag) {
      navigate(`/resultados`, { state: { tag } }); // Usar navigate con state para pasar el objeto tag
    }
  };

  return (
    <div className="inicio">
      <Cabecera es_home={true} />
      <Buscador label={"Buscar cafeterias"} onEnter={handleSearch} />
      <div className="contenedor-carrusel">
        <SwipeToSlide tags={tags} onClick={handleTagClick} />
      </div>
      <div className="contenedor-paravos">
        <Grid
          container
          alignItems="center" // Alinear los elementos verticalmente
          justifyContent="space-between" // Espaciar los elementos horizontalmente
          sx={{ mb: "1rem" }}
          spacing={1}
        >
          <Grid item container xs={6} alignItems="center" spacing={1}>
            <Grid item>
              <img src="/assets/RankingSinFondoIcon.svg" alt="" />
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                }}
              >
                Top Cofflick
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div
          className="background-scroll-rankingTopCafeterias"
          style={{
            position: "relative", // Asegura que el contenido posicionado relativo dentro sea visible
            overflow: "visible", // Permite que los elementos que sobresalen sean visibles
            marginTop: "2rem", // Agrega un margen superior para evitar que las medallas se corten
          }}
        >
          {loading ? (
            <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
              {Array.from({ length: 5 }).map((_, index) => (
                <Skeleton
                  key={index}
                  style={{
                    height: "9.125rem", // Altura fija
                    width: "90%", // Ancho relativo al contenedor
                    margin: "0 auto", // Centrar el esqueleto horizontalmente
                    borderRadius: "1.5625rem",
                    padding: "1rem 0.8rem",
                    marginBottom: "1rem",
                  }}
                />
              ))}
            </SkeletonTheme>
          ) : cafeterias.length > 0 ? ( // Eliminar llaves adicionales
            cafeterias.slice(0, 5).map((cafeteria: any, index: number) => (
              <ComponenteCafeteriaParaVos
                key={cafeteria.id_cafeteria}
                id_cafeteria={cafeteria.id_cafeteria}
                nombre_cafeteria={cafeteria.nombre}
                imagen={cafeteria.imagen}
                estado={cafeteria.estado}
                cantidad_estrellas={cafeteria.calificacion}
                direccion={cafeteria.direccion}
                tags={cafeteria.tags}
                index={index} // Pasa el índice aquí
                mostrarMedalla={true} // Prop para mostrar la medalla
              />
            ))
          ) : (
            <Box className="sin-recomendaciones">
              <div className="sin-recomendaciones-text">
                <Typography
                  sx={{
                    fontFamily: "Sora",
                    color: "#003147",
                    fontSize: "0.9375rem",
                    fontWeight: 600,
                  }}
                >
                  Aún no tienes recomendaciones
                </Typography>
              </div>
              <img src="assets/Resultados/sadFaceIcon.svg" alt="Sad Face" />
            </Box>
          )}
        </div>
      </div>
      <Nav select={0} />
    </div>
  );
};

export default RankingTopCafeterias;
