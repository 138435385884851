import React from "react";
import "../../styles/EncabezadoMapa.css";

const Encabezado: React.FC = () => {
  return (
    <div className="encabezado">
      <div className="logo-enc"></div>
    </div>
  );
};

export default Encabezado;
