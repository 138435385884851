import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid2";
import Skeleton from "react-loading-skeleton";

interface SwipeToSlideProps<T> {
  items: T[];
  cantidadMostrar: number;
  loading: boolean;
  renderItem: (item: T) => JSX.Element;
}

export default function SwipeToSlideGeneral<T>(props: SwipeToSlideProps<T>) {
  const settings = {
    className: "center",
    infinite: props.items.length > 1,
    centerPadding: "50px",
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: props.items.length > 1
  };

  return (
    <div className="slider-container">
      <div>
        <Slider {...settings}>
          {props.loading
            ? Array.from({ length: 2 }).map((_, index) => (
                <Grid key={index}>
                  <Skeleton
                    height={200}
                    width="100%"
                    style={{
                      borderRadius: "1.5625rem",
                      margin: "0.1rem",
                    }}
                  />
                </Grid>
              ))
            : props.items.map((item, index) => (
                <Grid key={index}>
                  {props.renderItem(item)}
                </Grid>
              ))}
        </Slider>
      </div>
    </div>
  );
}