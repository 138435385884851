import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Registro del Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js') // Prueba con la ruta relativa al root
      .then(registration => {

        // Escuchar nuevas actualizaciones
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;

          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  window.location.reload();  // Recargar la PWA automáticamente
                }
              }
            };
          }
        };
      })
      .catch(error => {
        console.error('Fallo en el registro del Service Worker:', error);
      });
  });
}

reportWebVitals();
