import { storeJWT } from "../../Token";
import axios from "axios";
import { BASE_URL } from "../config"

export const logUser = async (tokenDefinitivo: string) => {
    storeJWT(tokenDefinitivo);
  };

  
export const postUsuario = async ({
    nombre,
    apellido,
    uuid,
    email,
}: {
    nombre: string;
    apellido: string;
    uuid: string;
    email: string;
}) => {
    const response = await axios.post(`${BASE_URL}/users/`,
        {nombre, apellido, uuid, email}
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        throw Error(error);
      });
    return response;
  };

  export const existeUsuario = async (uid_meriendista:string): Promise<any> => {
    const response = await axios.get(
      `${BASE_URL}/meriendista/${uid_meriendista}`
    )
    .then((res) => {return res.data})
    .catch ((error) => {
      throw Error(error);
    });
    return response
  };

  export const eliminarUsuario = async (uid_meriendista:string): Promise<any> => {
    const response = await axios.delete(
      `${BASE_URL}/users/${uid_meriendista}`
    )
    .then((res) => {return res.data})
    .catch ((error) => {
      throw Error(error);
    });
    return response
  };

  export const tieneCafeteriaAsociada = async (uid_meriendista:string): Promise<any> => {
    const response = await axios.get(
      `${BASE_URL}/users/rol/${uid_meriendista}`
    )
    .then((res) => {return res.data})
    .catch ((error) => {
      throw Error(error);
    });
    return response
  };