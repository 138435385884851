import React, { memo } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AllInboxIcon from '@mui/icons-material/AllInbox';

// Pasos del Stepper
const steps = ["Información Básica", "Preferencias", "Contacto y Horarios", "Ubicacion e Imagenes"];

// Conector personalizado del Stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#003147", // Azul cuando el paso está activo
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#dab587", // Marrón cuando el paso está completado
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0", // Color para pasos inactivos
    borderRadius: 1,
  },
}));

// Íconos personalizados del Stepper
const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: ownerState.completed ? "#dab587" : "#eaeaf0", // Marrón para completado, gris claro para inactivo
  color: "#fff",
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#003147", // Azul para activo
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props: { active?: boolean; completed?: boolean; icon: React.ReactNode }) {
  const { active, completed, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AccountCircleIcon />,
    2: <FavoriteIcon />,
    3: <AllInboxIcon />,
    4: <AddLocationIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

// Componente CustomStepper
const CustomStepper: React.FC<{ activeStep: number }> = memo(({ activeStep }) => (
  <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
    {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel
          StepIconComponent={ColorlibStepIcon}
          sx={{
            "& .MuiStepLabel-label": {
              fontFamily: "Sora", // Aplica la fuente Higuen
              fontSize: "0.9rem", // Tamaño de la fuente
              fontWeight: 400, // Grosor de la fuente
              display: "flex",
              color:
                activeStep > index
                  ? "#44291e" // Marrón para pasos completados
                  : activeStep === index
                  ? "#003147" // Azul para paso activo
                  : "#ffffff", // Blanco para pasos inactivos
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Sora",
              color:
                activeStep > index
                  ? "#44291e" // Marrón para pasos completados
                  : activeStep === index
                  ? "#003147" // Azul para paso activo
                  : "#ffffff", // Blanco para pasos inactivos
              fontSize: "0.9rem",
              fontWeight: "400",
            }}
          >
            {label}
          </Typography>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
));

export default CustomStepper;
