import "./Tag.css"

interface Tag {
    id_tag: number,
    nombre_tag: string,
}

export default function TagComponente (tag: Tag) {
    return(
        <button className='boton-tag'>
            {tag.nombre_tag}
        </button>
    )
};