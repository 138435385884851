import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cabecera from "../components/home/Cabecera";
import { Typography, Box, Button, Skeleton, Fab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Nav from "../components/home/Nav";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import Posteo from "../components/foro/posteo/Posteo";
import TagComponente from "../components/pure/Tag";
import {
  estaSuscriptoMeriendistaAForo,
  getInfoForo,
  getPosteos,
  postSuscripcion,
} from "../App/Services/foroService";
import { useAuth } from "../context/AuthContext";
import AddIcon from "@mui/icons-material/Add";
import ModalNuevoPost from "../components/Modales/Foro/Post/ModalNuevoPost";
import Foro, { logoForoPorId } from "./Foro";

export default function ConsultarForo() {
  const { id_foro } = useParams();
  const [foro, setForo] = useState<any>();
  const [posteos, setPosteos] = useState<any[]>([]);
  const [estaSuscripto, setEstaSuscripto] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const usuario = useAuth();
  const [tusForos, setTusForos] = useState<Foro[]>([]);
  const [openModalNuevoPost, setOpenModalNuevoPost] = useState<boolean>(false);
  const id_foro_number = parseInt(id_foro ?? "0", 10);

  const fetchForoData = async () => {
    if (id_foro) {
      try {
        const foroData = await getInfoForo(id_foro_number);
        setForo(foroData);

        if (usuario?.user?.uid) {
          const estaSuscripto = await estaSuscriptoMeriendistaAForo(
            id_foro_number,
            usuario.user.uid
          );
          setEstaSuscripto(estaSuscripto);
        } else {
          console.error("El usuario no está definido o no tiene un UID");
          setEstaSuscripto(false);
        }
      } catch (error) {
        console.error("Error al consultar foro o posteos:", error);
      }
    }
  };

  const fetchPosteos = async () => {
    try {
      const posteos = await getPosteos(id_foro_number);
      setPosteos(posteos);
    } catch (error) {
      console.error("Error al consultar posteos:", error);
    }
  };

  useEffect(() => {
    try {
      fetchForoData();
      fetchPosteos();
    } finally {
      setLoading(false);
    }
  }, [id_foro, usuario.user]);

  useEffect(() => {
    if (openModalNuevoPost == false) {
      setLoading(true);
      try {
        fetchPosteos();
      } finally {
        setLoading(false);
      }
    }
  }, [openModalNuevoPost]);

  const handleUnise = () => {
    if (usuario?.user?.uid && !estaSuscripto) {
      postSuscripcion(usuario?.user?.uid, id_foro_number)
        .then((res) => fetchForoData())
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="inicio">
      <Cabecera es_home={false} />

      <Grid
        container
        alignItems="center" // Alinea verticalmente los elementos
        justifyContent="space-between"
        spacing={1}
        sx={{
          margin: "0 2rem",
        }}
      >
        {/* Contenedor principal con logo, nombre y miembros */}
        <Grid
          container
          display="flex"
          alignItems="center"
          flex={1}
          wrap="nowrap"
        >
          {/* Logo */}
          <Grid sx={{ mr: 2 }}>
            {logoForoPorId.get(foro?.tematica.id_tematica)}
          </Grid>

          {/* Nombre del foro y cantidad de miembros */}
          <Grid sx={{ flexGrow: 1, minWidth: 0 }}>
            {loading ? (
              <Skeleton width={150} height={40} />
            ) : (
              <Typography
                sx={{
                  fontFamily: "Higuen",
                  color: "#44291E",
                  fontSize: "1.5625rem",
                  fontWeight: "400",
                  marginBottom: "0.3rem",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  whiteSpace: "normal",
                  maxWidth: "100%",
                }}
              >
                {foro?.nombre}
              </Typography>
            )}

            <Grid display="flex" alignItems="center">
              {loading ? (
                <Skeleton width={100} height={20} />
              ) : (
                <>
                  <TagComponente
                    id_tag={foro?.tematica.id_tematica}
                    nombre_tag={foro?.tematica.nombre}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Sora",
                      fontSize: "0.9rem",
                      fontWeight: "400",
                      ml: "0.5rem",
                    }}
                  >
                    {foro?.cantidad} miembros
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Botón "Unirme" */}
        <Grid sx={{ flexShrink: 0 }}>
          {loading ? (
            <Skeleton variant="rectangular" width={100} height={40} />
          ) : (
            <Button
              variant="contained"
              sx={{
                background: estaSuscripto ? "#DAB587" : "#FFF",
                color: "black",
                borderRadius: "1.625rem",
                textTransform: "none",
                padding: "0.25rem 1rem",
                fontSize: "0.875rem",
                height: "fit-content",
                ":disabled": "#DAB587",
              }}
              onClick={() => handleUnise()}
              disabled={estaSuscripto}
            >
              {estaSuscripto ? "Sos parte" : "Unirme"}
            </Button>
          )}
        </Grid>

        {/* Descripción del foro */}
        <Grid sx={{ width: "100%" }}>
          {loading ? (
            <Skeleton width="100%" height={50} />
          ) : (
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.9rem",
                fontWeight: "400",
              }}
            >
              {foro?.descripcion}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Posteos */}
      {loading ? (
        <>
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </>
      ) : posteos?.length ? (
        posteos.map((posteo: any) => (
          <Posteo
            key={posteo.id}
            id_foro={Number(id_foro)}
            id={posteo.id_post}
            avatar={posteo.info_meriendista.avatar}
            nombre={posteo.info_meriendista.nombre_meriendista}
            apellido={posteo.info_meriendista.apellido_meriendista}
            fecha={posteo.info_meriendista.fecha_creacion}
            titulo={posteo.titulo}
            descripcion={posteo.descripcion}
            cantidad_likes={posteo.cant_likes}
            cantidad_dislikes={posteo.cant_dislikes}
            cantidad_comentarios={posteo.cant_comentarios}
          />
        ))
      ) : (
        <Box className="sin-recomendaciones">
          <Typography
            sx={{
              fontFamily: "Sora",
              color: "#003147",
              fontSize: "0.9375rem",
              fontWeight: 600,
            }}
          >
            Sé el primero en hacer un posteo
          </Typography>
        </Box>
      )}
      <Fab
        size="large"
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "5rem", // Ajusta según la separación que desees del borde inferior
          right: "2rem", // Ajusta según la separación que desees del borde derecho
          zIndex: 1000, // Asegura que el botón esté por encima de otros elementos
          backgroundColor: "#003147", // Color personalizado
          "&:hover": {
            backgroundColor: "#002236", // Color al hacer hover
          },
        }}
        onClick={() => setOpenModalNuevoPost(true)}
      >
        <AddIcon />
      </Fab>
      <ModalNuevoPost
        id_foro={Number(id_foro)}
        open={openModalNuevoPost}
        setOpen={setOpenModalNuevoPost}
      />
      <Nav select={0} />
    </div>
  );
}
