import { useNavigate } from "react-router-dom";

const useHandleSearch = () => {
  const navigate = useNavigate();

  const handleSearch = (query: string) => {
    if (query) {
      navigate(`/resultados?search=${query}`);
    }
  };

  return handleSearch;
};

export default useHandleSearch;
