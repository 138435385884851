import { Button, Grid, Link, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { marcarFavorita } from "../../App/Services/cafeteriaService";
import { useNavigate } from "react-router-dom";

interface IReseña_Favorito {
    id_cafeteria: number;
    favorito: boolean | undefined
    setFavorito: Dispatch<React.SetStateAction<boolean | undefined>>
}

export default function Reseña_Favorito (props: IReseña_Favorito) {
    //redireccionar a la paginaque permita crear reseña
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/reseñas/${props.id_cafeteria}`);
  }
  const usuario = useAuth();
  const handleClickFavorito = () => {
    props.setFavorito(!props.favorito);
  }
  return (
    <Grid container className="Reseña-Fav" alignItems={"center"}>
        <Grid item xs={9}>
            <Button
                sx={{
                    height: "1.5rem",
                    width: '100%',
                    background: "rgba(255, 255, 255, 0.57)",
                    borderRadius: "0.5rem",
                    textTransform: 'none'
                }}
                endIcon={<img src="/assets/consultarCafeteria/estrellaDeReseñasIcon.svg" alt="Estrella"/>}
                onClick={handleClick}
                >
                <Typography
                    sx={{
                        height: "0.938rem",
                        marginLeft: "0.12rem",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "#000",
                        fontFamily: "Sora",
                        fontSize: "0.75rem",
                        fontWeight: "600",
                    }}
                >
                    ¿Ya visitaste este lugar? Dejá tu reseña
                </Typography>
            </Button>
        </Grid>
        <Grid item xs={1}>
            <ListItemButton
                onClick={handleClickFavorito}>
                <ListItemIcon>
                    {props.favorito == true ? (
                    <img
                        src="/assets/consultarCafeteria/favoritoIcon.svg"
                        alt="Estrella"
                    />) 
                    : ( 
                    <img
                        src="/assets/consultarCafeteria/noFavoritoIcon.svg"
                        alt="Estrella"
                    />)}
                </ListItemIcon>
            </ListItemButton>
        </Grid>
    </Grid>
  );
};
