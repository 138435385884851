import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

export interface Evento {
  id: number;
  nombre: string;
  imagen: string;
  cantidad_estrellas: number;
  direccion: string;
  descripcion: string;
  fecha: number;
  hora: string;
}

const Evento: React.FC<Evento> = (evento) => {
    const navigate = useNavigate();
    const date = new Date(evento.fecha);

    // Formatear la fecha a 'día/mes'
    const dia = date.getUTCDate().toString().padStart(2, '0');
    const mes = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11

    const fechaFormateada = `${dia}/${mes}`;
  

  const handleConsultarEvento = (id_evento: number) => {
    //crear un loader para poder activarlo y que no seleccionen como loquitos
    navigate(`/evento/${id_evento}`);
  }

  return (
    <Card
      key={evento.id}
      sx={{
        height: "auto",
        backgroundColor: "#44291E",
        borderRadius: "1.5625rem",
        padding: "1rem 0.8rem",
        mb: "1rem",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", //Sombreado para la card
      }}
    >
      <CardActionArea
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
        onClick={() => handleConsultarEvento(evento.id)}
      >
        <Grid container justifyContent={"center"} spacing={1}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia
                component="img"
                sx={{ height: "7.125rem", width: '100%', objectFit: 'cover', borderRadius: "1.125rem" }}
                image={evento.imagen != null ? `data:image/bmp;base64,${evento.imagen}` : "/assets/homeCafeteria/ImgEventoDefault.png"}

                title={evento.nombre}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems={"center"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Higuen",
                    color: "#FFF",
                    fontSize: "1.125rem",
                  }}
                >
                  {evento.nombre}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                    sx={{
                    fontFamily: "Sora",
                    color: "#FFF",
                    fontSize: "0.625rem",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3, // Limita a 3 líneas
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    }}
                >
                    {evento.descripcion}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{background: '#003147', borderRadius: '0.4375rem', mt:'0.2rem', padding:'0.2rem 0.3rem'}}>
                <Grid container flexDirection={'column'}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon fontSize="inherit" sx={{color: '#FFF', mr: '0.3rem'}}/>
                        <Typography
                            sx={{
                                fontFamily: "Sora",
                                color: "#FFF",
                                fontSize: "0.5625rem",
                                fontWeight: '600'
                            }}
                        >
                            {fechaFormateada} - {evento.hora}hs
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <FmdGoodOutlinedIcon fontSize="inherit" sx={{color: '#FFF', mr: '0.3rem'}}/>
                        <Typography
                            sx={{
                                fontFamily: "Sora",
                                color: "#FFF",
                                fontSize: "0.5625rem",
                                fontWeight: '600'
                            }}
                        >
                            {evento.direccion}
                        </Typography>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default Evento;
