import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import EditIcon from "@mui/icons-material/Edit";

export interface Evento {
  id: number;
  nombre: string;
  datos_foto: string;
  direccion: string;
  descripcion: string;
  fecha: number;
  hora: string;
  puede_editar: boolean;
}

const EventoCafeteria: React.FC<Evento> = (evento) => {
  const navigate = useNavigate();
  const date = new Date(evento.fecha);

  // Formatear la fecha a 'día/mes'
  const dia = date.getUTCDate().toString().padStart(2, "0");
  const mes = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const fechaFormateada = `${dia}/${mes}`;

  const handleConsultarEvento = (id_evento: number) => {
    navigate(`/evento-cafeteria/${id_evento}`);
  };

  const handleEditarEvento = (id_evento: number) => {
    navigate(`/modificar-evento/${id_evento}`);
  };


  return (
    <Card
      key={evento.id}
      sx={{
        position: "relative",
        height: "9.125rem",
        width: "100%",
        backgroundColor: "#44291E",
        borderRadius: "1.5625rem",
        padding: "0 0.8rem",
        mb: "1rem",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      {/* Botón de editar */}
      {evento.puede_editar && (
        <IconButton
          sx={{
            position: "absolute",
            top: "0.5rem",
            right: "0.5rem",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
            zIndex: "2",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Evitar que se active el evento del área de acción
            handleEditarEvento(evento.id);
          }}
        >
          <EditIcon sx={{ color: "#44291E" }} />
        </IconButton>
      )}

      <CardActionArea
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
        onClick={() => handleConsultarEvento(evento.id)}
      >
        <Grid container justifyContent={"center"} spacing={1}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%", // Mantener imagen alineada
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: "7.125rem",
                width: "100%",
                objectFit: "cover", // Imagen ajustada al contenedor
                borderRadius: "1.125rem",
                margin: "0.8rem 0",
              }}
              image={evento.datos_foto != null ? `data:image/bmp;base64,${evento.datos_foto}` : "/assets/homeCafeteria/ImgEventoDefault.png"}
              title={evento.nombre}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems={"center"}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    display: "-webkit-box", // Configurar el contenedor para manejar múltiples líneas
                    WebkitBoxOrient: "vertical", // Orientar el contenedor en forma vertical
                    WebkitLineClamp: 2, // Limitar a 2 líneas
                    overflow: "hidden", // Ocultar texto excedente
                    textOverflow: "ellipsis", // Mostrar ellipsis para texto truncado
                    width: "100%", // Ancho completo
                    height: "2.4rem", // Altura fija
                    fontFamily: "Higuen",
                    color: "#FFF",
                    fontSize: "1.125rem",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal", // Asegurar un espaciado uniforme entre líneas
                    margin: "0.5rem 0",
                  }}
                >
                  {evento.nombre}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    display: "-webkit-box", // Habilita recorte de texto en varias líneas
                    WebkitBoxOrient: "vertical", // Orientación del contenedor en vertical
                    WebkitLineClamp: 3, // Limitar a 3 líneas
                    overflow: "hidden", // Ocultar texto adicional
                    textOverflow: "ellipsis", // Mostrar elipsis para texto truncado
                    width: "100%", // Ancho completo del contenedor
                    height: "2.625rem", // Altura fija
                    fontFamily: "Sora",
                    fontSize: "0.625rem", // Tamaño de fuente
                    color: "#FFF", // Color de texto
                    lineHeight: "0.875rem", // Espaciado entre líneas
                  }}
                >
                  {evento.descripcion}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  background: "#003147",
                  borderRadius: "0.4375rem",
                  mt: "0.2rem",
                  padding: "0.2rem 0.3rem",
                  Width: "9.4375rem",
                }}
              >
                <Grid container flexDirection={"column"}>
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon
                      fontSize="inherit"
                      sx={{ color: "#FFF", mr: "0.3rem" }}
                    />
                    <Typography
                      sx={{
                        display: "inline-block", // Garantiza que ocupe solo una línea
                        whiteSpace: "nowrap", // Evita saltos de línea
                        overflow: "hidden", // Oculta el contenido adicional si se desborda
                        textOverflow: "ellipsis", // Muestra los puntos suspensivos si el texto es muy largo
                        width: "9.4375rem", // Ancho completo del contenedor
                        height: "0.67rem", // Altura fija
                        fontFamily: "Sora", // Fuente
                        fontSize: "0.5625rem", // Tamaño de fuente
                        color: "#FFF", // Color del texto
                        lineHeight: "0.67rem", // Altura de la línea
                        fontWeight: "600", // Peso de la fuente
                      }}
                    >
                      {fechaFormateada} - {evento.hora}hs
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    <FmdGoodOutlinedIcon
                      fontSize="inherit"
                      sx={{ color: "#FFF", mr: "0.3rem" }}
                    />
                    <Typography
                      sx={{
                        display: "inline-block",
                        whiteSpace: "nowrap", // Evita saltos de línea
                        overflow: "hidden", // Oculta texto excedente
                        textOverflow: "ellipsis", // Muestra puntos suspensivos
                        width: " 8.10356rem", // Ancho completo del contenedor
                        height: "0.67rem", // Altura fija
                        fontFamily: "Sora", // Fuente
                        fontSize: "0.5625rem", // Tamaño de fuente
                        color: "#FFF", // Color del texto
                        lineHeight: "0.67rem", // Espaciado entre líneas
                        fontWeight: "600", // Peso de la fuente
                      }}
                    >
                      {evento.direccion}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default EventoCafeteria;
