import React, { useEffect, useState } from "react";
import "../../styles/Inicio.css";
import NavCafeteria from "../../components/home/NavCafeteria";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { getCafeteria, getTags } from "../../App/Services/cafeteriaService";
import { tieneCafeteriaAsociada } from "../../App/Services/UsuarioService/UsuarioService";
import Evento from "../../components/eventos/Evento";
import {
  crearEvento,
  getEventosCafeteria,
} from "../../App/Services/Cafeteria/eventosServices";
import ReusableModal, { ModalButton } from "../../components/Modales/Modal";
import CabeceraCafeteria from "../../components/home/CabeceraCafeteria";

const RegistrarEventoCafeteria: React.FC = () => {
  const usuario = useAuth();
  const [cafeteria, setCafeteria] = useState<any>(null);
  const [nombre, setNombre] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");
  const [direccion, setDireccion] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [fecha, setFecha] = useState<Date | null>(null);
  const [hora, setHora] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [ubicacionCafeteria, setUbicacionCafeteria] = useState<boolean>(false);
  const [imagenesEvento, setImagenesEvento] = useState<any[]>([]); // Estado para la imagen
  const [errorImg, setErrorImg] = useState("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false); // Nuevo estado
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<ModalButton[]>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDatosCafeteria = async () => {
      if (usuario.user) {
        try {
          const cafeteriaAsociada = await tieneCafeteriaAsociada(
            usuario.user.uid
          );
          const datosCafeteria = await getCafeteria(
            cafeteriaAsociada.id_cafeteria
          );
          setCafeteria(datosCafeteria);
        } catch (error) {
          console.error("Error al obtener datos de la cafetería:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchDatosCafeteria();
  }, [usuario]);

  useEffect(() => {
    const isValid =
      nombre.trim() !== "" &&
      descripcion.trim() !== "" &&
      direccion.trim() !== "" &&
      fecha !== null &&
      hora !== null;
    setIsFormValid(isValid);
  }, [nombre, descripcion, direccion, fecha, hora, imagenesEvento]);

  useEffect(() => {
    if (ubicacionCafeteria) {
      setDireccion(cafeteria.direccion);
    }
  }, [ubicacionCafeteria]);
  

  // Funciones onChange para los campos
  const handleNombreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(e.target.value);
  };

  const handleDescripcionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescripcion(e.target.value);
  };

  const handleDireccionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDireccion(e.target.value);
  };

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const handleFechaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFecha = e.target.value;

    // Verifica que el valor tenga el formato correcto (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(inputFecha)) {
      const newDate = new Date(inputFecha);
      // Verifica si el objeto Date es válido
      if (!isNaN(newDate.getTime())) {
        setFecha(newDate);
      } else {
        return; // Si la fecha no es válida
      }
    } else {
      return; // Si el formato no es válido
    }
  };

  const handleHoraChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(":").map(Number);
    const newHora = new Date();
    newHora.setHours(hours, minutes, 0, 0); // Establece hora:minuto:segundos:milisegundos
    setHora(newHora);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUbicacionCafeteria(e.target.checked);
  };

  const handleContinuar = () => {
    navigate("/eventos-cafeteria");
  };

  const handleGuardarEvento = () => {
    // Aquí agregarías la lógica para guardar el evento, incluyendo la imagen.
    if (usuario.user?.uid && fecha && hora) {
      crearEvento(
        usuario.user?.uid,
        cafeteria.id_cafeteria,
        nombre,
        descripcion,
        fecha.toISOString().split("T")[0],
        hora.toTimeString().slice(0, 5),
        direccion,
        imagenesEvento,
        link
      )
        .then((res) => {
          setTituloModal("Éxito");
          setContenidoModal("El evento se ha registrado correctamente");
          setBotonesModal([
            {
              label: "Continuar",
              onClick: handleContinuar,
              sx: {
                background: "#F0ECE3",
                color: "#44291E",
                borderRardius: "0.9375rem",
              },
            },
          ]);
        })
        .catch((error) => {
          setTituloModal("Error");
          setContenidoModal("Error al registrar el evento, intente nuevamente");
          setBotonesModal([
            {
              label: "Cancelar",
              onClick: handleContinuar,
              sx: {
                background: "#44291E",
                color: "#F0ECE3",
                borderRardius: "0.9375rem",
              },
            },
            {
              label: "Reintentar",
              onClick: () => setOpenModal(false),
              sx: {
                background: "#F0ECE3",
                color: "#44291E",
                borderRardius: "0.9375rem",
              },
            },
          ]);
        })
        .finally(() => setOpenModal(true));
    }
  };

  return (
    <div className="registrar-cafeteria">
      <CabeceraCafeteria nombre_cafeteria={cafeteria?.nombre} />

      {/* Título */}
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontFamily: "Sora",
            color: "#44291E",
            fontSize: "1.5rem",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          ¡Registremos tu evento!
        </Typography>
        <Typography
          sx={{
            fontFamily: "Sora",
            fontSize: "0.6875rem",
            fontWeight: "300",
            textAlign: "center",
          }}
        >
          (*) Indica que el campo es obligatorio
        </Typography>
      </Box>

      {/* Formulario */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          gap: "1.2rem",
          backgroundColor: "#FFF",
          p: "1rem",
          pb: "5rem",
          borderRadius: "1.75rem",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          overflow: "scroll",
        }}
      >
        {/* Nombre del evento */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Nombre del evento *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              value={nombre}
              placeholder="Taller de tostado de café"
              onChange={handleNombreChange}
              inputProps={{
                maxLength: 25, // Máximo de 50 caracteres
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>
        {/* Fecha y Hora */}
        <Grid container spacing={2}>
          {/* Fecha */}
          <Grid item xs={6} sm={6}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Fecha *
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              value={fecha ? fecha.toISOString().split("T")[0] : ""}
              onChange={handleFechaChange}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate() + 1)) // Fecha mínima: mañana
                  .toISOString()
                  .split("T")[0],
              }}
              onKeyDown={(e) => e.preventDefault()} // Evita que el usuario escriba manualmente
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1887F", // Color del borde al pasar el mouse
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D7CCC8", // Color del borde inicial
                },
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Hora *
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="time"
              value={
                hora
                  ? hora
                      .toLocaleTimeString("en-US", { hour12: false })
                      .substring(0, 5)
                  : ""
              }
              onChange={handleHoraChange}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A1887F", // Color del borde al pasar el mouse
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D7CCC8", // Color del borde inicial
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Dirección */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Dirección *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Obispo Trejo 1094"
              value={direccion}
              onChange={handleDireccionChange}
              inputProps={{
                maxLength: 20, // Máximo de 50 caracteres
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={ubicacionCafeteria}
              onChange={handleCheckboxChange}
            />
          }
          label="Es la misma dirección que la cafetería"
          sx={{
            fontFamily: "Sora",
            fontSize: "0.875rem",
            color: "#44291E",
            "& .MuiCheckbox-root": {
              color: "#44291E",
            },
          }}
        />

        {/* Descripción */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Descripción *
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={descripcion}
              onChange={handleDescripcionChange}
              placeholder="Cerámica y café"
              inputProps={{
                maxLength: 500, // Máximo de 50 caracteres
              }}
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Link */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Link del evento (opcional)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              value={link}
              onChange={handleLinkChange}
              placeholder="www.miseventos.com"
              sx={{
                backgroundColor: "#FBF7F3",
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Cargar imagen */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: "0.875rem",
                fontWeight: "600",
                color: "#44291E",
              }}
            >
              Cargar imagen (opcional)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label
              htmlFor="upload-images"
              className="boton-chiquito"
              style={{
                padding: "5px",
                cursor: "pointer",
                background: "#e0e0e0",
                borderRadius: "5px",
              }}
            >
              Subir
            </label>
            <input
              id="upload-images"
              type="file"
              multiple
              accept="image/*"
              onChange={async (e) => {
                const files = e.target.files;
                if (!files) return;

                const MAX_SIZE_MB = 10;
                let totalSize = 0;
                const imagesWithHeader = [...imagenesEvento]; // Mantener las imágenes previas

                for (const file of Array.from(files)) {
                  totalSize += file.size / 1024 / 1024; // Tamaño en MB
                  if (totalSize > MAX_SIZE_MB) {
                    setErrorImg(
                      "El tamaño total de las imágenes no debe superar los 10 MB."
                    );
                    return;
                  }

                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  await new Promise<void>((resolve) => {
                    reader.onload = () => {
                      // Guardar con el formato requerido
                      imagesWithHeader.push({ datos: reader.result as string });
                      resolve();
                    };
                  });
                }

                setImagenesEvento(imagesWithHeader);
                setErrorImg(""); // Limpiar mensaje de error si las imágenes se cargaron correctamente
              }}
              style={{
                display: "none",
              }}
            />
            {errorImg != "" && <p style={{ color: "red" }}>{errorImg}</p>}
          </Grid>
          <Grid item xs={12}>
            {/* Mostrar vista previa de imágenes con botón de eliminación */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                marginTop: "1rem",
                justifyContent: "center",
              }}
            >
              {imagenesEvento.map((imageObj, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <img
                    src={imageObj.datos}
                    alt={`Imagen ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                  <button
                    onClick={() => {
                      const updatedImages = imagenesEvento.filter(
                        (_, i) => i !== index
                      );
                      setImagenesEvento(updatedImages);
                    }}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    ×
                  </button>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Botón de Guardar */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            disabled={!isFormValid}
            sx={{
              backgroundColor: "#44291E",
              borderRadius: "10px",
              textTransform: "none",
              fontFamily: "Sora",
              fontSize: "1rem",
              fontWeight: "600",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "#A1887F",
              },
            }}
            onClick={handleGuardarEvento}
          >
            Guardar evento
          </Button>
        </Box>
      </Box>
      <ReusableModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
      <NavCafeteria select={0} />
    </div>
  );
};

export default RegistrarEventoCafeteria;
