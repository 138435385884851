import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css"; 
import { useAuth } from "../../context/AuthContext";

const HomePage: React.FC = () => {

  const auth = useAuth();

  return (
    <div className="home-container">
      <div className="logo">
        <img src="/cofflickLogo.png" alt="Cofflick" />
      </div>
      <div className="rectanguloAzul">
        <div className="buttons">
          <Link to="/signup">
            <button className="create-account">Crear una cuenta</button>
          </Link>
          <Link to="/login">
            <button className="login">Iniciar sesión</button>
          </Link>
  
        </div>
        <div className="div-span">
          <span className="span">O</span>
        </div>
        <button onClick={auth.iniciarConGoogle} className="google-login">
          Continuar con Google
        </button>
      </div>
    </div>
  );
};

export default HomePage;
