import React, { createContext, useContext } from "react";
import { useLoadScript, Libraries } from "@react-google-maps/api";

const libraries: Libraries = ["places", "geometry", "drawing", "marker"];
const GoogleMapsContext = createContext<{ isLoaded: boolean }>({ isLoaded: false });

export const GoogleMapsProvider: React.FC<{ apiKey: string; children: React.ReactNode }> = ({ apiKey, children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  return <GoogleMapsContext.Provider value={{ isLoaded }}>{children}</GoogleMapsContext.Provider>;
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
