import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton, Input, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../styles/LoginPage.css"; // Importar los estilos CSS
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { validarCorreo } from "../components/common/UtilidadesForm/ValidacionesCampos";


const RecuperarPage: React.FC = () => {
  const auth = useAuth();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [respuesta, setRespuesta] = useState<string | undefined>(undefined);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleRecuperar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    auth.recuperarContrasenia(email!)
        .then(() => setRespuesta("Revise la bandeja de su correo electrónico"))
        .catch((error) => setRespuesta("Error al enviar el mail"))
    };

  useEffect(() => {
    if (email != undefined) {
      setErrorEmail(!validarCorreo(email!));
      setRespuesta(undefined);
    }
    }
    ,[email]);

  return (
    <div className="background">
      <div className="textoBienvenida">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          className="bienvenido"
          sx={{ fontFamily: "Lora, serif" }} // Asignar la fuente Lora
        >
          No te preocupes!
        </Typography>
      </div>
      <div className="rectanguloAzulLogin">
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className="labelIniciarSesion"
        >
          Recuperar Cuenta
        </Typography>
        <Typography
          gutterBottom
          margin={'0.5rem'}
          className="labelRecuperar"
        >
          Enviaremos un mail a tu correo para restablecer tu contraseña
        </Typography>
        <Box component="form" className="form" noValidate autoComplete="off" sx={{ m: 1 }}>
          <TextField
            className="text"
            id="standard-basic"
            label="Email"
            variant="standard"
            placeholder="email@gmail.com"
            size={"small"}
            sx={{
              margin: "0.5rem",
              //color de la línea inferior del "Email"
              "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after":
                {
                  borderBottomColor: "white", // Color de la línea por defecto
                },
              "& .MuiInputLabel-root": {
                color: "#fff", // Color del label por defecto
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#fff", // Color del label cuando el TextField está enfocado
              },
              input: {
                color: "white", // Color del texto del input
              },
              "& .MuiInputBase-input::placeholder": {
                color: "white", // Color del placeholder
                opacity: 0.5, // Opacidad del placeholder
              },
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errorEmail && ( 
            <Typography
            color="#F00"
            sx={{ fontFamily: "Sora"}}
            >
              No es un mail válido
            </Typography>
          )}
          {respuesta != undefined && ( 
            <Typography
            color={respuesta === "Error al enviar el mail" ? "#F00" : "#FFF"}
            sx={{ fontFamily: "Sora"}}

            >
              {respuesta}
            </Typography>
          )}

         
          <button disabled={!(email && !errorEmail && respuesta===undefined)} onClick={handleRecuperar} className="custom-button">Enviar mail</button>
        </Box>
      </div>
    </div>
  );
};

export default RecuperarPage;