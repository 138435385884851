import axios from "axios";
import { BASE_URL } from "./config";

export const getForosRecomendados = async (): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/recomendados`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const deleteForo = async (id_foro: number): Promise<any> => {
  try {
    const response = await axios.delete(`${BASE_URL}/foro/${id_foro}`);
    return response.data; // Retornar la respuesta en caso de éxito
  } catch (error: any) {
    console.error(`Error eliminando el foro con ID: ${id_foro}`, error);
    throw error; // Lanza el error para que el llamado maneje la excepción
  }
};

export const getForosMeriendista = async (
  uid_meriendista: string
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/propios/${uid_meriendista}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getForosComunidad = async (): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/comunidad`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getForosSuscriptos = async (
  uid_meriendista: string
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/suscripcion/${uid_meriendista}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getInfoForo = async (id_foro: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/${id_foro}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const estaSuscriptoMeriendistaAForo = async (
  id_foro: number,
  uid_meriendista: string
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/suscripcion/${id_foro}/${uid_meriendista}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getPosteos = async (id_foro: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/${id_foro}/posts`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const buscarForos = async (
  palabraClave: string | undefined
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/buscador/${palabraClave}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

// Función para obtener los datos de un foro por su nombre, descripción y/o por su tematica
export const getForosPorNomYTematica = async (
  nombre?: string,
  id_tematica?: number
): Promise<any> => {
  // Crear un objeto para los parámetros de búsqueda
  const params: any = {};

  if (nombre) {
    params.palabraClave = nombre;
  }

  if (id_tematica) {
    params.idTematica = id_tematica; // Usar idTematica en lugar de tags según la documentación de Postman
  }

  // Convertir el objeto params en una query string
  const queryString = new URLSearchParams(params).toString();

  const response = await axios
    .get(`${BASE_URL}/foro/buscador?${queryString}`)
    .then((res) => res.data)
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getAllTematicas = async (): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/tematicas`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getComentariosPost = async (
  id_foro: number,
  id_post: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/${id_foro}/posts/${id_post}/comentarios`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const postInteraccion = async (
  uid: string,
  id_post: number,
  id_foro: number,
  id_tipo_interaccion: number,
  descripcion: string | null,
  id_interaccion_principal: number | null
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/foro/${id_foro}/posts/${id_post}/interacciones`, {
      id_post: id_post,
      id_tipo_interaccion: id_tipo_interaccion,
      descripcion,
      id_meriendista: uid,
      id_interaccion_principal: id_interaccion_principal,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getInfoReducidaForo = async (id_foro: number): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/${id_foro}/info`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getInfoPost = async (
  id_foro: number,
  id_post: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/${id_foro}/posts/${id_post}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const postPost = async (
  uid: string,
  id_foro: number,
  titulo: string,
  descripcion: string
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/foro/${id_foro}/posts/`, {
      nombre: titulo,
      descripcion: descripcion,
      id_meriendista: uid,
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error(
        "Error in postPost:",
        error.response?.data || error.message
      );
      throw new Error(error.response?.data || error.message);
    });

  return response;
};

export const postForo = async (
  uid: string,
  id_tematica: number,
  titulo: string,
  descripcion: string,
  esContenidoEducativo: boolean,
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/foro/`, {
      id_tematica: id_tematica,
      nombre: titulo,
      descripcion: descripcion,
      id_meriendista: uid,
      id_cafeteria: null,
      educativo: esContenidoEducativo,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error.response?.data || error.message);
    });

  return response;
};

export const postForoCafet = async (
  id_cafeteria: string,
  id_tematica: number,
  titulo: string,
  descripcion: string,
  esContenidoEducativo: boolean,
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/foro/`, {
      id_tematica: id_tematica,
      nombre: titulo,
      descripcion: descripcion,
      id_meriendista: null,
      id_cafeteria: id_cafeteria,
      educativo: esContenidoEducativo,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error.response?.data || error.message);
    });

  return response;
};

export const postSuscripcion = async (
  uid: string,
  id_foro: number
): Promise<any> => {
  const response = await axios
    .post(`${BASE_URL}/foro/suscripcion/${id_foro}/${uid}`)
    .then((res) => res.data)
    .catch((error) => {
      console.error(
        "Error in postPost:",
        error.response?.data || error.message
      );
      throw new Error(error.response?.data || error.message);
    });

  return response;
};

export const getForosEducativos = async (): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/foro/educativos`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};
