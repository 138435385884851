import React, { useEffect, useState } from "react";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Cabecera from "../components/home/Cabecera";
import "../styles/Inicio.css"; // Importar los estilos CSS
import Buscador from "../components/home/Buscador";
import Nav from "../components/home/Nav";
import { getTags } from "../App/Services/cafeteriaService";
import Grid from "@mui/material/Grid2";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Fab, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import SwipeToSlideGeneral from "../components/slider/SliderGeneral";
import TarjetaForo from "../components/foro/TarjetaForo";
import CoffeeMakerOutlinedIcon from "@mui/icons-material/CoffeeMakerOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AddIcon from "@mui/icons-material/Add";
import { getForosMeriendista, getForosRecomendados, getAllTematicas, getForosSuscriptos, getForosComunidad, getForosEducativos } from "../App/Services/foroService";
import { useAuth } from "../context/AuthContext";
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import useForoSearch from "../components/hooks/useForoSearch";
import SliderTematicas, { Tematica } from "../components/foro/sliderTematicas";
import KitchenIcon from '@mui/icons-material/Kitchen';
import CoffeeIcon from '@mui/icons-material/Coffee';
import GrainIcon from '@mui/icons-material/Grain';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import BookIcon from '@mui/icons-material/Book';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ForestIcon from '@mui/icons-material/Forest';
import PublicIcon from '@mui/icons-material/Public';
import BuildIcon from '@mui/icons-material/Build';
import HistoryIcon from '@mui/icons-material/History';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HomeIcon from '@mui/icons-material/Home';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BrushIcon from '@mui/icons-material/Brush';
import { useNavigate } from "react-router-dom";
import ModalNuevoForo from "../components/Modales/Foro/ModalNuevoForo";

export const logoForoPorId = new Map<number, JSX.Element>();
logoForoPorId.set(1, <KitchenIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Métodos de Preparación
logoForoPorId.set(2, <CoffeeIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Tipos de Café
logoForoPorId.set(3, <GrainIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Tostado y Molido
logoForoPorId.set(4, <LocalCafeIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Cafeteras
logoForoPorId.set(5, <BookIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Recetas de Café
logoForoPorId.set(6, <EmojiPeopleIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Cultura del Café
logoForoPorId.set(7, <ForestIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Café Sostenible
logoForoPorId.set(8, <PublicIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Café en el Mundo
logoForoPorId.set(9, <BuildIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Accesorios y Equipamiento
logoForoPorId.set(10, <HistoryIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Historia del Café
logoForoPorId.set(11, <RestaurantMenuIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Maridaje del Café
logoForoPorId.set(12, <HomeIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Café en Casa
logoForoPorId.set(13, <HealthAndSafetyIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Café y Salud
logoForoPorId.set(14, <TrendingUpIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Tendencias
logoForoPorId.set(15, <BrushIcon sx={{color:"#fff", borderRadius: '50%', backgroundColor: "#44291E"}}/>); // Art Latte


interface Foro {
  id_foro: number;
  nombre: string;
  descripcion: string;
  id_tematica: number;
}

const Foro: React.FC = () => {
  const [tematicas, setTematicas] = useState<any[]>([]);
  const handleSearch = useForoSearch();
  const [loading, setLoading] = useState<boolean>(true);
  const [forosRecomendados, setForosRecomendados] = useState<Foro[]>([]);
  const [tusForos, setTusForos] = useState<Foro[]>([]);
  const [forosSuscriptos, setForosSuscriptos] = useState<Foro[]>([]);
  const [forosComunidad, setForosComunidad] = useState<Foro[]>([]);
  const [contenidosEducativos, setContenidosEducativos] = useState<Foro[]>([]);
  const usuario = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModalNuevoForo, setOpenModalNuevoForo] = useState(false)

  const ITEM_HEIGHT = 48;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    fetchForosSuscriptos();
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = (id: number) => {
    navigate(`/foro/${id}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchForosSuscriptos = () => {
    if (usuario?.user?.uid) {
      // Verificamos que usuario y usuario.user estén definidos
      getForosSuscriptos(usuario.user.uid)
        .then((res: Foro[]) => {
          setForosSuscriptos(res);
        })
        .catch((error: any) => {
          console.error("Error al obtener los foros suscriptos:", error);
        });
    } else {
      console.error("El usuario no está autenticado o no tiene un UID");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Inicia el estado de carga

      try {
        // Ejecutar todas las llamadas en paralelo
        const [forosCom, tematicasRes, forosRecomendadosRes, tusForosRes, educativos] =
          await Promise.all([
            getForosComunidad(),
            getAllTematicas(),
            getForosRecomendados(),
            usuario?.user?.uid
              ? getForosMeriendista(usuario.user.uid)
              : Promise.resolve([]), // Si no hay UID, resolver con array vacío
            // getContenidosEducativos(),
            getForosEducativos(),
          ]);

        // Actualizar los estados con las respuestas
        setForosComunidad(forosCom);
        setTematicas(tematicasRes);
        setForosRecomendados(forosRecomendadosRes);
        setTusForos(tusForosRes);
        setContenidosEducativos(educativos);
      } catch (error) {
        console.error("Error al recuperar datos:", error);
      } finally {
        setLoading(false); // Finaliza la carga al completar todas las llamadas
      }
    };

    if (usuario?.user?.uid && openModalNuevoForo === false) {
      fetchData();
    } else {
      console.error("El usuario no está autenticado o no tiene un UID");
    }
  }, [usuario?.user?.uid, openModalNuevoForo]);

  const handleTematicaClick = (tematica: Tematica) => {
    if (tematica) {
      navigate(`/resultados-foro`, { state: { tematica } });
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: "#003147",
          width: "2.1875rem",
          height: "2.1875rem",
          border: "none",
          borderRadius: "0.5625rem",
          mt: "2rem",
          zIndex: 3,
          position: "fixed",
          top: 0 /* Alinea en la parte superior */,
          right: 0 /* Fija a la derecha */,
          mr: "2rem" /* Espaciado desde el borde derecho */,
          backgroundColor: "#FFF",
        }}
      >
        <ThumbUpOffAltOutlinedIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "15rem",
            },
          },
        }}
      >
        <MenuItem key={0} sx={{ my: "1.5rem", ml: "2.05rem" }}>
          <ThumbUpOffAltOutlinedIcon sx={{ color: "#003147" }} />
          <Typography sx={{ color: "#003147", fontWeight: "600" }}>
            Tus suscripciones
          </Typography>
        </MenuItem>
        {forosSuscriptos.map((foro) => (
          <MenuItem
            key={foro.id_foro}
            onClick={() => handleClickMenu(foro.id_foro)}
            sx={{
              borderBottom: "1px solid #ccc", // Agrega el borde debajo
              "&:last-child": {
                // Evita el borde en el último elemento
                borderBottom: "none",
              },
            }}
          >
            {logoForoPorId.get(foro.id_tematica)}
            <Typography
              sx={{ color: "#44291E", fontWeight: "600", ml: "0.2rem" }}
            >
              {foro.nombre}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <div className="inicio">
        <Cabecera es_home={false} />

        <Buscador label={"Buscar foros"} onEnter={handleSearch} />

        <div className="contenedor-carrusel">
          <SliderTematicas
            tematicas={tematicas}
            onClick={handleTematicaClick}
          />
        </div>
        {/* RECOMENDADOS */}
        <div className="contenedor-carrusel">
          <Grid
            container
            justifyContent={"initial"}
            sx={{ mb: "1rem" }}
            spacing={1}
          >
            <Grid>
              <AutoAwesomeOutlinedIcon sx={{ color: "#44291E" }} />
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#44291E",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Foros recomendados
              </Typography>
            </Grid>
          </Grid>
          <SwipeToSlideGeneral
            items={forosRecomendados}
            loading={loading}
            cantidadMostrar={1}
            renderItem={(foro: Foro) => (
              <TarjetaForo
                id={foro.id_foro}
                id_tematica={foro.id_tematica}
                titulo={foro.nombre}
                descripcion={foro.descripcion}
                colorTitulo={"#44291E"}
              />
            )}
          />
        </div>

        <div className="contenedor-carrusel">
          <Grid
            container
            justifyContent={"initial"}
            sx={{ mb: "1rem" }}
            spacing={1}
          >
            <Grid>
              <CampaignOutlinedIcon sx={{ color: "#003147" }} />
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Contenido educativo
              </Typography>
            </Grid>
          </Grid>
          <SwipeToSlideGeneral
            items={contenidosEducativos} //recomendados
            loading={loading}
            cantidadMostrar={1}
            renderItem={(foro: Foro) => (
              <TarjetaForo
                id={foro.id_foro}
                id_tematica={foro.id_tematica}
                titulo={foro.nombre}
                descripcion={foro.descripcion}
                colorTitulo={"#003147"}
              />
            )}
          />
        </div>

        {/* COMUNIDAD */}
        <div className="contenedor-carrusel">
          <Grid
            container
            justifyContent={"initial"}
            sx={{ mb: "1rem" }}
            spacing={1}
          >
            <Grid>
              <ConnectWithoutContactIcon sx={{ color: "#44291E" }} />
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#44291E",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Comunidad
              </Typography>
            </Grid>
          </Grid>
          <SwipeToSlideGeneral
            items={forosComunidad}
            loading={loading}
            cantidadMostrar={1}
            renderItem={(foro: Foro) => (
              <TarjetaForo
                id={foro.id_foro}
                id_tematica={foro.id_tematica}
                titulo={foro.nombre}
                descripcion={foro.descripcion}
                colorTitulo={"#44291E"}
              />
            )}
          />
        </div>

        {/* TUS FOROS */}
        <div className="contenedor-carrusel">
          <Grid
            container
            justifyContent={"initial"}
            sx={{ mb: "1rem" }}
            spacing={1}
          >
            <Grid>
              <CampaignOutlinedIcon sx={{ color: "#003147" }} />
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Tus foros
              </Typography>
            </Grid>
          </Grid>
          <SwipeToSlideGeneral
            items={tusForos}
            loading={loading}
            cantidadMostrar={1}
            renderItem={(foro: Foro) => (
              <TarjetaForo
                id={foro.id_foro}
                id_tematica={foro.id_tematica}
                titulo={foro.nombre}
                descripcion={foro.descripcion}
                colorTitulo={"#003147"}
              />
            )}
          />
        </div>
        <Fab
          variant="extended"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: "5rem", // Ajusta según la separación que desees del borde inferior
            right: "2rem", // Ajusta según la separación que desees del borde derecho
            zIndex: 1000, // Asegura que el botón esté por encima de otros elementos
            color: "#003147",
            backgroundColor: "#FFF", // Color personalizado
            "&:hover": {
              backgroundColor: "#002236", // Color al hacer hover
            },
            fontFamily: "Sora",
            fontSize: "0.75rem",
            textTransform: "none",
            fontWeight: 700,
          }}
          onClick={() => setOpenModalNuevoForo(true)}
        >
          Nuevo foro <AddIcon />
        </Fab>
        <ModalNuevoForo open={openModalNuevoForo} setOpen={setOpenModalNuevoForo}/>
        <Nav select={4} />
      </div>
    </>
  );
};

export default Foro;

