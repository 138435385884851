import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Libraries } from "@react-google-maps/api";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../styles/MapWithGeolocation.css";
import ComponenteCafeteriaParaVos from "../../components/pure/CafeteriaParaVos";
import { getCafeteriaReducida } from "../../App/Services/cafeteriaService";
import {
  getCiudadDeMeriendista,
  getCafeteriasCiudad,
  getNombreCiudad,
} from "../../App/Services/mapaService";
import { useAuth } from "../../context/AuthContext";
import { useGoogleMaps } from "./GoogleMapsProvider";

interface MapWithGeolocationProps {
  apiKey: string;
}

const libraries: Libraries = ["marker"];

const MapWithGeolocation: React.FC<MapWithGeolocationProps> = ({ apiKey }) => {
  const usuario = useAuth();
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(
    undefined
  );
  const [zoom, setZoom] = useState(18);
  const [userPosition, setUserPosition] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [selectedCafeteria, setSelectedCafeteria] = useState<any | null>(null);
  const [cafeterias, setCafeterias] = useState<any[]>([]);
  const [, setAnimationComplete] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [userMarker, setUserMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);
  const [mapReady, setMapReady] = useState(false);
  const [loadingCafeteria, setLoadingCafeteria] = useState(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [noCafeteriasFound, setNoCafeteriasFound] = useState(false); // Nuevo estado para el mensaje
  const { isLoaded } = useGoogleMaps();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setZoom(16);
          setUserPosition(pos);
          fetchCafeterias(pos.lat, pos.lng);
        },
        async (error) => {
          console.error("Error al obtener la geolocalización: ", error);
          await fetchCafeterias(); // Obtener ciudad y cafeterías en caso de error con la geolocalización
        },
        {
          enableHighAccuracy: true,
          timeout: 1000000,
          maximumAge: 0,
        }
      );
    } else {
      fetchCafeterias(); // Obtener ciudad y cafeterías si la geolocalización no está disponible
    }
  }, [usuario, isLoaded]);

  // Al cargar el mapa
  const onLoad = (map: google.maps.Map) => {
    setMapInstance(map);
    setMapReady(true);

    // Listener para detectar clics en el mapa
    google.maps.event.addListener(map, "click", () => {
      // Deseleccionar la cafetería
      setSelectedCafeteria(null);
    });

    if (userPosition) {
      const userMarker = renderAdvancedMarker(
        map,
        0,
        userPosition,
        "/assets/consultarCafeteria/Logo Cofflick (1).svg"
      );
      setUserMarker(userMarker);
    }
  };

  // Si hay una cafetería seleccionada, muestra los detalles
  const handleMarkerClick = useCallback(async (id_cafeteria: number) => {
    if (id_cafeteria === 0) {
      return;
    }

    setLoadingCafeteria(true);
    setAnimationStarted(true);

    try {
      const cafeteriaData = await getCafeteriaReducida(id_cafeteria);
      setSelectedCafeteria(cafeteriaData);

      setTimeout(() => {
        setAnimationComplete(true);
        setLoadingCafeteria(false);
      }, 400);
    } catch (error) {
      console.error("Error al obtener los datos de la cafetería:", error);
      setLoadingCafeteria(false);
    }
  }, []);

  const fetchCafeterias = useCallback(
    async (poslat?: number, poslng?: number): Promise<any> => {
      try {
        let nombreCiudad: string | null = null;
        let ciudadLatLng: { lat: number; lng: number } | null = null;

        // Verificar si se proporcionaron las coordenadas
        if (poslat !== undefined && poslng !== undefined) {
          // Obtener el nombre de la ciudad a partir de las coordenadas
          nombreCiudad = await getNombreCiudad(poslat, poslng, apiKey);
        } else if (usuario && usuario.user && usuario.user.uid) {
          // Obtener la ciudad a partir del usuario
          const ciudad = await getCiudadDeMeriendista(usuario.user.uid);
          nombreCiudad = ciudad.nombre;
          ciudadLatLng = {
            lat: parseFloat(ciudad.latitud),
            lng: parseFloat(ciudad.longitud),
          };
          setCenter(ciudadLatLng); // Actualizar el centro del mapa
        }

        if (nombreCiudad) {
          // Obtener las cafeterías de la ciudad detectada
          const cafes = await getCafeteriasCiudad(nombreCiudad);
          setCafeterias(cafes);
          setNoCafeteriasFound(cafes.length === 0); // Verificar si no se encontraron cafeterías
        }
      } catch (error) {
        console.error("Error al obtener las cafeterías: ", error);
      }
    },
    [apiKey, usuario]
  );

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setZoom(16);
          setUserPosition(pos);

          if (userMarker) {
            userMarker.map = null;
          }

          const newUserMarker = renderAdvancedMarker(
            mapInstance!,
            0,
            pos,
            "/assets/consultarCafeteria/Logo Cofflick (1).svg"
          );
          setUserMarker(newUserMarker);
        },
        (error) => {
          console.error("Error al obtener la geolocalización: ", error);
          if (userMarker) {
            userMarker.map = null;
            setUserMarker(null);
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 1000000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocalización no está disponible en este navegador.");
      if (userMarker) {
        userMarker.map = null;
        setUserMarker(null);
      }
    }
  };

  const renderAdvancedMarker = useCallback(
    (
      map: google.maps.Map,
      id_cafeteria: number,
      position: google.maps.LatLngLiteral,
      iconUrl: string
    ) => {
      if (!google?.maps?.marker?.AdvancedMarkerElement) {
        console.error("AdvancedMarkerElement no está disponible.");
        return null;
      }

      const createCustomMarkerContent = (imageUrl: string) => {
        const content = document.createElement("div");
        content.className = "custom-marker";
        content.style.backgroundImage = `url('${imageUrl}')`;
        content.style.width = "40px"; // Tamaño del ícono
        content.style.height = "40px"; // Tamaño del ícono
        content.style.backgroundSize = "cover";
        return content;
      };

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position,
        map,
        title: `Cafetería ${id_cafeteria}`,
        content: createCustomMarkerContent(iconUrl),
      });

      marker.addListener("click", () => {
        handleMarkerClick(id_cafeteria);
      });

      return marker;
    },
    [handleMarkerClick]
  );

  useEffect(() => {
    if (mapInstance && cafeterias.length > 0) {
      cafeterias.forEach((cafeteria) => {
        renderAdvancedMarker(
          mapInstance,
          cafeteria.id_cafeteria,
          { lat: Number(cafeteria.latitud), lng: Number(cafeteria.longitud) },
          "/assets/consultarCafeteria/Logo_Cofflick.svg"
        );
      });
    }
  }, [mapInstance, cafeterias, renderAdvancedMarker]);


  return (
    <div className="map-container">
      {noCafeteriasFound && (
        <div className="no-cafeterias-found-message">
          No se encontraron cafeterías en tu ubicación.
        </div>
      )}
      {center && (
        <GoogleMap
          id="map"
          mapContainerStyle={{ height: "100%", width: "100%" }}
          center={center}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            mapId: "80f094560809c165",
            minZoom: 10,
            maxZoom: 18,
          }}
          onLoad={onLoad} // Se asigna el evento onLoad
        />
      )}

      {mapReady && (
        <>
          <button
            onClick={() => updateLocation()}
            className={`location-button ${
              animationStarted
                ? selectedCafeteria
                  ? "move-up"
                  : "move-down"
                : ""
            }`}
          >
            <GpsFixedIcon />
          </button>

          {selectedCafeteria && (
            <div
              className={`cafeteria-details ${
                animationStarted ? "slide-up" : "slide-down"
              }`}
            >
              {loadingCafeteria ? (
                <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F5F5F5">
                  <Skeleton
                    style={{
                      height: "9.125rem",
                      width: "90%",
                      maxWidth: "600px",
                      minWidth: "200px",
                      margin: "0 auto",
                      borderRadius: "1.5625rem",
                      padding: "1rem 0.8rem",
                      marginBottom: "1rem",
                    }}
                  />
                </SkeletonTheme>
              ) : (
                <ComponenteCafeteriaParaVos
                  id_cafeteria={selectedCafeteria.id_cafeteria}
                  nombre_cafeteria={selectedCafeteria.nombre}
                  imagen={selectedCafeteria.imagen}
                  estado={selectedCafeteria.estado}
                  cantidad_estrellas={selectedCafeteria.calificacion}
                  direccion={selectedCafeteria.direccion}
                  tags={selectedCafeteria.tags}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MapWithGeolocation;
