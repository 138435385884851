import React from "react";
import {
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Grid,
  Collapse,
} from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface HorariosCafeteriaProps {
  estado: string;
  horariosHoy: { hora_apertura: string; hora_cierre: string };
  horarios: { dia: string; hora_apertura: string; hora_cierre: string }[];
  open: boolean;
  handleClick: () => void;
}

const HorariosCafeteria: React.FC<HorariosCafeteriaProps> = ({
  estado,
  horariosHoy,
  horarios,
  open,
  handleClick,
}) => {
  return (
    <>
      <ListItemButton
        sx={{
          px: "0",
          height: "1.25rem",
          width: "11rem",
          background: "rgba(255, 255, 255, 0.57)",
          borderRadius: "0.5rem",
        }}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: "1rem", maxWidth: "1rem" }}>
          <AccessTimeOutlinedIcon
            sx={{ width: "0.75rem", height: "0.75rem", pr: "" }}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography
            sx={{
              fontFamily: "Sora",
              fontSize: "0.65rem",
              marginLeft: "0.12rem",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            <span
              style={{
                color: estado === "Abierto" ? "#00CA69" : "#F00",
                fontWeight: 600,
              }}
            >
              {estado}
            </span>
            {horariosHoy.hora_apertura? 
            (` | ${horariosHoy.hora_apertura} a ${horariosHoy.hora_cierre}`) :
            ('')}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess sx={{}} /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid
          container
          alignItems={"center"}
          sx={{ background: "rgba(255, 255, 255, 0.95)", width: "11rem" }}
        >
          <Grid item xs={1}>
            <AccessTimeOutlinedIcon
              sx={{ color: "#44291E", width: "0.75rem", height: "0.75rem" }}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography
              sx={{
                fontFamily: "Sora",
                color: "#44291E",
                fontSize: "0.75rem",
                fontWeight: "600",
                alignContent: "center",
              }}
            >
              Horarios
            </Typography>
          </Grid>
          <Grid item>
            {horarios.map((horario) => (
              <Typography
                key={horario.dia}
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.6875rem",
                  fontWeight: "600",
                  alignContent: "center",
                }}
              >
                {horario.dia}: {horario.hora_apertura} - {horario.hora_cierre}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default HorariosCafeteria;
