import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Cabecera from "../components/home/Cabecera";
import "../styles/Inicio.css"; // Importar los estilos CSS
import Nav from "../components/home/Nav";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { getPerfilMeriendista } from "../App/Services/meriendistaService";
import DireccionCafeteria from "../components/cafeteria/DireccionCafeteria";
import SwipeToSlideCafeterias from "../components/home/SliderCafeterias";
import SwipeToSlide, { Tag } from "../components/home/SliderTags";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import SwipeToSlideReseña from "../components/reseña/SliderReseña";
import CoffeeIcon from "@mui/icons-material/Coffee";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalEditarPerfil from "../components/perfil/ModalEditarPerfil";
import { eliminarUsuario } from "../App/Services/UsuarioService/UsuarioService";
import ReusableModal, { ModalButton } from "../components/Modales/Modal";
import { useNavigate } from "react-router-dom";
import { avatarPorId } from "../components/common/const";
import RouteIcon from "@mui/icons-material/Route";
import SliderRutas from "../components/eventos/SliderRutas";
import BugReportIcon from '@mui/icons-material/BugReport';
import { deleteRuta } from "../App/Services/rutasEventosServices";
import {
  tieneCafeteriaAsociada,
} from "../App/Services/UsuarioService/UsuarioService";

const VerPerfil: React.FC = () => {
  const usuario = useAuth();
  const [datosUsuario, setDatosUsuario] = useState<any>({});
  const [rutasCafe, setRutasCafe] = useState<any[]>([]); // Nueva variable para las rutas de café
  const [loading, setLoading] = useState<boolean>(true);
  const [abrirEditarPerfil, setAbrirEditarPerfil] = useState<boolean>(false);
  const [modalConfirmacion, setModalConfirmacion] = useState<boolean>(false);
  const [tituloModal, setTituloModal] = useState<string>("");
  const [contenidoModal, setContenidoModal] = useState<string>("");
  const [botonesModal, setBotonesModal] = useState<ModalButton[]>();
  const navigate = useNavigate();

  // Nuevo estado para controlar si los botones de eliminación están activos
  const [eliminarActivo, setEliminarActivo] = useState<boolean>(false);

  // Función para activar/desactivar el modo de eliminación
  const toggleEliminarActivo = () => {
    setEliminarActivo((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchPerfil = async () => {
      if (usuario?.user?.uid) {
        setLoading(true);
        try {
          const res = await getPerfilMeriendista(usuario.user.uid);
          setDatosUsuario(res); // Actualizamos los datos del usuario

          // Si el endpoint también trae rutas de café, las seteamos aquí
          if (res.rutas) {
            setRutasCafe(res.rutas); // Guardamos las rutas de café en el estado
          }
        } catch (error) {
          console.error("Error al obtener datos del usuario", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("Usuario no definido o autenticación en proceso");
      }
    };

    fetchPerfil();
  }, [abrirEditarPerfil, usuario?.user?.uid]);

  const handleEliminarRuta = async (id: number) => {
    if (usuario?.user?.uid) {
      try {
        await deleteRuta(usuario.user.uid, id); // Llamamos a la API con el uid
        // Actualizar el estado después de eliminar
        setRutasCafe((prevRutas) => prevRutas.filter((ruta) => ruta.id !== id));
      } catch (error) {
        console.error("Error al eliminar la ruta:", error);
      }
    } else {
      console.error("UID no disponible");
    }
  };

  const handleBug = () => {
    window.open(`https://forms.gle/QFUSdibGrCAdp3kf8`, '_blank', 'noopener');
  }

  const handleBajaUsuario = () => {
    setTituloModal("¿Seguro que quieres dar de baja tu usuario?");
    setContenidoModal("Todos tus datos se perderán");
    setBotonesModal([
      {
        label: "No",
        onClick: () => setModalConfirmacion(false),
        sx: {
          background: "#44291E",
          color: "#F0ECE3",
          borderRardius: "0.9375rem",
        },
      },
      {
        label: "Sí",
        onClick: siBajaUsuario,
        sx: {
          background: "#F0ECE3",
          color: "#44291E",
          borderRardius: "0.9375rem",
        },
      },
    ]);
    setModalConfirmacion(true);
  };

  const handleCerrarSesion = () => {
    usuario
      .deslogueo()
      .then((res) => navigate(`/`))
      .catch((error) => console.log("error al desloguar"));
  };

  const siBajaUsuario = async () => {
    try {
      if (usuario && usuario.user && usuario.user.uid) {
        const res = await eliminarUsuario(usuario.user.uid);

        // Cerrar la sesión del usuario
        const res2 = await usuario.bajaUsuario(usuario.user);

        navigate(`/`);
      }
    } catch (error) {
      console.error("Error al dar de baja el usuario:", error);
      // Manejar el error según sea necesario
    }
  };

  // Pasar el objeto tag completo al hacer clic en el slider tags
  const handleTagClick = (tag: any) => {
    if (tag) {
      navigate(`/resultados`, { state: { tag } });
    }
  };

  const handleCloseModal = () => {
    setModalConfirmacion(false);
  };

  const handleVerificarCafeteria = async () => {
    if (!usuario?.user?.uid) {
      console.error("No se encontró el UID del usuario.");
      return;
    }
  
    try {
      const tieneCafeteria = await tieneCafeteriaAsociada(usuario.user.uid);
  
      console.log(tieneCafeteria.tiene_cafeteria_asociada)
      if (tieneCafeteria.tiene_cafeteria_asociada) {
        setTituloModal("Error")
        setContenidoModal("Usted ya posee una cafeteria asosiada")
        setBotonesModal([
          {
            label: "Ok",
            onClick: handleCloseModal,
            sx: {
              background: "#44291E",
              color: "#F0ECE3",
              fontFamily: "Sora",
              fontWeight: "600",
              borderRadius: "0.9375rem",
              width: "9.75rem",
            },
          },
        ]);
        setModalConfirmacion(true)
      } else {
        navigate("/registrar-cafeteria");
      }
    } catch (error) {
      console.error("Error verificando cafetería asociada:", error);
    }
  };

  return (
    <>
      <Dropdown>
        <MenuButton className="boton-perfil"></MenuButton>
        <Menu className="custom-menu">
          <Card
            sx={{
              width: "18.375rem",
              height: "29.9375rem",
              bordeRadius: "1.25rem",
              left: "50%",
              display: "flex", // Añadir flexbox para centrar contenido
              justifyContent: "center", // Centrar horizontalmente
              alignItems: "center", // Centrar verticalmente
              flexDirection: "column",
            }}
          >
            <CardMedia
              component="img" // Definir explícitamente que se renderizará un <img>
              sx={{
                width: "6.33681rem",
                height: "6.33681rem",
                borderRadius: "50%", // Mantener la imagen circular
                border: "0.25rem solid white", // Añadir borde blanco
              }}
              image={avatarPorId.get(datosUsuario.datos_avatar)} // Usar la propiedad "image" para la fuente de la imagen
              alt={datosUsuario.nombre}
            />
            <CardContent
              sx={{
                width: "18.375rem",
                height: "29.9375rem",
              }}
            >
              <Grid container justifyItems={"center"} spacing={0.5}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Sora",
                      fontSize: "1.125rem",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {datosUsuario.nombre} {datosUsuario.apellido}
                  </Typography>
                </Grid>
                {datosUsuario.reviews && (
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent={"initial"}
                      sx={{ m: "1rem" }}
                    >
                      <Grid item xs={1}>
                        <img src="\assets\reseñas.svg" alt="Icono Carta" />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography
                          sx={{
                            fontFamily: "Sora",
                            color: "#44291E",
                            fontSize: "0.9375rem",
                            fontWeight: "600",
                          }}
                        >
                          Mis reseñas
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className="contenedor-carrusel">
                      <SwipeToSlideReseña reseñas={datosUsuario.reviews} />
                    </div>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  sx={{ mx: "1rem" }}
                >
                  <Button
                    sx={{
                      borderRadius: "0.9375rem",
                      background: "#DAB587",
                      color: "#44291E",
                      textAlign: "center",
                      fontFamily: "Sora",
                      fontSize: "0.9375rem",
                      textTransform: "none",
                      width: "100%",
                    }}
                    startIcon={<CoffeeIcon />}
                    onClick={handleVerificarCafeteria}
                  >
                    Registrar mi cafetería
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mx: "1rem" }}>
                  <Button
                    sx={{
                      border: "none",
                      textAlign: "center",
                      color: "#44291E",
                      fontFamily: "Sora",
                      fontSize: "0.9375rem",
                      textTransform: "none",
                    }}
                    startIcon={<LogoutIcon />}
                    onClick={() => handleCerrarSesion()}
                  >
                    Cerrar sesión
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mx: "1rem" }}>
                  <Button
                    sx={{
                      color: "#C60F0F",
                      textAlign: "center",
                      fontFamily: "Sora",
                      fontSize: "0.9375rem",
                      textTransform: "none",
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleBajaUsuario()}
                  >
                    Dar de baja usuario
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Menu>
      </Dropdown>
      <div className="inicio">
        <Cabecera es_home={false} es_perfil={true} />
        <div>
          <Card
            sx={{
              height: "20rem",
              backgroundColor: "transparent",
              border: "none",
              position: "relative",
              boxShadow: "none",
            }}
          >
            <CardMedia
              sx={{
                height: "14rem",
                borderRadius: "1.125rem",
                position: "relative",
                zIndex: 0,
              }}
              image="/assets/backperfil.jpg"
            />

            <Card
              sx={{
                width: "20.1875rem",
                height: "auto",
                borderRadius: "1.0625rem",
                zIndex: 1,
                position: "absolute",
                top: "5rem",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex", // Añadir flexbox para centrar contenido
                justifyContent: "center", // Centrar horizontalmente
                alignItems: "center", // Centrar verticalmente
                overflow: "visible",
                paddingTop: "4.5rem",
                paddingBottom: "1rem",
              }}
            >
              <IconButton
                aria-label="reportar-bug"
                onClick={() => {
                  handleBug()
                }}
                sx={{
                  position: "absolute",
                  top: "0.5rem",
                  left: "0.5rem", // Cambiar a la izquierda
                  borderRadius: "0.4375rem",
                  background: "#44291E",
                  color: "#FFF",
                  zIndex: 3,
                }}
              >
                <BugReportIcon />
              </IconButton>
              <IconButton
                aria-label="editar"
                onClick={() => {
                  setAbrirEditarPerfil(true);
                }}
                sx={{
                  position: "absolute",
                  top: "0.5rem",
                  right: "0.5rem",
                  borderRadius: "0.4375rem",
                  background: "#44291E",
                  zIndex: 3, // Asegúrate de que esté por encima de otros elementos
                }}
              >
                <img src="/assets/edit.svg" alt="editar" />
              </IconButton>

              <CardMedia
                component="img" // Definir explícitamente que se renderizará un <img>
                sx={{
                  width: "8.375rem", // Asegurar que la altura y anchura sean iguales para mantener la proporción circular
                  height: "8.375rem",
                  borderRadius: "50%", // Hacer la imagen circular
                  position: "absolute",
                  top: "-4.1875rem", // Ajustar posición para que sobresalga en la parte superior
                  zIndex: 2,
                  border: "0.25rem solid white", // Añadir un borde blanco para resaltar la imagen
                  overflow: "visible",
                }}
                image={avatarPorId.get(datosUsuario.datos_avatar)} // Usar la propiedad "image" para la fuente de la imagen
                alt={datosUsuario.nombre}
              />

              <Grid container justifyItems={"center"} spacing={0.5}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Sora",
                      fontSize: "1.5625rem",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {datosUsuario.nombre} {datosUsuario.apellido}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "#44291E",
                      textAlign: "center",
                      fontFamily: "Sora",
                      fontSize: "0.9375rem",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {datosUsuario.nombre_nivel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <DireccionCafeteria
                    direccion={datosUsuario.ciudad}
                    centrar={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Puntos
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Reseñas
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Foros
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {datosUsuario.cantidad_puntos}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {datosUsuario.cantidad_reviews}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontFamily: "Sora",
                          fontSize: "0.9375rem",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {datosUsuario.cantidad_posteos_foros}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Card>
        </div>

        <div className="contenedor-carrusel">
          <Grid container justifyContent={"initial"} sx={{ m: "1rem" }}>
            <Grid item xs={1}>
              <img src="\assets\soloParaVosIcon.svg" alt="Icono Carta" />
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Tus lugares favoritos
              </Typography>
            </Grid>
          </Grid>
          <SwipeToSlideCafeterias
            cafeterias={
              datosUsuario.cafeterias_favoritas
                ? datosUsuario.cafeterias_favoritas
                : []
            }
            loading={loading}
          />
        </div>
        <div className="contenedor-carrusel">
          <Grid container justifyContent={"initial"} sx={{ m: "1rem" }}>
            <Grid item xs={1}>
              <img src="\assets\soloParaVosIcon.svg" alt="Icono Carta" />
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Tus preferencias
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="editan"
                onClick={() => navigate("/modificar-preferencias")}
                sx={{
                  borderRadius: "0.4375rem",
                  background: "#44291E",
                }}
              >
                <img src="/assets/edit.svg" alt="editar" />
              </IconButton>
            </Grid>
          </Grid>
          <SwipeToSlide
            tags={datosUsuario.preferencias ? datosUsuario.preferencias : []}
            onClick={handleTagClick}
          />
        </div>
        <div className="contenedor-carrusel">
          <Grid container justifyContent={"initial"} sx={{ m: "1rem" }}>
            <Grid item xs={1}>
              <RouteIcon sx={{ color: "#003147" }} />
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  color: "#003147",
                  fontSize: "0.9375rem",
                  fontWeight: "600",
                }}
              >
                Tus rutas del café
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="eliminar"
                onClick={toggleEliminarActivo}
                disabled={rutasCafe.length === 0} // Deshabilitar el botón si no hay rutas
                sx={{
                  borderRadius: "0.4375rem",
                  backgroundColor: rutasCafe.length > 0 ? "#C60F0F" : "#E0E0E0", // Color de fondo gris claro si está deshabilitado
                  color: rutasCafe.length > 0 ? "white" : "#A0A0A0", // Color gris si está deshabilitado
                  cursor: rutasCafe.length > 0 ? "pointer" : "not-allowed", // Cambiar el cursor a "not-allowed" si está deshabilitado
                }}
              >
                <DeleteOutlineIcon
                  sx={{
                    fontSize: "1.25rem",
                    color: rutasCafe.length > 0 ? "white" : "#A0A0A0", // Color del ícono gris claro si está deshabilitado
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <SliderRutas
            rutas={rutasCafe!}
            loading={loading}
            eliminarActivo={eliminarActivo}
            uid={usuario.user?.uid} // Paso el UID aquí para eliminar la ruta
            onEliminarRuta={handleEliminarRuta} // Función que elimina la ruta
          />
        </div>
        <Nav select={5} />
      </div>
      <ModalEditarPerfil
        abrir={abrirEditarPerfil}
        setAbrir={setAbrirEditarPerfil}
        datosUsuario={{
          nombre: datosUsuario.nombre,
          apellido: datosUsuario.apellido,
          datos_avatar: datosUsuario.datos_avatar,
          uid: usuario?.user?.uid,
        }}
      />
      <ReusableModal
        open={modalConfirmacion}
        onClose={() => {}}
        title={tituloModal}
        content={contenidoModal}
        buttons={botonesModal}
      />
    </>
  );
};

export default VerPerfil;