import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComponenteCafeteria from "../pure/Cafeteria";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Reseñas from "../../pages/Reseñas";

  interface SwipeToSlideProps {
    reseñas: any[],
  }
  
export default function SwipeToSlideReseña(props: SwipeToSlideProps) {
  const settings = {
    className: "center",
    infinite: props.reseñas.length > 1,
    centerPadding: "50px",
    slidesToShow: 2,
    arrows: props.reseñas.length > 1
  };
  
  return (
    <div className="slider-container">
      <div>
        <Slider {...settings}>
          {props.reseñas.map((reseña: any) => (
            <Card
                key={reseña.id_resenia}
                sx={{
                width: '6rem !important',
                height: '5.0625rem',
                borderRadius: '1rem',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
            >
              <CardContent>
                <Grid container spacing={0.5}>
                    <Grid item xs={2}>
                        <Typography
                            sx={{
                            fontFamily: "Sora",
                            color: "#003147",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            }}
                        >
                            {reseña.calificacion}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <img src="/assets/consultarCafeteria/estrellaDeReseñasIcon.svg" alt="Estrella"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                            fontFamily: "Sora",
                            fontSize: "0.4375rem",
                            fontWeight: "400",
                            }}
                        >
                            {reseña.descripcion}
                        </Typography>
                    </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Slider>
      </div>
    </div>
  );
};