import axios from "axios";
import { BASE_URL } from "../config";

export const crearPromocion = async (
  uid: string,
  idCafeteria: number,
  nombre: string,
  descripcion: string,
  condiciones: string,
  fecha: string,
  fechaInicio: string,
  fechaFin: string
): Promise<any> => {
  // Realizar la petición POST
  const response = await axios
    .post(`${BASE_URL}/cafeterias/promociones/`, {
      id_plantilla_promocion: 1,
      id_cafeteria: idCafeteria,
      id_meriendista: uid,
      nombre: nombre,
      descripcion: descripcion,
      condiciones: condiciones,
      fec_creacion: fecha,
      fec_inicio_vigencia: fechaInicio,
      fec_fin_vigencia: fechaFin,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const getAllPromocionesActivas = async (
  idCafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/cafeterias/promociones/${idCafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  console.log(response);
  return response;
};

export const getAllPromocionesInactivas = async (
  idCafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/cafeterias/promociones/inactivas/${idCafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  console.log(response);
  return response;
};

export const getPromocionCafeteria = async (
  idPromocion: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/cafeterias/promociones/particular/${idPromocion}`)
    .then((res) => res.data)
    .catch((error) => {
      throw Error(error);
    });

    return response;
};

export const modificarPromocion = async (
  idPromocion: number,
  uid: string,
  idCafeteria: number,
  nombre: string,
  descripcion: string,
  condiciones: string,
  fecha: string,
  fechaInicio: string,
  fechaFin: string
): Promise<any> => {
  // Realizar la petición POST
  const response = await axios
    .put(`${BASE_URL}/cafeterias/promociones/`, {
      id_promocion: idPromocion,
      id_cafeteria: idCafeteria,
      id_meriendista: uid,
      nombre: nombre,
      descripcion: descripcion,
      condiciones: condiciones,
      fec_creacion: "2024-12-03",
      fec_inicio_vigencia: fechaInicio,
      fec_fin_vigencia: fechaFin,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const activarPromocion = async (
  idPromocion: number,
  uid: string,
  idCafeteria: number,
): Promise<any> => {
  const response = await axios
    .patch(`${BASE_URL}/cafeterias/promociones/`, {
      id_promocion: idPromocion,
      id_cafeteria: idCafeteria,
      id_meriendista: uid,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const eliminarPromocion = async (
  idPromocion: number,
  uid: string,
  idCafeteria: number,
): Promise<any> => {
  console.log(idPromocion)
  const response = await axios
    .delete(`${BASE_URL}/cafeterias/promociones`, {
      params: {
        id_promocion: idPromocion,
        id_cafeteria: idCafeteria,
        id_meriendista: uid,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};