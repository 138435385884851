import axios, { AxiosResponse, AxiosError } from "axios";
import { BASE_URL } from "./config"

export const  postPreferencias = async (uid: string, preferencias: number[], idCiudad: number): Promise<any> => {
    const response = await axios.post(
      `${BASE_URL}/meriendista/${uid}/preferencias`,
      { 
        idTags: preferencias,
        idCiudad: idCiudad
      }
    )
    .then((res) => {return res.data})
    .catch ((error) => {
      throw Error(error);
    });
    return response
  };

  export const deletePreferencias = async (uid: string, preferencias: number[], idCiudad: number): Promise<any> => {
  const response = await axios.delete(
    `${BASE_URL}/meriendista/${uid}/preferencias`,
    {
      data: { // Usar el campo 'data' para pasar los datos en una solicitud DELETE
        idTags: preferencias,
        idCiudad: idCiudad
      }
    }
  )
  .then((res) => {return res.data})
  .catch((error) => {
    throw Error(error);
  });
  
  return response;
};

  export const getPreferencias = async (uid: string): Promise<any> => {
    const response = await axios.get(
        `${BASE_URL}/meriendista/${uid}/preferencias`
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  };

  export const getCiudades = async (): Promise<any> => {
    const response = await axios.get(
        `${BASE_URL}/ciudades/`
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  };
  
  export const getPerfilMeriendista = async (uid: string): Promise<any> => {
    const response = await axios.get(
        `${BASE_URL}/meriendista/perfil/${uid}`
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  };

  export const patchPerfilMeriendista = async (uid: string, nombre: string, apellido: string, id_avatar: number): Promise<any> => {
    const response = await axios.patch(
        `${BASE_URL}/meriendista/perfil/${uid}`,
        {
          nombre: nombre,
          apellido: apellido,
          id_ciudad: 1,
          id_avatar: id_avatar
        }
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  };