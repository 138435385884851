import { Box, Button, Card, CardMedia, Grid, IconButton, Modal, TextField } from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import { patchPerfilMeriendista } from "../../App/Services/meriendistaService";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { avatarPorId } from "../common/const";

interface IModalEditarPerfil {
    abrir: boolean;
    setAbrir: Dispatch<React.SetStateAction<boolean>>;
    datosUsuario: any;
}

export default function ModalEditarPerfil(props: IModalEditarPerfil) {
    const [nombre, setNombre] = useState<string | undefined>(props.datosUsuario.nombre);
    const [apellido, setApellido] = useState<string | undefined>(props.datosUsuario.apellido);
    const [avatar, setAvatar] = useState<number | undefined>(props.datosUsuario.datos_avatar); // Estado para el avatar seleccionado
    const [mostrarAvatares, setMostrarAvatares] = useState<boolean>(false); // Para mostrar el carrusel de avatares

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20.1875rem',
        height: 'auto',
        background: 'linear-gradient(180deg, #F0ECE3 38.38%, #D2C9C0 74.21%, #44291E 240.49%)',
        p: 4,
    };

    useEffect(() => {
        setNombre(props.datosUsuario.nombre);
        setApellido(props.datosUsuario.apellido);
        setAvatar(props.datosUsuario.datos_avatar); // Inicializar el avatar
    }, [props.datosUsuario])

    const handleActualizar = () => {
        patchPerfilMeriendista(props.datosUsuario.uid, nombre!, apellido!, avatar!)
            .then((res: any) => { props.setAbrir(false); })
            .catch((error: any) => { console.log('error al actualizar') })
    }

    const handleSeleccionarAvatar = (idAvatar: number) => {
        setAvatar(idAvatar); // Cambiar el avatar seleccionado
        setMostrarAvatares(false); // Ocultar la lista de avatares
    }

    return (
        <Modal
            open={props.abrir}
            onClose={() => props.setAbrir(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card
                    sx={{
                        height: '14rem',
                        backgroundColor: 'transparent',
                        border: 'none',
                        position: 'relative',
                        boxShadow: 'none',
                    }}
                >
                    <CardMedia
                        sx={{
                            height: "14rem",
                            borderRadius: "1.125rem",
                            position: 'relative',
                            zIndex: 0
                        }}
                        image="/assets/backperfil.jpg"
                    />

                    <Card
                        sx={{
                            width: '20.1875rem',
                            height: '7.5rem',
                            borderRadius: '1.0625rem',
                            zIndex: 1,
                            position: 'absolute',
                            top: '5rem',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'visible',
                            paddingTop: '1.5rem'
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                width: "8.375rem",
                                height: "8.375rem",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "-4.1875rem",
                                zIndex: 2,
                                border: "0.25rem solid white",
                                overflow: "visible",
                            }}
                            image={avatarPorId.get(avatar!)} // Mostrar el avatar seleccionado
                            alt={props.datosUsuario.nombre}
                        />
                        <Button
                            startIcon={<EditOutlinedIcon />}
                            sx={{
                                color: '#44291E',
                                fontFamily: 'Sora',
                                fontSize: '0.9375rem',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                alignItems: 'center',
                            }}
                            onClick={() => setMostrarAvatares(!mostrarAvatares)} // Mostrar/ocultar los avatares
                        >
                            Cambiar avatar
                        </Button>
                    </Card>
                </Card>

                {/* Mostrar el carrusel de avatares solo si el usuario hace clic en "Cambiar avatar" */}
                {mostrarAvatares && (
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2} justifyContent="center">
                            {Array.from(avatarPorId.keys()).map((idAvatar) => (
                                <Grid item xs={3} key={idAvatar}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: "5rem",
                                            height: "5rem",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            border: avatar === idAvatar ? "2px solid #44291E" : "2px solid transparent",
                                        }}
                                        image={avatarPorId.get(idAvatar)}
                                        alt={`Avatar ${idAvatar}`}
                                        onClick={() => handleSeleccionarAvatar(idAvatar)} // Seleccionar un avatar
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                <Box
                    component="form"
                    className="form"
                    noValidate
                    autoComplete="off"
                    sx={{ m: 1 }}
                >
                    <TextField
                        required
                        className="text"
                        id="standard-basic"
                        label="Nombre"
                        variant="standard"
                        size={"small"}
                        value={nombre}
                        sx={{
                            "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after": {
                                borderBottomColor: "black",
                            },
                            "& .MuiInputLabel-root": {
                                color: "black",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "black",
                            },
                            input: {
                                color: "black",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "black",
                                opacity: 0.5,
                            },
                        }}
                        onChange={(e) => setNombre(e.target.value)}
                    />
                    <TextField
                        required
                        className="text"
                        id="standard-basic"
                        label="Apellido"
                        variant="standard"
                        size={"small"}
                        value={apellido}
                        sx={{
                            "& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after": {
                                borderBottomColor: "black",
                            },
                            "& .MuiInputLabel-root": {
                                color: "black",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "black",
                            },
                            input: {
                                color: "black",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: "black",
                                opacity: 0.5,
                            },
                        }}
                        onChange={(e) => setApellido(e.target.value)}
                    />
                    <Button
                        sx={{
                            color: '#44291E',
                            fontFamily: 'Sora',
                            fontSize: '0.75rem',
                            fontWeight: '600',
                            backgroundColor: '#F0ECE3',
                            borderRadius: '4.75rem',
                            width: '9.75rem',
                            mt: '1rem'
                        }}
                        onClick={handleActualizar}
                        disabled={(!(props.datosUsuario.nombre !== nombre || props.datosUsuario.apellido !== apellido || props.datosUsuario.datos_avatar !== avatar) || !nombre || !apellido)}
                    >
                        Actualizar
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
