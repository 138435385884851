import axios, { AxiosResponse, AxiosError } from "axios";
import { BASE_URL } from "./config";  

export const getCiudadDeMeriendista = async (nombreCiudad: string): Promise<any> => {
    const response = await axios.get(
        `${BASE_URL}/ciudades/${nombreCiudad}`
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  };

  export const getCafeteriasCiudad = async (id_ciudad: string): Promise<any> => {
    const response = await axios.get(
        `${BASE_URL}/ciudades/cafeterias/${id_ciudad}`
      )
      .then((res) => {return res.data})
      .catch ((error) => {
        throw Error(error);
      });
      return response
  }; 

  export const getNombreCiudad = async (
    lat: number,
    lng: number,
    api: string
  ) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${api}`
      );

      if (response.data.status === "OK") {
        const addressComponents = response.data.results[0].address_components;

        const ciudad = addressComponents.find((component: any) =>
          component.types.includes("administrative_area_level_1")
        );
        return ciudad?.long_name || "Ciudad no encontrada";
      } else {
        console.error("Error en la geocodificación: ", response.data.status);
      }
    } catch (error) {
      console.error("Error al obtener la ciudad: ", error);
    }
  };
