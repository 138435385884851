import axios from "axios";
import { BASE_URL } from "../config";

export const getEventosCafeteria = async (
  idCafeteria: number
): Promise<any> => {
  const response = await axios
    .get(`${BASE_URL}/eventos/cafeteria/${idCafeteria}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const crearEvento = async (
  uid: string,
  idCafeteria: number,
  nombre: string,
  descripcion: string,
  fecha: string,
  hora: string,
  direccion: string,
  imagenes: any[],
  link: string
): Promise<any> => {
  // Remover encabezados y conservar estructura
  const imagenesProcesadas = imagenes.map((imagen) => {
    if (imagen.datos && typeof imagen.datos === "string" && imagen.datos.includes(",")) {
      return {
        datos: imagen.datos.split(",")[1], // Reemplazar solo el contenido de "datos"
      };
    }
    return imagen; // Devolver el objeto original si no tiene encabezado
  });

  // Realizar la petición POST
  const response = await axios
    .post(`${BASE_URL}/eventos/`, {
      idCafeteria: idCafeteria,
      idMeriendista: uid,
      nombre: nombre,
      descripcion: descripcion,
      fecha: fecha,
      hora: hora,
      direccion: direccion,
      fotos: imagenesProcesadas, // Enviar las imágenes con estructura conservada
      links: [link],
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};

export const modificarEvento = async (
  idEvento: number,
  uid: string,
  idCafeteria: number,
  nombre: string,
  descripcion: string,
  fecha: string,
  hora: string,
  direccion: string,
  imagenes: any[],
  link: string|undefined,
): Promise<any> => {
  // Filtrar imágenes que tienen id_foto o datos como null
  const imagenesFiltradas = imagenes.filter((imagen) => !imagen.id_foto || !imagen.datos);

  // Procesar las imágenes filtradas (saco el encabezado)
  const imagenesProcesadas = imagenesFiltradas.map((imagen) => {
    if (imagen.datos && typeof imagen.datos === "string" && imagen.datos.includes(",")) {
      return {
        id_foto: imagen.id_foto,
        datos: imagen.datos.split(",")[1], // Remover encabezado de "datos"
      };
    }
    return imagen; // Devolver el objeto original si no tiene encabezado
  });

  // Realizar la petición PATCH
  const response = await axios
    .patch(`${BASE_URL}/eventos/${idEvento}`, {
      idCafeteria: idCafeteria,
      idMeriendista: uid,
      nombre: nombre,
      descripcion: descripcion,
      fecha: fecha,
      hora: hora,
      direccion: direccion,
      fotos: imagenesProcesadas, // Enviar las imágenes con estructura conservada
      links: link != null ? [link] : [],
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw Error(error);
    });
  return response;
};
